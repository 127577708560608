import React, { createContext, useEffect, useState } from 'react';
import { API2, IProductInfo } from '../api/api';

const context = createContext<IProductInfo[]>([]);

export const PriceProvider = ({ children }: { children: React.ReactNode }) => {
  const [priceList, setPriceList] = useState<IProductInfo[]>([]);
  useEffect(() => {
    API2.moleculer.getPriceList().then((data) => setPriceList(data));
  }, []);
  return <context.Provider value={priceList}>{children}</context.Provider>;
};

PriceProvider.context = context;
