import React from 'react';
import Spinner from '../../RestView/Spinner';
import { classNames, formatDate } from '../../utils';
import OrganisationAvatar from '../OrganisationAvatar';

const OldInvoices = ({
  invoices,
  fetching,
}: {
  invoices:
    | {
        id: number;
        paid: boolean;
        date: string;
        total: number;
        organisation: {
          id?: number;
          edrpou?: string;
          prefix: string;
        };
      }[]
    | undefined;
  fetching: boolean;
}) => {
  if (fetching) {
    return <Spinner />;
  }
  if (invoices) {
    const invoicesList = invoices.map((invoice) => {
      return (
        <div
          key={invoice.id}
          className={classNames(
            'flex items-center',
            invoice.paid ? 'text-green-700' : ''
          )}
        >
          <div className='mr-1'>
            <OrganisationAvatar organisation={invoice.organisation} />
          </div>{' '}
          № {invoice.id} от {formatDate(invoice.date)} {invoice.total}
        </div>
      );
    });
    return <div className='space-y-1'>{invoicesList}</div>;
  }
  return null;
};

export default OldInvoices;
