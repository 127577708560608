import { Switch } from '@headlessui/react';
import React from 'react';

const FieldCheckBox = ({
  children,
  onChange,
  checked,
  name,
}: {
  children: React.ReactNode;
  onChange: (value: any) => void;
  checked: boolean;
  name: string;
}) => (
  <Switch.Group as='div' className='mt-2 flex items-center'>
    <Switch.Label className='flex justify-start pr-2 text-gray-600'>
      {children}
    </Switch.Label>
    <div className='items-center'>
      <Switch
        as='button'
        name={name}
        checked={checked}
        onChange={onChange}
        className={`${
          checked ? 'bg-indigo-600' : 'bg-gray-200'
        } relative flex -inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring`}
      >
        {({ checked }) => (
          <span
            className={`${
              checked ? 'translate-x-5' : 'translate-x-0'
            } inline-block h-5 w-5 transform rounded-full bg-white transition duration-200 ease-in-out`}
          />
        )}
      </Switch>
    </div>
  </Switch.Group>
);

export default FieldCheckBox;
