import { PaperClipIcon } from '@heroicons/react/outline';
import React from 'react';

const NoteContent = ({ content }: { content: string }): JSX.Element => {
  let data = <div className='py-1 px-2 text-gray-800 break-all'>{content}</div>;
  if (content && content.search('digitaloceanspace') > -1) {
    data = (
      <div>
        <a
          href={content}
          target={'_blank'}
          className='break-all'
          rel='noopener noreferrer'
        >
          <PaperClipIcon className='h-4 w-4 inline-block mr-1' />
          {content}
        </a>
      </div>
    );
  }
  return data;
};

export default NoteContent;
