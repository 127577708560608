import { useFormikContext } from 'formik';
import React from 'react';
import { API2 } from '../../api/api';
import { isFop, isOrganisation } from '../../utils';
import Field from './Field';

const EdrpouField = ({ onChange }: { onChange: (value?: any) => void }) => {
  const { values, setFieldValue, touched } = useFormikContext<any>();

  const getPartner = async (edrpou: string) => {
    const data: any = await API2.partners.getPartnerByEdrpou(edrpou);
    if (data.count) {
      const partner = data.results[0];
      onChange(partner);
      return partner;
    }
    onChange();
  };

  const clearFields = () => {
    setFieldValue('name', '');
    setFieldValue('isGov', false);
    !touched.email && setFieldValue('email', '');
    !touched.phones && setFieldValue('phones', '');
  };

  return (
    <Field
      name='edrpou'
      label='ЕГРПОУ/ИНН/Паспорт'
      type='text'
      onBlur={async () => {
        let { edrpou }: { edrpou: string } = values;
        const initialEdrpou = edrpou;
        if (edrpou.search('tel:') > -1) {
          edrpou = edrpou.replace('tel:', '');
        }
        edrpou = edrpou.trim();
        if (!edrpou) return;
        if (edrpou !== initialEdrpou) {
          setFieldValue('edrpou', edrpou);
        }

        clearFields();
        const partner = await getPartner(edrpou);
        if (partner) {
          (!touched.email || !values.email) &&
            setFieldValue('email', partner.email);
          setFieldValue('name', partner.name);
          setFieldValue('isGov', partner.is_gov);
        } else {
          if (isFop(edrpou) || isOrganisation(edrpou)) {
            const edr = await API2.moleculer.getEdrpouInfo(edrpou);
            edr.name && setFieldValue('name', edr.name);
          }
        }
      }}
    />
  );
};

export default EdrpouField;
