import React, { useState } from 'react';
import { returnDocument } from '../api/moleculer';
import { PostDocument } from '../types/PostDocument';
import { classNames, formatDate, getStateName } from '../utils';
import Card from './Card';
import Badge from './FormElements/Badge';
import { OldPost } from './InvoiceDetails/PostsList';
import ProviderLogo from './ProviderLogo';

export const PostState = ({
  state,
  stateName,
}: {
  state: PostDocument['state'];
  stateName: PostDocument['stateName'];
}) => (
  <Badge
    className={classNames(
      'rounded-full px-2 py-1 text-xs shadow',
      state === 'sent'
        ? 'bg-green-400 text-green-900'
        : state === 'wait'
        ? 'bg-orange-300 text-orange-900'
        : state === 'returned'
        ? 'bg-red-300 text-red-900'
        : 'bg-blue-300 text-blue-900'
    )}
  >
    {stateName}
  </Badge>
);

const ReturnForm = ({
  onSaveReason,
}: {
  onSaveReason: (content: string) => void;
}) => {
  const [inputMode, setInputMode] = useState(false);
  const [content, setContent] = useState('');

  const saveReason = () => {
    setInputMode(!inputMode);
    onSaveReason(content);
    setContent('');
  };
  return (
    <>
      <button
        className={inputMode ? 'hidden' : 'btn btn-sm btn-info'}
        onClick={() => setInputMode(!inputMode)}
      >
        Возврат
      </button>
      <div className={inputMode ? 'input-group' : 'hidden'}>
        <input
          className='form-control-white bg-white'
          value={content}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setContent(e.target.value);
          }}
          placeholder='Причина возврата'
        />
        <span className='input-group-btn'>
          <input
            type='submit'
            className='btn btn-success'
            value='Ок'
            onClick={saveReason}
          />
        </span>
      </div>
    </>
  );
};

const Post = ({ post, onUpdate }: { post: OldPost; onUpdate: () => void }) => (
  <Card className='w-full'>
    <div className='flex flex-wrap space-x-1'>
      <ProviderLogo provider={post.provider} />
      <div>Створена: {formatDate(post.created, 'DD.MM.YY')}</div>
      <PostState state={getStateName(post)} stateName={getStateName(post)} />
    </div>
    <div>
      {post.invoice.id} от {formatDate(post.invoice.date, 'DD.MM.YY')}
    </div>
    {post.sent && (
      <div>Надісланий {formatDate(post.sent_date, 'DD.MM.YY')}</div>
    )}
    <div>Автор: {post.user ? post.user.full_name : 'Через сайт'}</div>
    <div className='italic'>{post.comment}</div>
    <div className='text-red-600'>{post.return_reason}</div>
    {getStateName(post) === 'sent' && (
      <ReturnForm
        onSaveReason={(content: string) => {
          returnDocument(post.id.toString(), content).then(() => onUpdate());
        }}
      />
    )}
  </Card>
);

const PartnerPosts = ({
  posts,
  onUpdate,
}: {
  posts: OldPost[];
  onUpdate: () => void;
}): JSX.Element => {
  return (
    <div className='space-y-1'>
      {posts.map((post) => {
        return <Post key={post.id} post={post} onUpdate={onUpdate} />;
      })}
    </div>
  );
};

export default PartnerPosts;
