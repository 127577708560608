import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NoteType } from '../types/Note';
import { classNames, formatDate } from '../utils';
import NoteAuthor from './Note/NoteAuthor';
import NoteContent from './Note/NoteContent';
import NoteEditForm from './Note/NoteEditForm';
import NoteMenu from './Note/NoteMenu';
import Preview from './Note/Preview';

type Props = {
  pin: boolean;
  note: NoteType;
  disableMenu?: boolean;
  showPartnerName?: boolean;
  // children?: React.ReactNode; // TODO Отсюда контент или из note?
  onNotePin?: (key: string, pin: boolean) => void;
  onNoteDelete?: (key: string) => void;
  onNoteSubmit?: (key: string, content: string) => void;
};

const Note = ({
  pin,
  note,
  disableMenu = false,
  showPartnerName = false,
  onNotePin,
  onNoteDelete,
  onNoteSubmit,
}: Props): JSX.Element => {
  const [editing, setEditing] = useState(false);

  const handleNotePin = () => {
    onNotePin && onNotePin(note._id, !pin);
  };

  const handleNoteDelete = () => {
    onNoteDelete && onNoteDelete(note._id);
  };

  const handleSubmit = (content: string | undefined) => {
    setEditing(false);
    if (content !== note.content && onNoteSubmit) {
      onNoteSubmit(note._id, content || '');
    }
  };

  return (
    <div
      className={classNames(
        'relative px-2 py-2 md:rounded-lg',
        pin
          ? 'bg-yellow-200'
          : moment().diff(moment(note.created), 'days') > 240
          ? 'bg-gray-50 shadow brightness-95 grayscale'
          : 'bg-white shadow'
      )}
    >
      {editing ? (
        <NoteEditForm
          content={note.content}
          handleSubmit={handleSubmit}
          handleCancel={() => setEditing(false)}
        />
      ) : null}

      {editing ? null : (
        <div className='text-sm'>
          {!disableMenu ? (
            <NoteMenu
              handlePin={handleNotePin}
              handleNoteDelete={handleNoteDelete}
              handleNoteEdit={() => setEditing(true)}
            />
          ) : null}
          <div className='inline-flex items-start'>
            <span
              className='pr-1 text-sm text-gray-600'
              title={formatDate(note.created, 'DD.MM.YY HH:mm')}
            >
              {formatCreated(note.created)}
            </span>
            {note.partner && showPartnerName ? (
              <Link
                className='text-sm'
                to={{ pathname: `/partners/${note.partner.id}` }}
              >
                {note.partner.name}
              </Link>
            ) : (
              <NoteAuthor
                author={
                  note.user ? note.user.fullName || note.user.full_name : null
                }
              />
            )}
          </div>
          <div className='pr-6'>
            <NoteContent content={note.content} />
          </div>
          <Preview content={note.content} />
          {showPartnerName && (
            <NoteAuthor
              author={
                note.user ? note.user.fullName || note.user.full_name : null
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Note;
function formatCreated(noteCreated: Date) {
  let created = formatDate(noteCreated, 'DD.MM.YY');
  const eventCreated = new Date(noteCreated);
  const today = new Date();
  if (
    today.getFullYear() === eventCreated.getFullYear() &&
    today.getMonth() === eventCreated.getMonth() &&
    today.getDate() === eventCreated.getDate()
  ) {
    created = formatDate(noteCreated, 'HH:mm');
  }
  return created;
}
