const getToken = (): string | null => {
  return localStorage.getItem('token');
};

export const oldFetcher = (url: string): Promise<any> =>
  fetch(url, {
    headers: {
      Authorization: `Token ${getToken()}`,
      'Content-Type': 'application/json',
    },
  }).then((r) => r.json());

export const fetcher = (url: string): Promise<any> =>
  fetch(url, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    },
  }).then((r) => r.json());
