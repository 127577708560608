import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import Spinner from '../RestView/Spinner';
import { API2 } from '../api/api';
import { useInvoice } from '../hooks/useInvoice';
import Card from './Card';
import EdrpouInfo from './EdrpouInfo';
import AddPostForm, { PostValues } from './InvoiceDetails/AddPostForm';
import InvoiceActionsBar from './InvoiceDetails/InvoiceActionsBar';
import InvoiceInfo from './InvoiceDetails/InvoiceInfo';
import InvoiceOrganisation from './InvoiceDetails/InvoiceOrganisation';
import InvoiceTitle from './InvoiceDetails/InvoiceTitle';
import PartnerLink from './InvoiceDetails/PartnerLink';
import PaymentsList from './InvoiceDetails/PaymentsList';
import PostsList from './InvoiceDetails/PostsList';
import PartnerLicenses from './PartnerLicenses';
import PartnerNotes from './PartnerNotes';
import SonataInfo from './SonataInfo';
import InfoBlockHeader from './general/InfoBlockHeader';
import ManagerName from './partnerDetails/ManagerName';
// import { deleteDocumentFromQueue, sendDocumentToQueue } from '../api/moleculer';
import { getRegionById } from '../data/regions';
import { useInvoicePayments } from '../hooks/useInvoicePayments';
import { useInvoicePosts } from '../hooks/useInvoicePosts';

interface MatchParams {
  invoiceId: string;
}
type Props = RouteComponentProps<MatchParams>;

const InvoiceDetails = ({ match }: Props): JSX.Element => {
  const { invoiceId } = match.params;
  const [showReferer, setShowReferer] = useState(false);
  const { invoice, isLoading, mutate } = useInvoice(invoiceId);
  const { payments, isLoading: isLoadingPayments } =
    useInvoicePayments(invoiceId);
  const {
    posts,
    isLoading: isLoadingPosts,
    mutate: mutatePosts,
  } = useInvoicePosts(parseInt(invoiceId));

  const onDeliverySubmit = (values: PostValues, e?: Event) => {
    if (!invoice) return;
    console.log(JSON.stringify(values, null, 2));
    const { provider } = values;
    switch (provider) {
      case 'vchasno':
        API2.invoices
          .sendPost(invoiceId, values.comment, 'vchasno', values.email, '')
          .then(console.log)
          .then(() => mutatePosts())
          .catch(console.error);
        // sendDocumentToQueue({
        //   documentHash: invoice.unique_id,
        //   provider: 'vchasno',
        //   email: values.email,
        //   comment: values.comment,
        // })
        //   .then(console.log)
        //   .then(() => mutatePosts())
        //   .catch(console.error);
        break;
      case 'ukrposhta':
        if (values.regionId === '') return;
        const address = `${values.address};${values.city};${
          values.subregion
        };${getRegionById(parseInt(values.regionId, 10))} обл.;${values.city};${
          values.postIndex
        }`;
        API2.invoices
          .sendPost(
            invoiceId,
            values.comment,
            'ukrposhta',
            values.email,
            address
          )
          .then(console.log)
          .then(() => mutatePosts())
          .catch(console.error);
        // sendDocumentToQueue({
        //   documentHash: invoice.unique_id,
        //   provider: 'ukrposhta',
        //   comment: values.comment,
        //   address: values.address,
        //   city: values.city,
        //   postIndex: values.postIndex,
        //   regionId: values.regionId,
        //   subregion: values.subregion,
        // })
        //   .then(console.log)
        //   .then(() => mutatePosts())
        //   .catch(console.error);
        break;
      case 'novaposhta':
        API2.invoices
          .sendPost(
            invoiceId,
            values.comment,
            'novaposhta',
            values.email,
            values.address
          )
          .then(console.log)
          .then(() => mutatePosts())
          .catch(console.error);
        // sendDocumentToQueue({
        //   documentHash: invoice?.unique_id,
        //   provider: 'novaposhta',
        //   comment: values.comment,
        //   address: values.address,
        // })
        //   .then(console.log)
        //   .then(() => mutatePosts())
        //   .catch(console.error);
        break;
      default:
        break;
    }
  };

  const handleSetDocumentsOnPayer = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    API2.invoices
      .setDocumentsOnPayer(invoiceId, e.target.checked)
      .then(() => mutate());
  };

  if (isLoading)
    return (
      <div className='flex w-full items-center justify-center py-20'>
        <Spinner />
      </div>
    );

  if (!invoice) {
    return <></>;
  }
  const { partner, organisation, user } = invoice;
  return (
    <div className='flex flex-wrap space-y-2 md:space-x-2 md:space-y-0 md:p-2'>
      <Helmet title={`Рахунок №СЗ-${invoiceId}`}></Helmet>
      <div className='flex-auto space-y-2 md:flex-1'>
        <Card>
          <div className='space-y-3 divide-y divide-gray-200'>
            <div>
              <InvoiceOrganisation organisation={organisation} />
              {user && (
                <span className='float-right'>
                  <ManagerName name={user.full_name} />
                </span>
              )}
              <InvoiceTitle
                number={invoiceId}
                date={invoice.date}
                total={invoice.total}
                hash={invoice.unique_id}
              />
              <PartnerLink partner={partner} />
              <div className='text-blue-500'>{partner.edrpou}</div>
              {invoice.referrer && (
                <div className='break-words text-sm text-blue-700'>
                  <a
                    className='cursor-pointer font-semibold'
                    onClick={() => setShowReferer(true)}
                  >
                    Джерело
                  </a>
                  {showReferer && <div> {invoice.referrer}</div>}
                </div>
              )}
              {invoice.utm_source && (
                <div className='text-sm text-blue-700'>
                  <span className='font-semibold'>UTMSource:</span>{' '}
                  {invoice.utm_source}
                </div>
              )}
              <InvoiceActionsBar
                invoiceId={invoiceId}
                paid={invoice.paid}
                invoice={invoice}
                onUpdateAction={mutate}
              />
            </div>
            <div className='flex flex-wrap pt-2'>
              <div className='w-full md:w-1/2'>
                <div className='text-lg text-blue-600'>{partner.email}</div>
                <InvoiceInfo
                  invoice={invoice}
                  onSetDocumentsOnPayer={handleSetDocumentsOnPayer}
                />
                <InfoBlockHeader>ліцензії:</InfoBlockHeader>
                <PartnerLicenses edrpou={partner.edrpou} />
              </div>
              <div className='w-full md:w-1/2'>
                <SonataInfo edrpou={partner.edrpou} />
                <EdrpouInfo edrpou={partner.edrpou} />
              </div>
            </div>
          </div>
        </Card>
        {isLoadingPayments ? (
          <Spinner />
        ) : (
          <Card>
            <legend>Надходження коштів:</legend>
            {payments.length ? (
              <PaymentsList payments={payments} />
            ) : (
              <p className='text-gray-400'>Немає</p>
            )}
          </Card>
        )}
        {isLoadingPosts ? (
          <Spinner />
        ) : (
          <Card>
            <AddPostForm onSubmit={onDeliverySubmit} />
            <legend className='mt-4 text-red-700'>
              Історія надсилання документів:
            </legend>
            {posts ? (
              <PostsList
                posts={posts.results}
                onDelete={() => null}
                // onDelete={(postId) => {
                //   deleteDocumentFromQueue(postId).then(() =>
                //     mutatePosts({
                //       ...posts,
                //       rows: [
                //         ...posts.rows.filter((post) => post._id !== postId),
                //       ],
                //     })
                //   );
                // }}
              />
            ) : (
              <p className='text-gray-400'>Не було надсилання документів</p>
            )}
            {/* {posts ? (
              <PostsList
                posts={posts.rows}
                onDelete={(postId) => {
                  deleteDocumentFromQueue(postId).then(() =>
                    mutatePosts({
                      ...posts,
                      rows: [
                        ...posts.rows.filter((post) => post._id !== postId),
                      ],
                    })
                  );
                }}
              />
            ) : (
              <p className='text-gray-400'>Нет отправок документов</p>
            )} */}
          </Card>
        )}
      </div>
      <div className='flex-1'>
        <PartnerNotes partnerId={partner.id} />
      </div>
    </div>
  );
};

export default InvoiceDetails;
