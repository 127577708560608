import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { CallEvent } from '../../types/CallEvent';
import { classNames, formatDate } from '../../utils';

const Partner = ({
  partner,
  showEdrpou,
}: {
  partner: CallEvent['partners'][number];
  showEdrpou: boolean;
}) => {
  const { lastNote, lastLicense } = partner;
  const licenseDate =
    lastLicense && lastLicense.end
      ? formatDate(lastLicense.end, 'DD.MM.YY')
      : '-';
  let licenseExpired = false;
  if (lastLicense) {
    licenseExpired = moment(lastLicense.end).isBefore(moment());
  }
  let created = null;
  let isTodayNote = false;
  if (lastNote) {
    const noteCreated = new Date(lastNote.created);
    let note_created = formatDate(lastNote.created, 'DD.MM.YY HH:mm');
    created = <span className='text-gray-700'>{note_created}</span>;
    if (moment(noteCreated).isSame(moment(), 'day')) {
      isTodayNote = true;
      note_created = formatDate(lastNote.created, 'HH:mm');
      created = <span className='text-blue-700'>{note_created}</span>;
    }
  }
  return (
    <>
      <div className='flex flex-wrap shadow-sm md:flex-nowrap'>
        {showEdrpou && (
          <div className='flex w-full flex-wrap items-center justify-center bg-gray-200 pl-1 pr-1 md:w-40'>
            <div className='w-full text-center'>{partner.edrpou}</div>
          </div>
        )}
        <div
          className={classNames(
            'flex w-20 items-center justify-center px-2',
            licenseExpired ? ' bg-gray-300 text-red-600' : 'bg-gray-300'
          )}
        >
          <span className='text-xs'>{licenseDate}</span>
        </div>
        <div className='-pr-3 -rounded-r-full flex w-full items-center bg-gray-200 pl-1 md:pl-3'>
          <Link to={{ pathname: `/partners/${partner.partnerId}` }}>
            {partner.name || '<Без названия>'}
          </Link>
        </div>
      </div>
      {lastNote && (
        <div
          className={classNames(
            'flex pl-2 pt-2 text-xs',
            isTodayNote ? 'font-bold' : 'font-normal'
          )}
        >
          <div className='mr-1'>
            <span className='whitespace-nowrap'>{created}</span>
            {lastNote.user && (
              <span className='ml-1 text-green-700'>
                {lastNote.user.fullName}
              </span>
            )}
          </div>
          <div className='break-all'>{lastNote.content}</div>
        </div>
      )}
    </>
  );
};

export default Partner;
