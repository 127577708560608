import React, { useState } from 'react';
import ListContextProvider from './ListContextProvider';
import useListController, { ListProps } from './useListController';

const MenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-indigo-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
  </svg>
)

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-indigo-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
  </svg>
)

type MenuProps = {
  onClick: () => void
}

const MenuButton: React.FC<MenuProps> = ({ onClick }) => (
  <button onClick={onClick} className="transition -ml-6 hover:bg-purple-700 text-white focus:outline-none w-10 h-10 bg-purple-600 rounded-r transform hover:translate-x-4 hover:shadow flex justify-center items-center">
    <MenuIcon />
  </button>
)

const Sidebar: React.FC = ({ children }) => {
  const [show, setShow] = useState(false);

  return <div className="left-0 fixed top-16">
    <div className="pl-2 py-2">
      {/* {!show && <MenuButton onClick={() => setShow(!show)} />} */}
      <div className={`w-60 -ml-2 mt-2 bg-indigo-300 rounded-r-lg shadow transition-all duration-200 transform ${show ? 'translate-x-0 bg-white' : 'hover:-translate-x-52 -translate-x-56 -bg-purple-200'}`}>
        <div className="justify-end text-right flex">
          <button className="focus:outline-none w-10 h-10 flex justify-center items-center" onClick={() => setShow(!show)} >
            {show ? <CloseIcon /> : <MenuIcon />}
          </button>
        </div>
        <div className={`transition ${show ? 'block' : 'hidden'}`}>{children}</div>
      </div>
    </div>
  </div>
}

const List: React.FC<ListProps> = (props) => {
  const { children, filters, aside, bulkActions } = props;
  const listController = useListController(props);
  const { selected } = listController;
  return (
    <ListContextProvider value={listController}>
      {filters && (
        <div className='bg-gray-600 py-2 text-left'>
          {filters}
        </div>
      )}
      <div className="flex ">
        {aside && <Sidebar>{aside}</Sidebar>}
        <div className="flex-auto">
          {selected.length > 0 && bulkActions &&
            <div className="p-2 fixed ml-14 mt-1 bg-gray-600 bg-opacity-70 rounded-lg">
              <div className="text-white font-medium pb-1">Выбрано: {selected.length}</div>
              <div className="space-x-1">{bulkActions}</div>
            </div>
          }
          {children}
        </div>
      </div>
    </ListContextProvider>
  );
};

export default List;