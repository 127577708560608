import { PlusIcon } from '@heroicons/react/outline';
import React from 'react';
import RectButton from '../Buttons/RectButton';

const CreateForm = ({ onCreate }: { onCreate: () => void }) => (
  <div className='btn-group mt-2'>
    <RectButton onClick={onCreate} className='bg-green-600 hover:bg-green-700'>
      <PlusIcon className='h-5 w-5' /> Создать нового
    </RectButton>
  </div>
);

export default CreateForm;
