import React from 'react';
import get from 'lodash/get';
import { FieldProps } from '../../../RestView/types';

interface Status {
  message?: string;
  errorMessage?: string;
  response?: string;
  messageId?: string;
}

interface StatusProps {
  [key: string]: Status
}

export const StatusField: React.FC<FieldProps<StatusProps>> = ({ source, resource, ...props }) => {
  const value = get(resource, source);
  if (!value) return null;
  return <div {...props}>
    {value.errorMessage && <div className="text-red-500">{value.errorMessage}</div>}
    {value.response && <div className="text-green-700 text-sm">{value.response}</div>}
    {value.message && <div className="text-green-700 text-sm">{value.message}</div>}
    {value.messageId && <div className="text-gray-600 text-xs" title={JSON.stringify(value, undefined, 2)}>{value.messageId}</div>}
  </div>;
};

export default StatusField;