import { API2 } from '../api/api';
import { BaseRecord, DataProvider, RestResult } from './types';

const headers = () => {
  const token = API2.auth.get_token();
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
};

export const moleculerProvider = <T extends BaseRecord>(
  apiUrl: string
): DataProvider<T> => ({
  getBaseUrl: () => apiUrl,
  getList: async (resource: string, params) => {
    const { page, perPage } = params.pagination;
    const { field } = params.sort;
    const urlParams = new URLSearchParams({
      pageSize: perPage.toString(),
      page: page.toString(),
      sort: field || '',
    });
    if (params.filter) {
      urlParams.append('query', JSON.stringify(params.filter));
    }
    const url = `${apiUrl}${resource}/?${urlParams.toString()}`;
    const response = await fetch(url, {
      headers: headers(),
    });
    const data: RestResult<T> = await response.json();
    const { rows, total, totalPages } = data;
    return {
      rows,
      total,
      totalPages,
    };
  },
  update: async (resource, resourceId, data) => {
    const url = `${apiUrl}${resource}/${resourceId}`;
    const response = await fetch(url, {
      headers: headers(),
      method: 'PUT',
      body: JSON.stringify(data),
    });
    return response.json();
  },
  remove: async (resource, resourceId) => {
    const url = `${apiUrl}${resource}/${resourceId}`;
    const response = await fetch(url, {
      headers: headers(),
      method: 'DELETE',
    });
    return response.json();
  },
});
