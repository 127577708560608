import React from 'react';
import { NoteType } from '../types/Note';
import NotesList from './Note/NotesList';

type Props = {
  notes: NoteType[] | undefined;
};

const AllNotesPanel = ({ notes }: Props): JSX.Element => {
  if (!notes) return <></>;
  return <NotesList data={notes} showPartnerName disableMenu></NotesList>;
};

export default AllNotesPanel;
