import useSWR from 'swr';
import { InvoiceFromDjango } from '../types/InvoiceFromDjango';
import { oldFetcher } from './utils';

const OLD_API = process.env.REACT_APP_OLD_API;

export const useInvoice = (invoiceId: string) => {
  const { data, error, mutate } = useSWR<InvoiceFromDjango, any>(
    `${OLD_API}/api/v2/invoices/${invoiceId}/`,
    oldFetcher
  );
  // const { data, error, mutate } = useSWR<InvoiceFromDjango, any>(
  //   `${OLD_API}/api/invoices/${invoiceId}/`,
  //   oldFetcher
  // );
  return {
    invoice: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
