import React from 'react';

const NovaPoshtaLogo = (props: { [key: string]: string }) => (
  <div {...props}>
    <svg
      className='fill-current text-red-700'
      width='100%'
      height='100%'
      viewBox='0 0 512 512'
    >
      <path
        transform='scale(1, -1) translate(0, -512)'
        d='M410 359c2 1 4 0 6-3l92-90c3-2 4-5 4-9 0-3-1-6-4-8l-92-91c-2-3-4-4-6-3-1 1-2 3-2 7l0 191c0 4 1 6 2 6z m-157 150l6 0 7-3 94-94c5-5 3-9-4-9l-39 0c-3 0-6-1-9-4-2-2-3-5-3-8l0-70c0-3-2-7-4-9-3-2-6-3-10-3l-69 0c-3 0-6 1-8 3-3 3-4 6-4 9l0 70c0 3-1 6-3 8-3 3-6 4-9 4l-42 0c-7 0-8 4-4 9l95 94z m-149-148c2-1 3-3 3-7l0-195c0-4-1-6-3-6-2-1-5 0-6 2l-94 94c-3 2-4 5-4 8 0 4 1 7 4 9l94 93c2 2 5 3 6 2z m118-154l69 0c4 0 7-1 10-3 2-2 4-6 4-9l0-74c0-3 1-7 3-9 2-3 6-4 9-4l37 0c6 0 9-4 4-8l-92-93c-3-2-6-4-10-4-3 0-7 2-9 4l-92 93c-6 4-3 8 4 8l39 0c3 0 7 1 9 4 2 2 3 6 3 9l0 74c0 3 1 6 4 9 2 2 5 3 8 3z'
      />
    </svg>
  </div>
);

export default NovaPoshtaLogo;
