import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { SpinnerWithContainer } from '../RestView/Spinner';
import { API2, IOldPost } from '../api/api';
import { formatEnvelopeAddress } from '../api/moleculer';
import Messages from '../common/messages';
import { usePosts } from '../hooks/usePosts';
import { classNames, downloadFile, formatDate, printFile } from '../utils';
import ClipboardButton from './Buttons/ClipboardButton';
import RectButton from './Buttons/RectButton';
import DropDown from './FormElements/DropDown';
import DropDownItemA from './FormElements/DropDownItemA';
import Gerb from './Gerb';
import NovaPoshtaLogo from './NovaPoshtaLogo';
import OrganisationAvatar from './OrganisationAvatar';
import UkrposhtaLogo from './UkrposhtaLogo';
import VchasnoLogo from './VchasnoLogo';

const Post = ({
  post,
  onPrintClick,
  onSentClick,
  onSendOriginalDocsClick,
  onDeleteClick,
  onUpdate,
}: {
  post: IOldPost;
  onPrintClick: (postId: number, printed: boolean) => void;
  onSentClick: (postId: number, sent: boolean) => void;
  onSendOriginalDocsClick: (invoice_id: number) => void;
  onDeleteClick: (postId: number) => void;
  onUpdate: () => void;
}) => {
  const handlePrintClick = () => {
    onPrintClick(post.id, !post.printed);
  };
  const handleSentClick = () => {
    onSentClick(post.id, !post.sent);
  };
  const handleSendOriginalDocsClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onSendOriginalDocsClick(post.invoice.id);
    Messages.info(
      'Надсилання',
      `Надіслано повідомлення по рахунку ${post.invoice.id}`
    );
  };
  const invoiceId = post.invoice.id;
  const partner = post.partner_info;
  const showPrintButtons = false;

  return (
    <div
      className={classNames(
        'well relative -overflow-hidden',
        post.printed ? 'well-printed' : '',
        post.sent ? 'well-sent' : ''
      )}
    >
      <>
        <div className='float-right flex pr-1 pt-2'>
          <DropDown title='Відкрити меню' dots left>
            <DropDownItemA
              onClick={() => {
                if (
                  window.confirm(
                    `Ви впевнені, що хочете видалити заявку від ${post.partner.buyer}?`
                  )
                ) {
                  onDeleteClick(post.id);
                }
              }}
            >
              <span className='text-red-700'>Видалити</span>
            </DropDownItemA>
            <DropDownItemA
              target='_blank'
              href={`/admin/adminpanel/postofficeevent/${post.id}/`}
            >
              <span className='text-red-900'>Адмін</span>
            </DropDownItemA>
          </DropDown>
        </div>
        <div className='flex flex-wrap'>
          <div className='w-full md:w-1/2'>
            <div className='flex items-center'>
              <div className='flex items-center pr-1'>
                {post.provider === 'novaposhta' ? (
                  <NovaPoshtaLogo className='inline-block h-6 w-6' />
                ) : null}
                {post.provider === 'vchasno' ? (
                  <VchasnoLogo className='inline-block h-6 w-6' />
                ) : null}
                {post.provider === 'ukrposhta' ? (
                  <UkrposhtaLogo className='inline-block h-6 w-6' />
                ) : null}
                {partner.is_gov ? (
                  <div className='inline-block h-6 w-6'>
                    <Gerb />
                  </div>
                ) : null}
              </div>
              <div className='text-xs text-gray-500'>
                {formatDate(post.created)}
              </div>
              <Link
                className='ml-2'
                to={{ pathname: `/partners/${partner.id}` }}
              >
                {partner.buyer}
              </Link>
            </div>

            {post.provider === 'vchasno' ? (
              <div className='mt-1 flex items-center space-x-1'>
                <OrganisationAvatar organisation={post.invoice.organisation} />
                <span>Email для Вчасно:</span>
                <span className='font-semibold'>{post.email_edo}</span>
                {post.email_edo && (
                  <ClipboardButton clipboardText={post.email_edo} />
                )}
                {post.invoice?.partner_for_documents ? (
                  <span className='font-semibold text-indigo-700'>
                    {post.invoice?.partner_for_documents?.edrpou}
                  </span>
                ) : (
                  <span className='font-semibold'>
                    {post.invoice?.invoice_partner?.edrpou}
                  </span>
                )}
                {(post.invoice?.partner_for_documents ||
                  post.invoice?.invoice_partner) && (
                  <ClipboardButton
                    clipboardText={
                      post.invoice?.partner_for_documents?.edrpou ||
                      post.invoice?.invoice_partner?.edrpou
                    }
                  />
                )}
                <button
                  className='text-blue-800 hover:underline'
                  onClick={() => {
                    downloadFile(post.invoice.id, 'pdf', 'akt', true, {
                      edrpouOwner: post.invoice?.organisation.edrpou,
                      edrpouRecipient:
                        post.invoice?.partner_for_documents?.edrpou ||
                        post.invoice?.invoice_partner?.edrpou,
                      date: moment(post.invoice.date).format('YYYYMMDD'),
                      emailRecipient:
                        post.email_edo || post.invoice?.invoice_partner.email,
                    });
                  }}
                >
                  Зберегти Акт
                </button>
              </div>
            ) : (
              <div>
                <div className='flex items-center space-x-1'>
                  <span className='mr-1 text-gray-500'>Адреса по заявці:</span>
                  {post.address}{' '}
                  <ClipboardButton clipboardText={post.address} />
                </div>
                <div className='flex items-center space-x-1'>
                  <span className='mr-1 text-gray-500'>Адреса у клієнта:</span>
                  {post.post_address}
                  <ClipboardButton clipboardText={post.post_address} />
                </div>
                <div className='ml-4 text-xs text-gray-800'>
                  {post.partner.post_address}{' '}
                  {post.partner.post_address &&
                    post.partner.post_address.search(';') < 0 && (
                      <button
                        className='text-blue-800 underline hover:text-blue-900'
                        onClick={async () => {
                          const formatedAddress = await formatEnvelopeAddress(
                            post.address
                          );
                          if (formatedAddress?.output) {
                            await API2.partners.changePartner(post.partner.id, {
                              post_address: formatedAddress.output,
                            });
                          }
                          onUpdate();
                          console.log(
                            `Адреса оновлена: ${formatedAddress.output}`
                          );
                        }}
                      >
                        Виправити
                      </button>
                    )}
                </div>
                <div className='flex items-center space-x-1'>
                  <span className='mr-1 text-gray-500'>Отримувач:</span>
                  {post.receiver}
                  <ClipboardButton clipboardText={post.receiver} />
                </div>
                {post.receiver !== post.partner.post_receiver && (
                  <div className='ml-4 text-xs text-gray-800'>
                    {post.partner.post_receiver}{' '}
                    {post.partner.post_receiver2 ? (
                      <div>{post.partner.post_receiver2}</div>
                    ) : null}
                  </div>
                )}
                <div>
                  <span className='mr-1 text-gray-500'>Автор:</span>
                  {post.user === null ? 'Через сайт' : post.user.full_name}
                </div>
              </div>
            )}
          </div>
          <div className='w-full md:w-1/4'>
            {post.provider !== 'vchasno' ? (
              <div className='space-x-1'>
                {/* disbaled button: opacity-50 cursor-not-allowed */}
                <RectButton
                  onClick={async () => {
                    await downloadFile(invoiceId, 'pdf', 'invoice_akt', false);
                  }}
                >
                  Рахунок та акт
                </RectButton>

                <RectButton
                  onClick={async () => {
                    downloadFile(invoiceId, 'pdf', 'envelop');
                  }}
                >
                  Конверт
                </RectButton>

                <RectButton
                  onClick={async () => {
                    downloadFile(invoiceId, 'pdf', 'packet', false);
                  }}
                >
                  Пакет
                </RectButton>
                {partner.contract_bank ? (
                  <>
                    <RectButton
                      onClick={async () => {
                        downloadFile(invoiceId, 'pdf', 'contract', false);
                      }}
                    >
                      Договір
                    </RectButton>
                    {showPrintButtons && (
                      <RectButton
                        onClick={async () => {
                          printFile(invoiceId, 'pdf', 'contract', false);
                        }}
                      >
                        Друк договору
                      </RectButton>
                    )}
                  </>
                ) : null}
                {showPrintButtons && (
                  <>
                    <RectButton
                      onClick={async () => {
                        printFile(invoiceId, 'pdf', 'envelop');
                      }}
                    >
                      Друк конверту
                    </RectButton>
                    <RectButton
                      onClick={async () => {
                        await printFile(invoiceId, 'pdf', 'invoice_akt', false);
                      }}
                    >
                      Друк Рахунок+акт
                    </RectButton>
                  </>
                )}
              </div>
            ) : null}
            <div className='flex items-center space-x-1 pt-3'>
              <OrganisationAvatar organisation={post.invoice.organisation} />
              <Link
                className={
                  'rounded px-2 py-1 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white' +
                  (post.invoice.paid ? ' bg-green-400' : ' bg-gray-200')
                }
                to={{ pathname: `/invoices/${post.invoice.id}` }}
              >
                Рахунок №СЗ-{post.invoice.id} на {post.invoice.total} грн.
              </Link>
            </div>
            {post.comment ? (
              <div className='text-sm text-gray-700'>{post.comment}</div>
            ) : null}
          </div>
          <div className='w-full md:w-1/4'>
            <div className='space-x-1'>
              {post.provider !== 'vchasno' ? (
                <RectButton
                  onClick={handlePrintClick}
                  className={classNames(
                    'text-gray-900',
                    post.printed
                      ? 'bg-green-500 hover:bg-green-600'
                      : 'bg-gray-200 hover:bg-gray-300'
                  )}
                >
                  Роздруковано
                </RectButton>
              ) : null}
              <RectButton
                onClick={handleSentClick}
                className={classNames(
                  'text-gray-900',
                  post.sent
                    ? 'bg-green-500 hover:bg-green-600'
                    : 'bg-gray-200 hover:bg-gray-300'
                )}
              >
                Надісланий
              </RectButton>
              {post.provider !== 'vchasno' ? (
                <RectButton onClick={handleSendOriginalDocsClick}>
                  Повідомлення
                </RectButton>
              ) : null}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

const PostsList = () => {
  const { posts, isLoading, mutate } = usePosts();

  if (isLoading) return <SpinnerWithContainer />;
  return (
    <>
      <Helmet title='Пошта' />
      {posts?.map((post) => (
        <div className='p-1' key={post.id}>
          <Post
            post={post}
            onUpdate={mutate}
            onPrintClick={async (postId, isPrinted) => {
              mutate(
                (data) => {
                  if (data) {
                    return {
                      ...data,
                      results: data.results.map((post) => {
                        if (post.id === postId) {
                          return {
                            ...post,
                            printed: !post.printed,
                          };
                        }
                        return { ...post };
                      }),
                    };
                  }
                  return data;
                },
                {
                  revalidate: false,
                }
              );
              API2.posts.setAsPrint(postId, isPrinted).then(() => mutate());
            }}
            onSendOriginalDocsClick={async (invoiceId) => {
              await API2.invoices.sendOriginalDocsSended(invoiceId);
              mutate();
            }}
            onSentClick={async (postId, isSent) => {
              API2.posts.setAsSent(postId, isSent);
              mutate(
                (data) => {
                  if (data) {
                    return {
                      ...data,
                      results: data.results.map((post) => {
                        if (post.id === postId) {
                          return {
                            ...post,
                            sent: !post.sent,
                          };
                        }
                        return { ...post };
                      }),
                    };
                  }
                  return data;
                },
                {
                  revalidate: false,
                }
              );
            }}
            onDeleteClick={async (postId) => {
              await API2.posts.deletePostRequest(postId);
              mutate();
              Messages.info('Удалена заявка', `Заявка ${postId}`);
            }}
          />
        </div>
      ))}
    </>
  );
};

export default PostsList;
