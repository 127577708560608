import moment from 'moment';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useInvoices } from '../hooks/useInvoices';
import { SpinnerWithContainer } from '../RestView/Spinner';
import { TOldInvoice } from '../types/Invoices';
import { formatDate } from '../utils';
import SocketIO from './general/SocketIO';
import Gerb from './Gerb';
import LicenseInfo from './LicenseInfo';
import { NoResults } from './NoResults';
import OrganisationAvatar from './OrganisationAvatar';
import Pager from './Pager';
import ManagerName from './partnerDetails/ManagerName';
import SearchForm from './SearchForm';

const LiqpayInfo = ({ data }: { data: TOldInvoice }) => {
  if (data.liqpay_transaction_id === '') {
    return null;
  }
  return (
    <span className='mr-1 rounded border border-green-600 bg-white px-1 text-xs'>
      LP: {data.liqpay_status} id: {data.liqpay_transaction_id}
    </span>
  );
};

const Invoice = ({ data }: { data: TOldInvoice }) => {
  const ageDays = moment().diff(moment(data.partner.created), 'd');
  const isNew = ageDays < 3;
  return (
    <div className={'well well-sm' + (data.paid ? ' success-well' : '')}>
      <div className='float-right flex flex-wrap md:float-left'>
        <div className='z-10 md:absolute md:left-6'>
          {data.partner.is_gov ? (
            <div className='h-8 w-8'>
              <Gerb />
            </div>
          ) : null}
        </div>
        <div className='z-10 mr-1'>
          <OrganisationAvatar organisation={data.organisation} />
        </div>
      </div>
      <div className='flex flex-wrap items-start'>
        <div className='flex flex-wrap items-center md:w-1/6'>
          <div className='md:pl-3'>
            <div>
              <Link
                className='text-md text-teal-800'
                to={`/invoices/${data.id}/`}
              >
                № СЗ-{data.id}
              </Link>
            </div>
            <div className='text-sm text-gray-600'>
              {formatDate(data.date, 'DD.MM.YY HH:mm')}
            </div>
          </div>
          <div className='ml-1'>
            <div className='mr-1 inline-block rounded bg-teal-500 px-2 py-1 text-xl text-white'>
              {data.total}
            </div>
          </div>
          <div>
            <a
              target='_blank'
              className='btn btn-default'
              rel='noopener noreferrer'
              href={`https://old.sonatazvit.com.ua${data.url}`}
              title='Роздрукувати рахунок'
            >
              <i className='fa fa-print'> </i>{' '}
            </a>
          </div>
        </div>
        <div className='md:w-1/2'>
          <Link to={{ pathname: `/partners/${data.partner.id}` }}>
            {data.partner.buyer}
          </Link>{' '}
          <LicenseInfo edrpou={data.partner.edrpou} />
          {isNew ? (
            <span
              className='mx-1 rounded-full bg-orange-500 py-0.5 px-2 text-xs text-white shadow'
              title={ageDays + ' дней'}
            >
              Новий
            </span>
          ) : null}
          <div>
            <span className='mr-1 font-bold text-teal-800'>
              {data.partner.edrpou}
            </span>
            <a
              href='mailto:{{ i.email }}'
              target='_blank'
              rel='noopener noreferrer'
            >
              {data.email}
            </a>
          </div>
        </div>
        <div className='md:w-1/4'>
          {data.user && <ManagerName name={data.user.full_name} />}
          <div className='text-sm text-gray-600'>{data.comment}</div>
          <div className='text-sm'>
            <LiqpayInfo data={data} />
            {data.client_id && <div>ga: {data.client_id}</div>}
            {data.referrer && <div>ref: {data.referrer.substr(0, 60)}</div>}
            {data.utm_source && <div>utmSource: {data.utm_source}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

const InvoicesList = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const search = query.get('search') || undefined;
  const [currentPage, setCurrentPage] = useState(1);

  const { invoices, isLoading, lastPage, mutate } = useInvoices({
    page: currentPage,
    filter: search,
  });

  const handleSearch = (search: string) => {
    if (search) {
      return history.push(`${location.pathname}?search=${search}`);
    }
    history.push(`${location.pathname}`);
  };

  return (
    <>
      <Helmet title='Рахунки' />
      <SocketIO
        channel='invoices'
        onUpdate={(data) => {
          console.log('socket.io invoices', data);
          mutate();
        }}
      />
      <div className='justify-left flex flex-wrap items-center bg-gray-700 px-2 py-2'>
        <div className='w-full items-center md:w-1/3'>
          <SearchForm onSearch={handleSearch} initialValue={search} />
        </div>
        <div className='flex w-full items-center md:w-auto md:px-4'>
          <Pager
            onPageChange={({ selected }) => setCurrentPage(selected + 1)}
            pageCount={lastPage}
            initialPage={currentPage}
          />
        </div>
      </div>
      {isLoading || !invoices ? (
        <SpinnerWithContainer />
      ) : (
        <div className='px-1 pt-4'>
          {invoices.length ? (
            invoices.map((invoice) => (
              <div key={invoice.id} className='pb-2'>
                <Invoice data={invoice} />
              </div>
            ))
          ) : (
            <NoResults />
          )}
        </div>
      )}
    </>
  );
};

export default InvoicesList;
