import useSWR from 'swr';
import { RestResult } from '../providers/types';
import { UseSwrResponse } from './swrTypes';
import { fetcher } from './utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

type PartnerData = {
  _id: string;
  name: string;
  edrpou: string;
  partnerId: number;
  phones: string[];
  email?: string;
  // lastLicense?: any;
  // lastNote?: any;
};

export const usePartners = (
  page = 0,
  filter: string | null
): UseSwrResponse & {
  partners: RestResult<PartnerData> | undefined;
} => {
  let search = filter;
  // if (filter && filter.search(/^\+?(38)?0\d{9}$/) >= 0) {
  if (filter && filter.search(/^\+380\d{9}$/) >= 0) {
    search = filter.substring(1);
  }
  if (filter && filter.search(/^0\d{9}$/) >= 0) {
    search = `38${filter}`;
  }
  console.log(search);
  const url = encodeURI(
    `${MOLECULER}/private/partners?pageSize=28&sort=-partnerId&page=${page}${
      search ? `&search="${search}"` : ''
    }`
  );
  const { data, error, mutate } = useSWR<RestResult<PartnerData>, any>(
    url,
    fetcher
  );
  return {
    partners: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
