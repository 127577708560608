import React from 'react';

const Preview = ({ content }: { content: string }) => {
  if (content && content.search('.png') > -1) {
    return (
      <div>
        <img
          className='border border-gray-500'
          src={content}
          height={80}
          alt=''
        />
      </div>
    );
  }
  return null;
};

export default Preview;
