import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API2 } from '../api/api';

const Login = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    API2.auth
      .login(login, password)
      .then((data) => {
        if (data) {
          history.push('/');
        }
      })
      .catch((data) => {
        console.log('auth fail', data);
      });
  };
  return (
    <div className='mt-10 flex justify-center text-center md:mt-20'>
      <div className='m-auto mx-2 w-full rounded-lg bg-white p-4 shadow-lg md:mx-0 md:w-1/3'>
        <form onSubmit={handleSubmit} id='login-form' className=''>
          <header className='text-xl uppercase'>Вход</header>
          <fieldset className='text-left'>
            <section className='mb-4'>
              <label className='uppercase text-gray-600'>
                Имя пользователя:
              </label>
              <label className='input'>
                <input
                  className='form-control w-full'
                  type='username'
                  name='username'
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
              </label>
            </section>
            <section className='mb-4'>
              <label className='uppercase text-gray-600'>Пароль:</label>
              <label className='input'>
                <input
                  className='form-control w-full'
                  type='password'
                  name='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
            </section>
          </fieldset>
          <footer>
            <button
              type='submit'
              className='w-full rounded-full bg-green-600 py-2 text-lg uppercase leading-relaxed text-white'
            >
              Войти
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};

export default Login;
