import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { API2 } from '../api/api';

toast.configure();

export const ORGANISATIONS = [
  {
    id: 1,
    name: 'ФОП Єрмаков С.М.',
  },
  {
    id: 2,
    name: 'ФОП Лавриненко О.Л.',
  },
  {
    id: 3,
    name: 'ТОВ "ІМ"',
  },
  {
    id: 4,
    name: 'ТОВ "ІМС"',
  },
];

export const initialValues = {
  edrpou: '',
  name: '',
  preferredOrganisation: 1,
  isGov: false,
  phones: '',
  email: '',
  discount: 0,
};

export const InvoiceSchema = Yup.object().shape({
  edrpou: Yup.string()
    .min(8, 'Занадто короткий')
    .max(10, 'Занадто довгий')
    .required('Обовʼязково'),
  name: Yup.string().min(3, 'Занадто короткий').required('Обовʼязковий'),
  email: Yup.string().email(),
  discount: Yup.number(),
  phones: Yup.string().min(10, 'Занадто короткий'),
});

export const onSubmit = (values: any, actions: any, data: any) => {
  const { total, licTypeCode, onSendComplete } = data;
  const { discount } = values;
  const postData = {
    ...values,
    price: total - discount,
    licType: licTypeCode,
    preferredOrganisation: parseInt(values.preferredOrganisation),
  };
  const toastId = toast.info('Надсилання рахунку...');
  API2.invoices.createCustomInvoice(postData).then((data) => {
    onSendComplete && onSendComplete(data); // №${data.invoice_id}
    actions.setSubmitting(false);
    toast.dismiss(toastId);
  });
};
