import { Form, Formik } from 'formik';
import pick from 'lodash/pick';
import React from 'react';
import * as Yup from 'yup';
import Card from '../components/Card';
import Field from '../components/FormElements/Field';
import Switcher from '../components/FormElements/Switcher';

type ChangePartnerProps = {
  buyer: string;
  edrpou: string;
  address: string;
  contact_person: string;
  comment: string;
  send_pay_reminders: boolean;
  government: boolean;
  post_address: string;
  post_receiver: string;
  post_receiver2: string;
  email: string;
  email_edo: string;
  contract_fio: string;
  contract_bank: string;
  contract_fio_footer: string;
  contract_warranty: string;
  // [key: string]: any
};

const ValidationSchema = Yup.object().shape({
  buyer: Yup.string().required('Обов`язково'),
  email: Yup.string().email('Некоректний email'),
  email_edo: Yup.string().email('Некоректний email'),
  edrpou: Yup.string().required('Введіть код'),
  address: Yup.string(),
  contact_person: Yup.string(),
  comment: Yup.string(),
  send_pay_reminders: Yup.boolean(),
  government: Yup.boolean(),
  post_address: Yup.string(),
  post_receiver: Yup.string(),
  post_receiver2: Yup.string(),
  contract_fio: Yup.string(),
  contract_bank: Yup.string(),
  contract_fio_footer: Yup.string(),
  contract_warranty: Yup.string(),
});

type FormProps = {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialValues: ChangePartnerProps;
};

const PartnerEditForm = ({
  onSubmit,
  onCancel,
  initialValues,
}: FormProps): JSX.Element => {
  const pickValues: ChangePartnerProps = pick(initialValues, [
    'buyer',
    'edrpou',
    'address',
    'contact_person',
    'comment',
    'send_pay_reminders',
    'government',
    'post_address',
    'post_receiver',
    'post_receiver2',
    'email',
    'email_edo',
    'contract_fio',
    'contract_bank',
    'contract_fio_footer',
    'contract_warranty',
  ]);

  // for (const key of Object.keys(pickValues)) {
  //   if (pickValues[key] === null) {
  //     pickValues[key] = '';
  //   }
  // }
  if (!pickValues.contact_person) pickValues.contact_person = '';
  if (!pickValues.post_address) pickValues.post_address = '';

  return (
    <Formik
      initialValues={pickValues}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={ValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form className='md:mb-5 md:p-2'>
          <Card>
            <div className='flex flex-wrap md:space-x-4'>
              <div className='flex-auto space-y-3 md:flex-1'>
                <Field title='Назва' type='text' name='buyer' />
                <Field title='ЄДРПОУ' name='edrpou' type='text' />
                <Field title='Адреса' name='address' type='text' />
                <Field
                  title='Контактна особа'
                  name='contact_person'
                  type='text'
                />
                <div className='flex justify-between space-x-3'>
                  <Field
                    className='flex-1'
                    title='Email'
                    name='email'
                    type='email'
                  />
                  <Field
                    className='flex-1'
                    title='Email (ЭДО)'
                    name='email_edo'
                    type='email'
                  />
                </div>
                <Field title='Коментар' name='comment' type='text' />
                {values.edrpou.length !== 10 && (
                  <Switcher
                    checked={values.government}
                    onChange={(v) => setFieldValue('government', v)}
                  >
                    <div>Бюджетник</div>
                    <div className='text-xs font-normal text-gray-600'>
                      Надсилається інший вид договору та акту без дати
                    </div>
                  </Switcher>
                )}
                <Switcher
                  checked={values.send_pay_reminders}
                  onChange={(v) => setFieldValue('send_pay_reminders', v)}
                >
                  <div>Нагадуваня про сплату</div>
                  <div className='text-xs font-normal text-gray-600'>
                    Надсилати нагадування про закінчення ліцензії
                  </div>
                </Switcher>
              </div>
              <div className='flex-auto md:flex-1'>
                <div className='space-y-3'>
                  <Field
                    title='Поштовий адрес'
                    name='post_address'
                    type='text'
                    placeholder='Поштовий адрес'
                  />
                  <Field
                    title='Отримувач'
                    name='post_receiver'
                    type='text'
                    placeholder='Отримувач на пошті'
                  />
                  <Field
                    // title='Получатель (строка 2)'
                    name='post_receiver2'
                    type='text'
                    placeholder='Отримувач на пошті (строка 2)'
                  />
                </div>
                <div className='mt-3 flex flex-wrap space-y-3'>
                  <div className='w-full space-y-3'>
                    <legend>Для договору</legend>
                    <Field
                      title='Представник для договору (посада та ПІБ)'
                      name='contract_fio'
                      type='text'
                      placeholder='директора Петренка Сергія Сергійовича'
                    />
                    <Field
                      title='Банковскі реквізити'
                      name='contract_bank'
                      type='text'
                      placeholder='Банковскі реквізити'
                    />
                    <Field
                      title='Представник для договору (ініціали)'
                      name='contract_fio_footer'
                      type='text'
                      placeholder='Петренко С.С.'
                    />
                    <Field
                      title='Діє на підставі'
                      name='contract_warranty'
                      type='text'
                      placeholder='Статуту, ЗУ, ...'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-3'></div>
          </Card>
          <div className='flex space-x-2 p-2 md:pt-2'>
            <button
              className='btn-primary'
              // disabled={pristine || submitting}
              type='submit'
            >
              Зберегти
            </button>
            <button className='btn-secondary' onClick={onCancel}>
              Скасувати
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PartnerEditForm;
