import React from 'react';

const UkrposhtaLogo = (props: { className?: string }): JSX.Element => (
  <div {...props}>
    <svg
      className='text-orange-500 fill-current '
      width='100%'
      height='100%'
      viewBox='0 0 512 512'
    >
      <path
        transform='scale(1, -1) translate(0, -512)'
        d='M416 218l-170 39c-50 11-82 61-70 112 11 50 61 82 112 71 50-12 82-62 71-113-6-24-21-45-42-58l0 0c-1-1-1-1-1-2 0-1 1-2 2-2l75-17c2-1 4 0 5 1 21 29 33 63 33 99 0 87-67 159-154 164-1 0-5 0-7 0-39 1-78-11-110-34-85-59-106-176-46-261l150-215c1-1 2-1 3-1 1 0 2 0 3 1l148 211c0 1 1 1 1 2 0 2-2 3-3 3'
      />
    </svg>
  </div>
);

export default UkrposhtaLogo;
