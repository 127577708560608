import React, { useState } from 'react';
import { ClipboardCopyIcon } from '@heroicons/react/outline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { classNames } from '../../utils';
import { Transition } from '@headlessui/react';

type Props = {
  clipboardText: string;
  className?: string;
  title?: string;
};

const ClipboardButton = ({ clipboardText, title }: Props): JSX.Element => {
  const [processing, setProcessing] = useState(false);
  return (
    <CopyToClipboard
      text={clipboardText}
      onCopy={() => {
        setProcessing(true);
        setTimeout(() => setProcessing(false), 1000);
      }}
    >
      <div className='relative z-10' title={title}>
        <ClipboardCopyIcon
          className={classNames(
            'w-6 h-6 transform transition-transform hover:text-purple-700 cursor-pointer',
            processing ? 'rotate-12 text-purple-900' : 'text-purple-500'
          )}
        />
        {/* {processing && ( */}
        <Transition
          show={processing}
          enter='transition-opacity duration-100'
          enterFrom='opacity-0'
          enterTo='opacity-80'
          leave='transition-opacity duration-150'
          leaveFrom='opacity-80'
          leaveTo='opacity-0'
        >
          <div className='absolute bg-purple-300 px-2 py-1 text-purple-800 shadow rounded text-sm ml-2 opacity-80'>
            Скопировано!
          </div>
        </Transition>
        {/* )} */}
      </div>
    </CopyToClipboard>
  );
};
// 'h-8 w-8 inline-flex items-center justify-center rounded shadow bg-gray-300 hover:bg-gray-400 hover:text-gray-900 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500',
export default ClipboardButton;
