import useSWR from 'swr';
import { CallEvent } from '../types/CallEvent';
import { fetcher } from './utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

// {
//   "_id": "608a7ef8426beb5e98fb6d3e",
//   "created": "2021-04-29T09:40:08.340Z",
//   "from": "+380961619104",
//   "to": "0673138733",
//   "generalCallID": "2575090453",
//   "callType": "0",
//   "billsec": 0,
//   "waitsec": 0,
//   "answeredDate": "2021-04-29T09:40:10.683Z",
//   "extNumber": "907",
//   "partners": [
//     {
//       "_id": "5f92633bfc184988d020625f",
//       "name": "ПРЕДСТАВНИЦТВО \"ВЕСТІНГХАУЗ ЕЛЕКТРІК СВІДЕН АБ\"",
//       "edrpou": "26627601",
//       "partnerId": 64936,
//       "isGov": false,
//       "email": "alla.opanasenko@tmf-group.com",
//       "sendPayReminders": true,
//       "phones": [
//         "+380502488755",
//         "+380934691956",
//         "+380444289444",
//         "+380980817288",
//         "+380961619104"
//       ],
//       "lastNote": {
//         "content": "новый звитный не принят. Указала 4 рядка на вылучення и потом 4 на введення. Скорее нужно делать по одному рядку. В титулке заполнять графы 01-05 пока",
//         "partnerId": 64936,
//         "is_system": false,
//         "pin": false,
//         "noteId": "5dbe2444-2875-4b9d-b585-a173058034ef",
//         "user": {
//           "id": 54,
//           "token": "bceb7541b62832139c8858f9a83ebb84ff2bd385",
//           "username": "support7@sonatazvit.com.ua",
//           "fullName": "Ярослав Токаренко",
//           "email": "support7@sonatazvit.com.ua"
//         },
//         "userId": 54,
//         "created": "2021-04-29T08:54:09.113Z",
//         "modified": "2021-04-29T08:54:09.113Z",
//         "_id": "608a7431cf83fb05d88d0188"
//       },
//       "lastLicense": {
//         "edrpou": "26627601",
//         "name": "Вестінгхауз Електрик Свіден АБ",
//         "state": "Не актуальна",
//         "start": "2021-03-15T00:00:00.000Z",
//         "end": "2022-03-15T00:00:00.000Z",
//         "activatedDate": "2021-02-16T10:47:56.000Z",
//         "type": "Повна на рік",
//         "total": 350
//       }
//     }
//   ]
// }

export const useLastEvents = (direction: 0 | 1) => {
  const { data, error, mutate } = useSWR<CallEvent[], any>(
    `${MOLECULER}/private/calls/last/?direction=${direction}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    events: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
