import React, { useEffect } from 'react';
import { io } from 'socket.io-client';
import { API2 } from '../../api/api';
import minimatch from 'minimatch';

const DEBUG = process.env.REACT_APP_STAGE === 'dev';
const ENDPOINT = DEBUG
  ? 'http://127.0.0.1:3000'
  : 'https://test.sonatazvit.com.ua';

type onUpdateProps = { event: string; sender: string; payload: any };

type onUpdateFunc = (data: onUpdateProps) => void;

const SocketIO = ({
  channel,
  eventMatch = ['*'],
  onUpdate,
}: {
  channel: string;
  eventMatch?: string[];
  onUpdate: onUpdateFunc;
}): JSX.Element => {
  useEffect(() => {
    if (DEBUG) return;
    const token = API2.auth.get_token();
    if (!token) return;
    const socket = io(ENDPOINT, {
      query: {
        token,
      },
    });
    socket.on('connect', () => console.log(`Connect ${channel}`));
    socket.on('disconnect', () => console.log('Disconnected'));
    // socket.on(channel, onUpdate);
    socket.on(channel, (data: onUpdateProps) => {
      console.log('DATA SOCKET', data);
      const { event } = data;
      eventMatch.forEach((match) => {
        if (minimatch(event, match)) {
          console.log(`Fire ${event}`);
          return onUpdate(data);
        }
      });
    });
    return () => {
      socket.disconnect();
    };
  }, [onUpdate]);
  return <></>;
};

export default SocketIO;
