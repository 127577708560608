import { ExclamationCircleIcon } from '@heroicons/react/solid';
import React from 'react';
import { FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { getPostCodeInfo } from '../../api/moleculer';
import { postProviders } from '../../data/postProviders';
import { useRegions } from '../../hooks/useRegions';
import RectButton from '../Buttons/RectButton';

export type PostValues = {
  provider: 'vchasno' | 'ukrposhta' | 'novaposhta';
  email: string;
  postIndex: string;
  regionId: string | '';
  subregion: string;
  city: string;
  address: string;
  recipient: string;
  comment: string;
};

const defaultValues: PostValues = {
  provider: 'ukrposhta',
  email: '',
  postIndex: '',
  regionId: '',
  subregion: '',
  city: '',
  address: '',
  recipient: '',
  comment: '',
};

const AddPostForm = ({ onSubmit }: { onSubmit: any }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<PostValues>({
    defaultValues: defaultValues,
    mode: 'onChange',
  });
  const onSubmitHandler: SubmitHandler<PostValues> = (values) => {
    onSubmit(values);
    reset();
  };
  const { regions } = useRegions();
  const provider = watch('provider');

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className='space-y-1'>
      <legend>Запланувати надсилання документів по рахунку</legend>
      <select {...register('provider')}>
        {postProviders.map((value) => (
          <option key={value.id} value={value.id}>
            {value.name}
          </option>
        ))}
      </select>
      {/* ВЧАСНО */}
      {provider === 'vchasno' && (
        <>
          <input
            type='text'
            {...register('email', {
              required: true,
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Вкажіть правильний email',
              },
            })}
            placeholder='Email'
          />
          <ErrorMessage errorField={errors.email} />
        </>
      )}
      {/* НОВА ПОШТА */}
      {provider === 'novaposhta' && (
        <>
          <input
            type='text'
            placeholder='Місто, відділення, одержувач, телефон'
            {...register('address', {
              required: true,
            })}
          />
          <ErrorMessage errorField={errors.address} />
        </>
      )}
      {/* УКРПОШТА */}
      {provider === 'ukrposhta' && (
        <div className='flex-auto space-y-1 md:flex-1'>
          <div className='flex space-x-1'>
            <div className='flex-1'>
              <input
                type='text'
                {...register('postIndex', { required: true })}
                placeholder='Індекс'
                onBlur={(e) => {
                  const postIndex = e.target.value;
                  if (postIndex !== '' && postIndex.length === 5) {
                    getPostCodeInfo(postIndex).then((data) => {
                      if (data.success !== false && data.regionId) {
                        setValue('city', data.cityUa);
                        setValue('regionId', data.regionId);
                        setValue('subregion', data.districtUa);
                        clearErrors();
                      } else {
                        setValue('city', '');
                        setValue('regionId', '');
                        setValue('subregion', '');
                      }
                    });
                  }
                }}
              />
              <ErrorMessage errorField={errors.postIndex} />
            </div>
            <div className='flex-1'>
              <select
                placeholder='Область'
                {...register('regionId', { required: true })}
              >
                <option value=''>Виберіть область</option>
                {regions?.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </select>
              <ErrorMessage errorField={errors.regionId} />
            </div>
          </div>
          <div className='flex space-x-1'>
            <div className='flex-1'>
              <input
                placeholder='Район'
                type='text'
                className='flex-1'
                {...register('subregion')}
              />
            </div>
            <div className='flex-1'>
              <input
                placeholder='Населений пункт'
                type='text'
                {...register('city', { required: true })}
              />
              <ErrorMessage errorField={errors.regionId} />
            </div>
          </div>
          <div>
            <input
              placeholder='вул.Івана Кожедуба, буд.1 оф. 1'
              type='text'
              {...register('address', {
                required: true,
              })}
            />
            <ErrorMessage errorField={errors.address} />
          </div>
        </div>
      )}
      <input placeholder='Коментар' type='text' {...register('comment')} />
      <div className='pt-2 text-center'>
        <RectButton
          className='bg-green-700 text-white hover:bg-green-800'
          type='submit'
        >
          Додати
        </RectButton>
      </div>
    </form>
  );
};

const ErrorContainer = ({ children }: { children: any }) => (
  <div className='ml-1 mt-0.5 inline-flex items-center rounded bg-red-200 py-0.5 pr-2 pl-1 text-xs text-red-600'>
    {children}
  </div>
);

const ErrorMessage = ({
  errorField,
}: {
  errorField: FieldError | undefined;
}) => (
  <>
    {errorField?.type === 'required' && (
      <ErrorContainer>
        <ExclamationCircleIcon className='h-4 w-4' />
        Це поле обов&apos;язкове
      </ErrorContainer>
    )}
    {errorField?.message && (
      <ErrorContainer>
        <ExclamationCircleIcon className='h-4 w-4' />
        {errorField.message}
      </ErrorContainer>
    )}
  </>
);

export default AddPostForm;
