import React from 'react';

type Props = {
  total: number | '-';
};
const OverallStatsView = ({ total }: Props): JSX.Element => (
  <span className='text-white'>
    {total === '-' || total === 0 ? 0 : Math.round(total * 100) / 100} грн.
  </span>
);

export default OverallStatsView;
