import { Field as _Field } from 'formik';
import React from 'react';
import Label from './Label';

interface IFieldValues {
  id: number | string;
  name: string;
}

type Props<T> = {
  label: string;
  name: string;
  values: T[];
};

function CustomSelectField<T extends IFieldValues>({
  label,
  name,
  values,
}: Props<T>) {
  const options = values.map((v) => (
    <option key={v.id} value={v.id}>
      {v.name}
    </option>
  ));
  return (
    <div className='mt-3'>
      <Label name={name} label={label} />
      <div>
        <_Field as='select' name={name}>
          {options}
        </_Field>
      </div>
    </div>
  );
}

export default CustomSelectField;
