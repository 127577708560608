import React from 'react';

const TotalPanel = ({
  total,
  discount,
}: {
  total: number;
  discount: number;
}) => (
  <div className='my-2 justify-end py-4 text-right text-lg md:flex md:items-center'>
    <div className='pr-4 text-gray-600'>
      Сума: {total} грн. Знижка: {discount} грн.
    </div>
    <div className='inline-flex rounded bg-gray-100 px-4 py-2 font-semibold text-gray-700'>
      Разом: {total - discount} грн.
    </div>
  </div>
);

export default TotalPanel;
