import React from 'react';
import { classNames } from '../../utils';

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  children: React.ReactNode;
  as?: string;
  url?: string;
  type?: 'button' | 'submit' | 'reset';
  title?: string;
  disabled?: boolean;
};

const RectButton = ({
  onClick,
  className = 'text-sm text-gray-700 bg-gray-200 border-gray-300 hover:bg-gray-300',
  children,
  as: asEl,
  url,
  title,
  type = 'button',
  disabled = false,
}: Props): JSX.Element => {
  if (asEl === 'a')
    return (
      <a
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        title={title}
        // className={`h-8 w-8 flex items-center justify-center rounded shadow ${className}`}
        className={classNames(
          'inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm -font-medium  rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          className
        )}
      >
        {children}
      </a>
    );
  return (
    <button
      className={classNames(
        'disabled:opacity-70 inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        className
      )}
      onClick={onClick}
      type={type}
      title={title}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default RectButton;
