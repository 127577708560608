import React from 'react';
import { UserCircleIcon } from '@heroicons/react/solid';

const NoteAuthor = ({ author }: { author: string | null }): JSX.Element => {
  if (author) {
    return (
      <div className='inline-flex items-center text-green-700'>
        <UserCircleIcon className='h-5 w-5 text-green-500' />
        {author}
      </div>
    );
  }
  return <></>;
};

export default NoteAuthor;
