import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Card = ({ children, className }: Props): JSX.Element => {
  return (
    <div
      className={`-overflow-hidden bg-white shadow sm:rounded-lg ${className}`}
    >
      <div className='px-2 py-3 sm:p-4 sm:px-3'>{children}</div>
    </div>
  );
};

export default Card;
