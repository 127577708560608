import React from 'react';
import get from 'lodash/get';
import { FieldProps } from './types';
import moment from 'moment';

const DateField: React.FC<FieldProps<string>> = ({
  source,
  resource,
  ...props
}) => {
  const value = get(resource, source);
  return (
    <div {...props}>{value ? moment(value).format('DD.MM.YY HH:mm') : '-'}</div>
  );
};

export default DateField;
