import React from 'react';

const COLOURS = [
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#1abc9c',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#ecf0f1',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
];

interface IOrganisationAvatar {
  id?: number;
  edrpou?: string;
  prefix: string;
}

const OrganisationAvatar = ({
  organisation,
}: {
  organisation: IOrganisationAvatar;
}) => {
  let letters = 'АА';
  // let colorIndex = '#7f8c8d';
  let colorIndex = 0;
  if (organisation) {
    letters = organisation.prefix;
    if (organisation.id) {
      colorIndex = organisation.id % 20;
    } else if (organisation.edrpou) {
      colorIndex = parseInt(organisation.edrpou) % 20;
    }
  }
  const bgColor = COLOURS[colorIndex];
  return (
    <span
      style={{ background: bgColor }}
      className='flex h-8 w-8 items-center justify-center rounded-full border border-white text-center text-xs uppercase text-white'
    >
      <span className='relative block leading-none'>{letters}</span>
    </span>
  );
};

export default OrganisationAvatar;
