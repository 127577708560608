import React from 'react';

type Props = {
  enable: boolean;
};

const PayReminder = ({ enable }: Props): JSX.Element => {
  return (
    <div>
      Нагадування про сплату:
      {enable ? (
        <span className='pl-1 font-bold text-green-700'>Так</span>
      ) : (
        <span className='pl-1 font-bold text-red-600'>Ні</span>
      )}
    </div>
  );
};
export default PayReminder;
