import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SearchForm from '../../components/SearchForm';
import { ListContext } from '../ListContextProvider';

const FilterSearch = ({source, processValue, placeholder}) => {
  const listContext = useContext(ListContext);
  const {filterValues, setFilterValues} = listContext;
  const setFilter = (value) => {
    let newValue = value;
    if (processValue) {
      newValue = processValue(value);
    }
    setFilterValues({...filterValues, [source]: newValue || undefined});
  };
  return (
    <SearchForm placeholder={placeholder} onSearch={setFilter} />
  );
};
FilterSearch.propTypes = {
  source: PropTypes.string.isRequired,
  processValue: PropTypes.func,
  placeholder: PropTypes.string,
};

export default FilterSearch;