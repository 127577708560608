import React from 'react';
import { ListControllerProps } from './types';

type Props = {
  value: ListControllerProps;
  children: React.ReactNode;
};

export const ListContext = React.createContext<Props['value']>(
  {} as Props['value']
);

const ListContextProvider = ({ value, children }: Props): JSX.Element => (
  <ListContext.Provider value={value}>{children}</ListContext.Provider>
);

export default ListContextProvider;
