export const REGIONS = [
  { id: 1, name: 'Вінницька' },
  { id: 2, name: 'Волинська' },
  { id: 4, name: 'Дніпропетровська' },
  { id: 5, name: 'Донецька' },
  { id: 6, name: 'Житомирська' },
  { id: 7, name: 'Закарпатська' },
  { id: 8, name: 'Запорізька' },
  { id: 9, name: 'Івано-Франківська' },
  { id: 10, name: 'Київ' },
  { id: 11, name: 'Київська' },
  { id: 12, name: 'Кіровоградська' },
  { id: 3, name: 'Луганська' },
  { id: 14, name: 'Львівська' },
  { id: 15, name: 'Миколаївська' },
  { id: 16, name: 'Одеська' },
  { id: 17, name: 'Полтавська' },
  { id: 26, name: 'Рівненська' },
  { id: 18, name: 'Сумська' },
  { id: 19, name: 'Тернопільська' },
  { id: 20, name: 'Харківська' },
  { id: 21, name: 'Херсонська' },
  { id: 22, name: 'Хмельницька' },
  { id: 23, name: 'Черкаська' },
  { id: 25, name: 'Чернівецька' },
  { id: 24, name: 'Чернігівська' },
];

export const getRegionById = (regionId: number): string | null => {
  const regions = REGIONS.filter((regionInfo) => regionInfo.id === regionId);
  if (regions.length) return regions[0].name;
  return null;
};
