import { CashIcon, PhoneIncomingIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API2 } from '../api/api';
import { useStats } from '../hooks/useStats';
import { UserProvider } from '../providers/UserProvider';
import Documents from './Documents';
import SocketIO from './general/SocketIO';
import HomeView from './HomeView';
import IncomingCall from './IncomingCall';
import InvoiceDetails from './InvoiceDetails';
import InvoicesList from './InvoicesList';
import MyStats from './MyStats';
import Nav from './Nav';
import NewInvoice from './NewInvoice';
import OverallStatsView from './OverallStatsView';
import PartnerDetails from './PartnerDetails';
import PartnersList from './PartnersList';
import PaymentsList from './PaymentsList';
import PhonesPanel from './PhonesPanel';
import PostsList from './PostsList';
import ReminderList from './ReminderList';
import TasksList from './TasksList';

const MsgPayment = ({ total, name }: { total: number; name: string }) => (
  <div className='flex text-sm'>
    <div className='w-10 justify-center'>
      <CashIcon className='mr-2 h-8 w-8' />
    </div>
    <div>
      <div className='font-bold'>Надійшла оплата: {total} грн</div>
      <div>От {name}</div>
    </div>
  </div>
);

const Container = () => {
  const history = useHistory();
  const [userData, setUserData] = useState({ userId: undefined });
  const [token, setToken] = useState<string>();

  const _token = localStorage.getItem('token') || '';
  toast.configure();

  const { stats, mutate } = useStats();

  useEffect(() => {
    setToken(_token);
    if (!_token) {
      history.replace({ pathname: '/login' });
    }
    if (_token) {
      API2.users
        .getCurrentUser()
        .then((data) => {
          console.log('>', data);
          if (!data.user_id) {
            return history.push('/login');
          }
          setUserData({
            userId: data.user_id,
          });
        })
        .catch((reason) => {
          console.log('fail get user', reason);
          localStorage.setItem('token', '');
        });
    }
  }, [_token]);

  if (!userData.userId || !token) {
    return null;
  }

  return (
    <UserProvider>
      <SocketIO
        channel='bank'
        eventMatch={['*.newPayment']}
        onUpdate={(data) => {
          console.log('BANK EVENT', data);
          // event: "bank.newPayment"
          // payload:
          // client_acc: "UA393348510000000002600348651"
          // client_edrpou: "22726944"
          // client_mfo: "334851"
          // client_name: "ОСББ \"ОЛІМПІЄЦЬ-2\""
          // date: "2021-06-29 14:55:00"
          // details: "Оплата за надання консультаційних послуг та супроводу ПЗ \"СОНАТА\" 12 міс. зг. рах.№СЗ-416435 від 14.06.2021 року, без ПДВ"
          // full: {AUT_MY_CRF: "2929003816", AUT_MY_MFO: "305299", AUT_MY_ACC: "UA093052990000026005000115205", AUT_MY_NAM: "Єрмаков Сергій Миколайович підприєм", AUT_MY_MFO_NAME: "АТ КБ \"ПРИВАТБАНК\"", …}
          // my_acc: "UA093052990000026005000115205"
          // my_edrpou: "2929003816"
          // my_mfo: "305299"
          // number: "349"
          // real: true
          // ref: "HSKLL0629L4WVO.P"
          // total: 780

          const { payload } = data;
          const { total, client_name: clientName } = payload;
          if (total > 0) {
            toast.info(<MsgPayment total={total} name={clientName} />, {
              position: 'top-right',
            });
          }
          mutate();
        }}
      />
      <SocketIO
        channel='callcenter'
        eventMatch={['*.push']}
        onUpdate={((data: { payload: any }) => {
          const { payload } = data;
          console.log(payload);
          if (
            payload.requestType === 'receivedTheCall' &&
            payload.callType === '0'
          ) {
            const Msg = ({ number }: { number: string }) => (
              <div className='flex items-center'>
                <PhoneIncomingIcon className='mr-2 h-5 w-5' />
                Дзвінок з номера {number}
              </div>
            );
            const { generalCallID, srcNumber } = payload;
            toast.info(<Msg number={srcNumber} />, {
              position: 'bottom-right',
              autoClose: 15000,
              pauseOnFocusLoss: false,
              toastId: generalCallID,
            });
          }
          if (
            payload.requestType === 'hangupTheCall' ||
            payload.requestType === 'answerTheCall'
          ) {
            const { generalCallID } = payload;
            toast.dismiss(generalCallID);
          }
        }).bind(this)}
      />
      <Nav
        mailsCount={stats ? stats.mailCount : 0}
        paymentsCount={stats ? stats.paymentsCount : 0}
      />
      <div id='main' role='main'>
        <div className='mt-1 h-8 md:mt-0 md:h-10'></div>
        <div id='ribbon' className='w-full bg-gray-800 p-1 text-right'>
          <div>
            <PhonesPanel />
            <div className='ml-4 inline-block rounded bg-gray-700 px-2 py-1'>
              <OverallStatsView total={stats ? stats.total : 0} />
            </div>
          </div>
        </div>
        <div id='content' className='-p-2'>
          <Switch>
            <Route exact path='/' component={HomeView} />
            <Route exact path='/mail' component={PostsList} />
            <Route exact path='/queue' component={TasksList} />
            <Route exact path='/invoices/new' component={NewInvoice} />
            {/* <Route exact path='/invoices/token' component={TokenInvoice} /> */}
            <Route exact path='/invoices' component={InvoicesList} />
            <Route
              exact
              path='/invoices/:invoiceId'
              component={InvoiceDetails}
            />
            <Route exact path='/partners' component={PartnersList} />
            <Route
              exact
              path='/partners/:partnerId'
              component={PartnerDetails}
            />
            <Route exact path='/payments' component={PaymentsList} />
            <Route
              exact
              path='/incoming/:phoneNumber'
              component={IncomingCall}
            />
            <Route exact path='/stats' component={MyStats} />
            <Route exact path='/reminders' component={ReminderList} />
            <Route exact path='/documents' component={Documents} />
            <Route path='*' component={HomeView} />
          </Switch>
        </div>
      </div>
    </UserProvider>
  );
};

export default Container;
