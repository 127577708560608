import React from 'react';

interface ResourceProps {
  name: string;
  list: React.FC<{ name: string, orderBy: string }>;
  orderBy: string;
}

const Resource: React.FC<ResourceProps> = ({ name, list, orderBy }) => {
  const ListComponent = list;
  return (
    <ListComponent name={name} orderBy={orderBy} />
  );
};

export default Resource;