import useSWR from 'swr';

const MOLECULER = process.env.REACT_APP_MOLECULER;

export const usePriceList = () => {
  type Product = {
    period: number;
    individual: boolean;
    price: number;
    code: number;
  };
  const { data, error } = useSWR<Product[], unknown>(`${MOLECULER}/products/`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  return {
    prices: data,
    isLoading: !error && !data,
    isError: error,
  };
};
