import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { API2 } from '../api/api';
import { License } from '../types/License';
import { formatDate } from '../utils';

const LicenseItem = ({ license }: { license: License }): JSX.Element => (
  <div
    className={
      license.state === 'Не актуальна'
        ? moment(license.end) < moment()
          ? ' pb-1 text-sm text-gray-400'
          : ' pb-1 text-green-600'
        : ' pb-1 font-bold text-green-700'
    }
  >
    {license.removed ? <span className='text-red-500'>x </span> : null}
    <span>
      {formatDate(license.start, 'DD.MM.YY')}&nbsp;-&nbsp;
      {formatDate(license.end, 'DD.MM.YY')}
    </span>{' '}
    (<span style={{ whiteSpace: 'nowrap' }}>{license.type}</span>)
  </div>
);

const PartnerLicenses = ({ edrpou }: { edrpou: string }): JSX.Element => {
  const [licenses, setLicenses] = useState([]);
  useEffect(() => {
    let isConnected = true;
    const fetch = async () => {
      const licenses = await API2.moleculer.getLicenses(edrpou);
      const json = await licenses.json();
      const sorted = json.licenses.sort((a: License, b: License) =>
        moment(a.start) < moment(b.start) ? 1 : -1
      );
      if (isConnected) {
        setLicenses(sorted);
      }
    };
    fetch();
    return () => {
      isConnected = false;
    };
  }, []);

  return <>{licenses ? <LicensesList licenses={licenses} /> : null}</>;
};

const LicensesList = ({ licenses }: { licenses: License[] }) => {
  const licensesList = licenses.map((license) => (
    <li key={license.start}>
      <LicenseItem license={license} />
    </li>
  ));
  return <ul>{licensesList}</ul>;
};

export default PartnerLicenses;
