import { Menu } from '@headlessui/react';
import React from 'react';
import { classNames } from '../../utils';

type Props = {
  onClick?: () => void;
  // onClick?: React.MouseEventHandler<
  //   HTMLLinkElement | HTMLAnchorElement | HTMLButtonElement
  // >;
  children: React.ReactNode;
  href?: string;
  target?: '_blank';
};

const DropDownItemA = ({
  href,
  children,
  target,
  onClick,
  ...props
}: Props): JSX.Element => {
  return (
    <Menu.Item>
      {({ active }) => (
        <a
          target={target}
          {...props}
          href={href || ''}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick && onClick();
          }}
          className={classNames(
            active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
            'block items-center px-4 py-2 text-sm'
          )}
        >
          {children}
        </a>
      )}
    </Menu.Item>
  );
};

export default DropDownItemA;
