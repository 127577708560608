import React from 'react';

const PartnerPhonesList = ({
  phones,
}: {
  phones: {
    number: string;
  }[];
}) => (
  <>
    {phones && phones.length ? (
      <div className='flex'>
        <div className='pr-2 text-gray-400'>Телефони в базе:</div>
        <div className=''>
          {phones.map((phone) => {
            return (
              <div className='text-sm text-gray-500' key={phone.number}>
                {phone.number}
              </div>
            );
          })}
        </div>
      </div>
    ) : null}
  </>
);

export default PartnerPhonesList;
