import React from 'react';
import { Menu } from '@headlessui/react';
import { classNames } from '../../utils';

type Props = {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
  href?: string;
};

const DropDownHeader = ({ children }: Props): JSX.Element => {
  return (
    <div className='px-4 pt-2'>
      <p className='text-sm truncate text-gray-400'>{children}</p>
    </div>
  );
};

export default DropDownHeader;
