import useSWR from 'swr';
import { oldFetcher } from './utils';

const OLD_API = process.env.REACT_APP_OLD_API;

export const usePartnerInvoices = (partnerId: string) => {
  const {
    data,
    error,
    mutate,
  } = useSWR(
    `${OLD_API}/api/v2/invoices/?partner__id=${partnerId}&page_size=500`,
    oldFetcher,
    { revalidateOnFocus: false }
  );
  return {
    invoices: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
