import moment from 'moment';
import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../hooks/utils';
import Spinner from '../RestView/Spinner';
import { classNames } from '../utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

const LicenseInfo = ({ edrpou }: { edrpou: string }) => {
  const { data, error } = useSWR(
    `${MOLECULER}/private/licenses/${edrpou}/last`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      refreshInterval: 160000,
    }
  );
  const isLoading = !error && !data;

  if (isLoading) {
    return <Spinner />;
  }
  if (data && data.end) {
    return (
      <span
        className={classNames(
          data.state === 'Актуальна'
            ? 'bg-green-400 text-green-800'
            : 'bg-gray-400 text-gray-800',
          'rounded-full px-2 py-0.5 text-xs'
        )}
      >
        {moment(data.end).format('DD.MM.YY')}
      </span>
    );
  }
  return (
    <span className='rounded-full bg-gray-300 px-2 py-0.5 text-xs text-gray-600'>
      Ліцензія відсутня
    </span>
  );
};

export default LicenseInfo;
