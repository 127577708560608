import { ExclamationIcon } from '@heroicons/react/outline';
import React from 'react';
import { useEdrpouInfo } from '../hooks/useEdrpouInfo';
import Spinner from '../RestView/Spinner';
import { isFop, isOrganisation } from '../utils';

type EdrpouInfoProps = {
  edrpou: string;
};

const EdrpouInfo = ({ edrpou }: EdrpouInfoProps): JSX.Element => {
  if (!edrpou || !(isFop(edrpou) || isOrganisation(edrpou))) return <></>;
  const { info, isLoading } = useEdrpouInfo(edrpou);
  if (isLoading) return <Spinner />;
  return (
    <div>
      <h2 className='header1'>Данні з ЄДРПОУ:</h2>
      {info && Object.keys(info).length ? (
        <div className='space-y-2 text-gray-900'>
          <div>{info.short_name}</div>
          {!isFop(edrpou) && <div>{info.name}</div>}
          {info.address && <div>{info.address}</div>}
          {info.kved && <div>{info.kved}</div>}
          {!isFop(edrpou) && <div>{info.boss}</div>}
          {info.sti && <div className='text-xs text-gray-500'>{info.sti}</div>}
          {info.pdvStatus && (
            <>
              <h2 className='header1'>Данні з реєстру ПДВ:</h2>
              <div>
                {info.vatCode}{' '}
                <span className='text-green-500'>(Платник ПДВ)</span>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className='flex space-x-1 text-red-600'>
          <ExclamationIcon className='h-5 w-5' /> <div>Не знайдено</div>
        </div>
      )}
    </div>
  );
};

export default EdrpouInfo;
