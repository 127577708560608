import React from 'react';
import { formatDate } from '../../utils';

type PaymentFromDjango = {
  id: string;
  date: Date;
  details: string;
  edrpou: string;
  name: string;
  total: number;
};

const PaymentsList = ({
  payments,
}: {
  payments: PaymentFromDjango[];
}): JSX.Element => {
  const paymentsList = payments.map(function (order) {
    const date = formatDate(order.date, 'DD.MM.YY HH:mm');
    return (
      <div key={order.id} className='rounded p-2 bg-orange-200 mb-2'>
        <b>{order.total}</b> {date}{' '}
        <span className='small'>{order.details}</span>
        <br />
        <b>{order.edrpou}</b> {order.name} <br />
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'/admin/documents/paymentorder/' + order.id + '/'}
        >
          Админ
        </a>
      </div>
    );
  });
  return <>{paymentsList}</>;
};
export default PaymentsList;
