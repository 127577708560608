import React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, DotsVerticalIcon } from '@heroicons/react/solid';
import { classNames } from '../../utils';

type Props = {
  title: string;
  children: React.ReactNode;
  dots?: boolean;
  left?: boolean;
};

const DropDown = ({
  title,
  children,
  dots = false,
  left = false,
}: Props): JSX.Element => {
  return (
    <Menu as='div' className='relative inline-block text-left'>
      {({ open }) => (
        <>
          {dots ? (
            <div>
              <Menu.Button className='flex items-center text-gray-400 rounded-full hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'>
                <span className='sr-only'>{title}</span>
                <DotsVerticalIcon className='w-5 h-5' aria-hidden='true' />
              </Menu.Button>
            </div>
          ) : (
            <div>
              <Menu.Button className='z-0 inline-flex justify-center w-full px-4 py-2 text-sm text-gray-700 bg-gray-200 border border-gray-300 rounded-md shadow-sm -font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'>
                {title}
                <ChevronDownIcon
                  className='w-5 h-5 ml-2 -mr-1'
                  aria-hidden='true'
                />
              </Menu.Button>
            </div>
          )}

          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              static
              className={classNames(
                'z-20 origin-top-right absolute mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-200 py-1',
                left ? 'right-0' : 'left-0'
              )}
            >
              {children}
              {/* <div className='py-1'>{children}</div> */}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default DropDown;
