import React from 'react';
import PartnerLink from './PartnerLink';

type InvoiceInfoProps = {
  invoice: any;
  onSetDocumentsOnPayer: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const InvoiceInfo = (props: InvoiceInfoProps): JSX.Element => {
  const { invoice, onSetDocumentsOnPayer } = props;
  const partner_for_documents = invoice.partner_for_documents;
  return (
    <>
      {invoice.email ? (
        invoice.email !== invoice.partner.email ? (
          <span className='text-sm text-red-500'>
            Email в рахунку відрізняється ({invoice.email})
          </span>
        ) : null
      ) : (
        <span className='text-sm text-red-600'>
          Email в рахунку не вказаний
        </span>
      )}
      {invoice.comment && <div>{invoice.comment}</div>}
      {invoice.paid && (
        <div>
          <div>
            <label className=' flex items-center space-x-1'>
              <input
                type='checkbox'
                checked={invoice.documents_on_payer}
                onChange={onSetDocumentsOnPayer}
                className='checkbox style-0'
              />
              <span>Документи на платника</span>
            </label>
          </div>
          {partner_for_documents && (
            <div className='pl-5'>
              <span className='text-gray-500'>Для документів:</span>
              <br />
              <PartnerLink partner={partner_for_documents} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default InvoiceInfo;
