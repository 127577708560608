import useSWR from 'swr';
import { RestResult } from '../providers/types';
import { UseSwrResponse } from './swrTypes';
import { fetcher } from './utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

// {
//   "_id": "5f91c44253beeaab00dffaa2",
//   "name": "Єрмаков Сергій Миколаевич",
//   "edrpou": "2929003816",
//   "partnerId": 1,
//   "phones": [
//       "+380969236635"
//   ],
//   "lastLicense": {
//       "_id": "5f95ccaf186abfea3deadf5d",
//       "edrpou": "2929003816",
//       "name": "ФОП Ермаков Сергей Николаевич",
//       "state": "Актуальна",
//       "start": "2020-08-04T00:00:00.000Z",
//       "end": "2021-08-04T00:00:00.000Z",
//       "activatedDate": "2020-08-04T11:59:48.000Z",
//       "type": "Повна на рік",
//       "total": 142,
//       "date": "2021-08-04T00:00:00.000Z"
//   },
//   "lastNote": {
//       "_id": "608977b32d7f65157c7bb6e0",
//       "content": "test 5 test 5 test 6 test tetst",
//       "partnerId": 1,
//       "is_system": false,
//       "pin": false,
//       "noteId": "a5187c88-6676-4e3b-b421-7251d4dbd810",
//       "user": {
//           "id": 1,
//           "token": "8d454f7d724f916dbfa09a46a7112760b6c38f49",
//           "username": "root",
//           "fullName": "Сергей Ермаков",
//           "email": "info@bcbiz.com.ua"
//       },
//       "userId": 1,
//       "created": "2021-04-28T14:56:51.382Z",
//       "modified": "2021-04-29T07:18:58.609Z"
//   }
// }

type PartnerData = {
  _id: string;
  name: string;
  edrpou: string;
  partnerId: number;
  phones: string[];
  // lastLicense?: any;
  // lastNote?: any;
};

export const usePartnersByPhone = (
  phone: string
): UseSwrResponse & { partners: PartnerData[] | undefined } => {
  const url = encodeURI(`${MOLECULER}/private/partners/byphone/${phone}`);
  const { data, error, mutate } = useSWR<PartnerData[], any>(
    phone && phone.length > 6 ? url : null,
    fetcher
  );
  return {
    partners: data,
    isLoading: !error && !data && phone !== undefined && phone.length > 6,
    isError: error,
    mutate,
  };
};
