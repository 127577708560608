import React from 'react';
import RectButton from '../Buttons/RectButton';

type Props = {
  data: {
    partnerId: number;
    edrpou: string;
    name: string;
  };
  onAppend: (partnerId: number) => void;
};

const OrganisationWithAction = ({ data, onAppend }: Props): JSX.Element => (
  <span>
    <div>
      <span className='font-bold text-gray-700'>{data.edrpou}</span> {data.name}
    </div>
    <div className='py-1'>
      <RectButton onClick={() => onAppend(data.partnerId)}>
        Закрепить клиента за номером
      </RectButton>
    </div>
  </span>
);

export default OrganisationWithAction;
