import useSWR from 'swr';
import { fetcher } from './utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

type Props = {
  number: string;
};

export const usePhones = (partnerId: string) => {
  const { data, error, mutate } = useSWR<Props[], any>(
    `${MOLECULER}/private/partners/${partnerId}/phones/`,
    fetcher
  );
  return {
    phones: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
