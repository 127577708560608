import { CheckIcon, PaperClipIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 } from 'uuid';
import { API2 } from '../api/api';
import { usePartnerNotes } from '../hooks/usePartnerNotes';
import Spinner from '../RestView/Spinner';
import SocketIO from './general/SocketIO';
import NotesList from './Note/NotesList';

toast.configure();

type NotesFormProps = {
  partnerId: number;
  onNoteSubmit: (content: string) => void;
  onCompleteUpload: () => void;
};

const NotesForm = ({
  partnerId,
  onNoteSubmit,
  onCompleteUpload,
}: NotesFormProps): JSX.Element => {
  const [content, setContent] = useState('');

  const uploadFile = (file: EventTarget & HTMLInputElement) => {
    if (file && file.files) {
      const data = new FormData();
      data.append('partnerId', partnerId.toString());
      data.append('file', file.files[0]);
      const toastId = toast.info('Завантаження файлу...');
      API2.moleculer.uploadFileNote(data).then(() => {
        toast.dismiss(toastId);
        onCompleteUpload && onCompleteUpload();
      });
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!content.trim()) {
      return;
    }
    onNoteSubmit(content);
    setContent('');
  };

  return (
    <form className='relative' onSubmit={handleSubmit}>
      <div className='flex items-center'>
        <div className='flex'>
          <label
            htmlFor='file-upload'
            className='relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-lg bg-white font-medium text-gray-500 shadow focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-gray-600'
          >
            <span>
              <PaperClipIcon className='h-6 w-6' />
            </span>
            <input
              id='file-upload'
              name='file'
              type='file'
              className='sr-only'
              onChange={(e) => {
                e.preventDefault();
                uploadFile(e.target);
              }}
            />
          </label>
        </div>
        <input
          type='text'
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className='ml-1 h-12 border border-transparent bg-white py-2 pl-2 pr-20'
          placeholder='Введіть нотатку...'
        />
        <button
          type='submit'
          className='absolute right-0 flex h-12 w-12 items-center justify-center rounded-r-lg bg-gray-800 px-2 text-white'
        >
          <CheckIcon className='h-8 w-8' />
        </button>
      </div>
    </form>
  );
};

const PartnerNotes = ({ partnerId }: { partnerId: number }): JSX.Element => {
  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  const { notes, isLoading, mutate } = usePartnerNotes(partnerId);

  const handleNotePin = (noteId: string, pin: boolean) => {
    API2.moleculer.pinNote(noteId, pin).then(() => {
      notes &&
        mutate({
          ...notes,
          rows: [
            ...notes.rows.map((note) =>
              note._id === noteId ? { ...note, pin } : note
            ),
          ],
        });
    });
  };

  const handleNoteDelete = (noteId: string) => {
    API2.moleculer.deleteNote(noteId).then(() => {
      notes &&
        mutate(
          {
            ...notes,
            rows: [...notes.rows.filter((item) => item._id !== noteId)],
          },
          true
        );
    });
  };

  const handleNoteSubmit = (content: string) => {
    const newNote = {
      content,
      partnerId,
      // partnerId: parseInt(partnerId),
      is_system: false,
      pin: false,
      noteId: v4().toString(),
    };
    API2.moleculer.createNote(newNote).then((note) => {
      notes && mutate({ ...notes, rows: [note, ...notes.rows] });
    });
  };

  const handlePaste = (e: unknown) => {
    const items = (e as ClipboardEvent).clipboardData?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const toastId = toast.info('Копіювання зображення з буфера...');
          const blob = items[i].getAsFile();
          if (blob) {
            const _data = new FormData();
            _data.append('partnerId', partnerId.toString());
            _data.append('file', blob);
            API2.moleculer.uploadFileNote(_data).then(() => {
              mutate();
              toast.dismiss(toastId);
            });
          }
        }
      }
    }
  };

  const handleNoteChangeSubmit = (noteId: string, content: string) => {
    API2.moleculer.updateNote(noteId, content).then((updatedNote) => {
      notes &&
        mutate({
          ...notes,
          rows: [
            ...notes.rows.map((note) =>
              note._id === noteId ? updatedNote : note
            ),
          ],
        });
    });
  };

  return (
    <>
      <SocketIO
        channel='notes'
        onUpdate={({ event, payload }) => {
          console.log('EVENT', event, payload);
          switch (event) {
            case 'notes.removeNote':
              notes &&
                mutate(
                  {
                    ...notes,
                    rows: [
                      ...notes.rows.filter((note) => note._id !== payload._id),
                    ],
                  },
                  false
                );
              break;
            case 'notes.appendNote':
              notes &&
                payload.partnerId === partnerId &&
                mutate({
                  ...notes,
                  rows: [payload, ...notes.rows],
                });
              break;
            case 'notes.updateNote':
              notes &&
                mutate({
                  ...notes,
                  rows: [
                    ...notes.rows.map((note) =>
                      note._id === payload._id ? payload : note
                    ),
                  ],
                });
              break;

            default:
              break;
          }
        }}
      />
      <NotesForm
        onNoteSubmit={handleNoteSubmit}
        partnerId={partnerId}
        onCompleteUpload={() => {
          mutate();
        }}
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <NotesList
          data={notes?.rows}
          onNotePin={handleNotePin}
          onNoteDelete={handleNoteDelete}
          onNoteSubmit={handleNoteChangeSubmit}
        />
      )}
    </>
  );
};

export default PartnerNotes;
