import useSWR from 'swr';
import { UseSwrResponse } from './swrTypes';
import { fetcher } from './utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

type Props = {
  email: string;
  hash: string;
  discountOrg: number;
  discountFop: number;
};

export const useDiscount = (
  email?: string
): UseSwrResponse & { discounts: Props | undefined } => {
  const { data, error, mutate } = useSWR<Props[]>(
    email ? `${MOLECULER}/private/vipcabinet/users?email=${email}` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    discounts: data && data.length ? data[0] : undefined,
    isLoading: !error && !data && email !== '',
    isError: error,
    mutate,
  };
};
