import React from 'react';
import get from 'lodash/get';
import { FieldProps } from '../../../RestView/types';
import { classNames } from '../../../utils';

enum Tag {
  Clicker = 'clicked',
  Failed = 'failed',
  Delivered = 'delivered',
  Opened = 'opened',
  Unsubscribed = 'unsubscribed',
}

interface TagsProps {
  [key: string]: Tag[];
}

const TagsField: React.FC<FieldProps<TagsProps>> = ({
  resource,
  source,
  ...props
}) => {
  const value = get(resource, source);
  if (!value) return null;
  if (Array.isArray(value)) {
    const res = value.map((tag, i) => (
      <span
        className={classNames(
          'text-xs rounded-full px-2 py-1 text-white mr-1',
          tag === 'clicked'
            ? 'bg-green-600'
            : tag === 'failed'
            ? 'bg-red-600'
            : tag === 'opened'
            ? 'bg-yellow-600'
            : tag === 'unsubscribed'
            ? 'bg-red-700'
            : 'bg-blue-600'
        )}
        key={i}
      >
        {tag}
      </span>
    ));
    return <>{res}</>;
  }
  return <div {...props}>{JSON.stringify(value)}</div>;
};

export default TagsField;
