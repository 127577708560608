/* eslint-disable no-console */
import fetch from 'isomorphic-fetch';
import Messages from '../common/messages';
import { formatDate } from '../utils';

console.log('STAGE = ' + process.env.REACT_APP_STAGE);
// const DEBUG = process.env.REACT_APP_STAGE === 'dev';

const host = 'https://old.sonatazvit.com.ua';
const MOLECULER_API = 'https://test.sonatazvit.com.ua/api';
// if (process.env.REACT_APP_STAGE === 'dev') {
//   host = 'http://localhost:8000';
//   MOLECULER_API = 'http://localhost:3000/api';
// }

const headers = (): {
  Authorization: string;
  'Content-Type'?: string;
} => {
  const token = API2.auth.get_token();
  return {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };
};

const checkErrors = async (res: any) => {
  if (!res.ok) {
    let errorText;
    if (res.status === 404) {
      errorText = `Страница не найдена \n${res.url} `;
    } else {
      const json = await res.json();
      errorText = json.non_field_errors;
    }
    if (errorText) {
      Messages.error(errorText);
    }
    return Promise.reject(errorText);
  }
  let json: {
    result: boolean;
    description?: string;
    reason?: string;
  } = {
    result: true,
  };
  if (res.status !== 204) {
    json = await res.json();
    if (json && json.result === false) {
      Messages.error(json.reason);
    }
  }
  return Promise.resolve(json);
};

const saveToken = (key: string) => {
  localStorage.setItem('token', key);
  return key;
};

const deleteToken = () => {
  localStorage.removeItem('token');
};

const getToken = () => {
  const token = localStorage.getItem('token');
  return token;
};

const buildUrl = (relPath: string, params: any) => {
  const url = new URL(relPath, `${host}`);
  Object.keys(params).forEach((key) => {
    if (params[key]) url.searchParams.append(key, params[key]);
  });
  return url;
};

interface IInvoiceData {
  edrpou: string;
  name: string;
  email: string;
  phones: string;
  licType: string;
  price: number;
  isGov: boolean;
  qty: number;
  isGoods: boolean;
  preferredOrganisation: number;
  notSendEmail: boolean;
}

type TDayInfo = {
  id: number;
  date: Date;
  weekday: number;
};

export type TDayData = {
  id: number;
  value: number;
};

type TWorkData = {
  days: TDayData[];
  person: {
    id: number;
    name: string;
  };
};

export interface IWorktableData {
  data: TWorkData[];
  days: TDayInfo[];
  month: number;
  year: number;
}

export interface IProductInfo {
  code: number;
  name: string; //"Надання консультаційних послуг та супроводу ПЗ 'СОНАТА' строком на 12 місяців";
  full_name: string; //"Надання консультаційних послуг та супроводу ПЗ 'СОНАТА' строком на 12 місяців";
  full_name_contract: string; //"Надання консультаційних послуг та супроводу ПЗ 'СОНАТА' строком на 12 місяців";
  price: number; //390;
  period: number; //12;
  period_ru: string; //'12 месяцев';
  period_uk: string; //'12 місяців';
  individual: boolean; //true;
  sonataProductId: number; // 2;
}

export interface IQueueTask {
  id: number;
  date: string;
  title: string;
  name: string;
  body: string;
  details: string;
  total: number;
  invoice: {
    buyer: string;
    paid: boolean;
    total: number;
    id: number;
  };
  partner: {
    edrpou: string;
  };
}

export interface IOldPost {
  id: number; //42111;
  created: string; //'2022-04-26T15:26:33+03:00';
  printed: boolean;
  sent: boolean;
  sent_date: string | null;
  address: string; //'КНП"ХОЗЗНПД" ХОР,Скаржинці,Ярмолинецький р-н,Хмельницька обл.,32120';
  invoice: {
    id: number;
    date: string; //'2022-04-26T15:20:13+03:00';
    email: string; //'hopl1economist@ukr.net';
    phone: string; //'';
    paid: boolean;
    buyer: string; //'КОМУНАЛЬНЕ НЕКОМЕРЦІЙНЕ ПІДПРИЄМСТВО "ХМЕЛЬНИЦЬКИЙ ОБЛАСНИЙ ЗАКЛАД З НАДАННЯ ПСИХІАТРИЧНОЇ ДОПОМОГИ" ХМЕЛЬНИЦЬКОЇ ОБЛАСНОЇ РАДИ';
    name: string; //'КОМУНАЛЬНЕ НЕКОМЕРЦІЙНЕ ПІДПРИЄМСТВО "ХМЕЛЬНИЦЬКИЙ ОБЛАСНИЙ ЗАКЛАД З НАДАННЯ ПСИХІАТРИЧНОЇ ДОПОМОГИ" ХМЕЛЬНИЦЬКОЇ ОБЛАСНОЇ РАДИ';
    partner: number;
    comment: string; //'Создан из админпанели';
    url: string; //'/uk/i/b0e16e3ac93e777eed113297/';
    total: number;
    docs_packet_url: string; //'/uk/old_admin/invoices/462380/packet/';
    docs_invoice_akt: string; //'/uk/old_admin/invoices/462380/akt/';
    docs_partner_post: string; //'/uk/old_admin/partners/462380/post/';
    docs_invoice_contract: string; //'/uk/old_admin/invoices/462380/contract/';
    invoice_partner: {
      edrpou: string;
      email: string;
    };
    partner_for_documents?: {
      edrpou: string;
      email: string;
    };
    organisation: {
      id: number;
      prefix: string;
      edrpou: string;
    };
  };
  partner: {
    id: number;
    buyer: string; //'КОМУНАЛЬНЕ НЕКОМЕРЦІЙНЕ ПІДПРИЄМСТВО "ХМЕЛЬНИЦЬКИЙ ОБЛАСНИЙ ЗАКЛАД З НАДАННЯ ПСИХІАТРИЧНОЇ ДОПОМОГИ" ХМЕЛЬНИЦЬКОЇ ОБЛАСНОЇ РАДИ';
    edrpou: string; //'02004580';
    contract_fio: string; //'директора Демчука Володимира Миколайовича';
    name: string; //'КОМУНАЛЬНЕ НЕКОМЕРЦІЙНЕ ПІДПРИЄМСТВО "ХМЕЛЬНИЦЬКИЙ ОБЛАСНИЙ ЗАКЛАД З НАДАННЯ ПСИХІАТРИЧНОЇ ДОПОМОГИ" ХМЕЛЬНИЦЬКОЇ ОБЛАСНОЇ РАДИ';
    is_gov: boolean;
    post_address: string;
    post_receiver: string;
    post_receiver2: string;
  };
  post_type: null;
  comment: string; //'';
  partner_info: {
    id: number;
    buyer: string; //'КОМУНАЛЬНЕ НЕКОМЕРЦІЙНЕ ПІДПРИЄМСТВО "ХМЕЛЬНИЦЬКИЙ ОБЛАСНИЙ ЗАКЛАД З НАДАННЯ ПСИХІАТРИЧНОЇ ДОПОМОГИ" ХМЕЛЬНИЦЬКОЇ ОБЛАСНОЇ РАДИ';
    edrpou: string; //'02004580';
    contract_fio: string; //'директора Демчука Володимира Миколайовича';
    name: string; //'КОМУНАЛЬНЕ НЕКОМЕРЦІЙНЕ ПІДПРИЄМСТВО "ХМЕЛЬНИЦЬКИЙ ОБЛАСНИЙ ЗАКЛАД З НАДАННЯ ПСИХІАТРИЧНОЇ ДОПОМОГИ" ХМЕЛЬНИЦЬКОЇ ОБЛАСНОЇ РАДИ';
    is_gov: boolean;
    contract_bank: string;
  };
  post_address: string; //'КНП"ХОЗЗНПД" ХОР,Скаржинці,Ярмолинецький р-н,Хмельницька обл.,32120';
  receiver: string; //'КОМУНАЛЬНЕ НЕКОМЕРЦІЙНЕ ПІДПРИЄМСТВО "ХМЕЛЬНИЦЬКИЙ ОБЛАСНИЙ ЗАКЛАД З НАДАННЯ ПСИХІАТРИЧНОЇ ДОПОМОГИ"';
  return_reason: string; //'';
  provider: string; //'ukrposhta';
  email_edo: string | null;
  user: {
    id: number;
    username: string; //"root",
    first_name: string; //"Сергей",
    last_name: string; //"Ермаков",
    full_name: string; //"Сергей Ермаков",
    email: string; //"info@bcbiz.com.ua",
    is_staff: boolean; //true,
    is_superuser: boolean; //true,
    is_active: boolean; //true
  };
}

export const API2 = {
  auth: {
    login(username: string, password: string) {
      const url = `${host}/api/rest-auth/login/`;
      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      })
        .then((res) => res.json())
        .then((data) => saveToken(data.key));
    },
    logout() {
      deleteToken();
      return fetch(`${host}/api/rest-auth/logout/`, {
        method: 'POST',
        headers: headers(),
      }).then(checkErrors);
    },
    get_token() {
      return getToken();
    },
  },
  invoices: {
    setInvoicePaid(invoiceId: number | string, paid = true) {
      return fetch(`${host}/api/invoices/${invoiceId}/`, {
        method: 'PATCH',
        headers: headers(),
        body: JSON.stringify({
          paid: paid,
        }),
      }).then(checkErrors);
    },

    setOrganisation(invoiceId: number | string, organisationId: number) {
      return fetch(`${host}/api/invoices/${invoiceId}/set_organisation/`, {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({
          organisation: organisationId,
        }),
      }).then(checkErrors);
    },

    sendDocsRequest(invoiceId: number | string) {
      return fetch(`${host}/api/invoices/${invoiceId}/send-docs-request/`, {
        method: 'POST',
        headers: headers(),
      }).then(checkErrors);
    },

    sendContractRequest(invoiceId: number | string) {
      return fetch(`${host}/api/invoices/${invoiceId}/send-contract-request/`, {
        method: 'POST',
        headers: headers(),
      }).then(checkErrors);
    },

    sendDocsPack(invoiceId: number | string) {
      return fetch(`${host}/api/invoices/${invoiceId}/send-docs-pack/`, {
        method: 'POST',
        headers: headers(),
      }).then(checkErrors);
    },

    sendOriginalDocsSended(invoiceId: number | string) {
      return fetch(
        `${host}/api/invoices/${invoiceId}/send-original-docs-sended/`,
        {
          method: 'POST',
          headers: headers(),
        }
      ).then(checkErrors);
    },

    activateLicense(invoiceId: number | string, paymentId?: number) {
      return fetch(`${host}/api/invoices/${invoiceId}/activate-license/`, {
        method: 'POST',
        body: JSON.stringify({
          paymentId,
        }),
        headers: headers(),
      }).then(checkErrors);
    },

    // Зарегистрировать клиента у разработчика
    registerPartner(invoiceId: number | string) {
      return fetch(`${host}/api/invoices/${invoiceId}/register-partner/`, {
        method: 'POST',
        headers: headers(),
      }).then(checkErrors);
    },

    // Добавить в очередь на отправку документов почтовой службой(Укрпочта, Новая почта)
    sendPost(
      invoiceId: number | string,
      comment = '',
      provider = 'ukrposhta',
      email_edo = '',
      address = ''
    ) {
      const data = {
        comment,
        provider,
        email_edo,
        address,
      };
      return fetch(`${host}/api/invoices/${invoiceId}/send-post/`, {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(data),
      }).then(checkErrors);
    },

    // Отправка приглашения в Вчасно
    sendToVchasnoInvitation(invoiceId: number | string) {
      return fetch(
        `${host}/api/invoices/${invoiceId}/send-vchasno-invitation/`,
        {
          method: 'POST',
          headers: headers(),
        }
      );
    },

    setDocumentsOnPayer(invoiceId: string | number, documentsOnPayer = true) {
      return fetch(`${host}/api/invoices/${invoiceId}/`, {
        method: 'PATCH',
        headers: headers(),
        body: JSON.stringify({
          documents_on_payer: documentsOnPayer,
        }),
      }).then(checkErrors);
    },
    // createCustomInvoice(invoice_data: IInvoiceData) {
    createCustomInvoice(invoice_data: any) {
      const data = {
        edrpou: invoice_data.edrpou,
        name: invoice_data.name,
        email: invoice_data.email,
        phone: invoice_data.phones,
        product_id: invoice_data.licType,
        price: invoice_data.price,
        is_gov: invoice_data.isGov,
        qty: invoice_data.qty,
        is_goods: invoice_data.isGoods,
        preferredOrganisation: invoice_data.preferredOrganisation,
        notSendEmail: invoice_data.notSendEmail,
      };
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      console.log(data);
      return fetch(`${MOLECULER_API}/private/invoices/create-custom-invoice`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers,
      }).then((data) => data.json());
    },
    sendMyDocs(invoiceId: number | string) {
      return fetch(`${host}/api/invoices/${invoiceId}/send-my-docs/`, {
        method: 'POST',
        headers: headers(),
      }).then(checkErrors);
    },
  },
  partners: {
    getPartnerByEdrpou(edrpou: string) {
      return fetch(`${host}/api/v2/partners/?edrpou=${edrpou}`, {
        headers: headers(),
      }).then(checkErrors);
    },
    changePartner(partnerId: number | string, data: any) {
      return fetch(`${host}/api/partners/${partnerId}/`, {
        method: 'PATCH',
        headers: headers(),
        body: JSON.stringify(data),
      }).then(checkErrors);
    },
    changePartnerEmail(partnerId: number | string, email: string) {
      return fetch(`${host}/api/partners/${partnerId}/`, {
        method: 'PATCH',
        headers: headers(),
        body: JSON.stringify({
          email,
        }),
      }).then(checkErrors);
    },
    sendTeamViewerLink(partnerId: number | string, email = '') {
      const data = {
        email,
      };
      return fetch(`${host}/api/partners/${partnerId}/send-teamviewer/`, {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(data),
      }).then(checkErrors);
    },
  },
  payments: {
    setAsUnrecognized(paymentId: number | string) {
      return fetch(`${host}/api/payments/${paymentId}/set_as_unrecognized/`, {
        method: 'POST',
        headers: headers(),
      }).then(checkErrors);
    },
    setInvoice(paymentId: number | string, invoiceId: string | null) {
      let data;
      if (invoiceId === null) {
        data = {
          invoice: null,
        };
      } else {
        data = {
          invoice: {
            id: invoiceId,
          },
          custom: invoiceId,
        };
      }
      return fetch(`${host}/api/payments/${paymentId}/`, {
        method: 'PATCH',
        headers: headers(),
        body: JSON.stringify(data),
      }).then(checkErrors);
    },
    setAsCompleted(paymentId: string | number) {
      return fetch(`${host}/api/payments/${paymentId}/`, {
        method: 'PATCH',
        headers: headers(),
        body: JSON.stringify({
          completed: true,
        }),
      }).then(checkErrors);
    },
  },
  posts: {
    deletePostRequest(postId: number) {
      return fetch(`${host}/api/posts/${postId}/`, {
        method: 'DELETE',
        headers: headers(),
      }).then(checkErrors);
    },
    setAsSent(postId: number, isSent: boolean) {
      return fetch(`${host}/api/posts/${postId}/?all=1`, {
        method: 'PATCH',
        headers: headers(),
        body: JSON.stringify({
          sent: isSent,
          sent_date: formatDate(new Date(), 'YYYY-MM-DD'),
        }),
      }).then(checkErrors);
    },
    setAsPrint(postId: number, isPrinted: boolean) {
      return fetch(`${host}/api/posts/${postId}/?all=1`, {
        method: 'PATCH',
        headers: headers(),
        body: JSON.stringify({
          printed: isPrinted,
        }),
      }).then(checkErrors);
    },
  },
  stats: {
    getStats(
      url: string,
      params: {
        start: string;
        end: string;
      }
    ) {
      const fetchUrl = buildUrl(url, params);
      return fetch(fetchUrl.href, {
        headers: headers(),
      }).then(checkErrors);
    },
  },
  salary: {
    async getWorktable(month: number, year: number): Promise<IWorktableData> {
      const params = {
        month: month,
        year: year,
      };
      const url = buildUrl('/api/salary/worktable/', params);
      return fetch(url.href, {
        headers: headers(),
      }).then((res) => res.json());
    },
    async setWorktable(data: IWorktableData) {
      const res = await fetch(`${host}/api/salary/worktable/`, {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(data),
      });
      return checkErrors(res);
    },
  },
  sonata: {
    getStats() {
      return fetch(`${host}/api/sonata/stats/`, {
        headers: headers(),
      }).then((res) => res.json());
    },
  },
  users: {
    getCurrentUser() {
      console.log('Get current user...');
      return fetch(`${host}/api/get_current_user/`, {
        headers: headers(),
      }).then((res) => res.json());
    },
  },
  queue: {
    async getAll(): Promise<IQueueTask[]> {
      const res = await fetch(`${host}/api/queue/`, {
        headers: headers(),
      });
      return await res.json();
    },
    setCompleted(taskId: number) {
      return fetch(`${host}/api/queue/${taskId}/`, {
        method: 'PATCH',
        headers: headers(),
        body: JSON.stringify({
          completed: true,
        }),
      }).then(checkErrors);
    },
  },
  calls: {
    callToPhone(number: string, partnerId: string | null) {
      return fetch(`${host}/api/call-to-phone/`, {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify({
          phone_number: number,
          partner_id: partnerId,
        }),
      }).then(checkErrors);
    },
  },
  moleculer: {
    getPartnerInfo(edrpou: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(
        `${MOLECULER_API}/private/sonata/clients/${encodeURI(edrpou)}`,
        {
          method: 'GET',
          headers,
        }
      ).then((response) => {
        if (response.status === 404) {
          return {
            success: false,
            reason: 'Не найден',
          };
        }
        return response.json();
      });
    },
    sendActivationMail(invoiceId: number | string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(
        `${MOLECULER_API}/private/invoices/${invoiceId}/send-activation-mail/`,
        {
          method: 'POST',
          headers,
        }
      ).then((data) => data.json());
    },
    createPartner(partnerData: any) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/partners`, {
        method: 'POST',
        headers,
        body: JSON.stringify(partnerData),
      }).then((data) => data.json());
    },
    getPartnersByPhone(phone: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/partners/byphone/${phone}`, {
        method: 'GET',
        headers,
      }).then((data) => data.json());
    },
    getPhones(partnerId: number) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/partners/${partnerId}/phones/`, {
        method: 'GET',
        headers,
      }).then((data) => data.json());
    },
    appendPhone(partnerId: number | string, phone: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/partners/${partnerId}/phones/`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          phone,
        }),
      }).then((data) => data.json());
    },
    removePhone(partnerId: number | string, phoneNumber: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(
        `${MOLECULER_API}/private/partners/${partnerId}/phones/${phoneNumber}/`,
        {
          method: 'DELETE',
          headers,
        }
      ).then((data) => data.json());
    },
    createNote(note: any) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/notes/`, {
        method: 'POST',
        headers,
        body: JSON.stringify(note),
      })
        .then((data) => data.json())
        .then((data) => {
          data.noteId = data.noteId || data._id;
          console.log(data);
          return data;
        });
    },
    uploadFileNote(formData: any) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json'
      };
      return fetch(`${MOLECULER_API}/uploads/notes`, {
        method: 'POST',
        headers,
        body: formData,
      })
        .then((data) => data.json())
        .then((data) => {
          return data[0];
        });
    },
    updateNote(noteId: number | string, content: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/notes/${noteId}/`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({
          content,
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          data.noteId = data.noteId || data._id;
          return data;
        });
    },
    pinNote(noteId: string, pin: boolean) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/notes/${noteId}/`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({
          pin,
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          data.noteId = data.noteId || data._id;
          console.log(data);
          return data;
        });
    },
    deleteNote(noteId: number | string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/notes/${noteId}`, {
        method: 'DELETE',
        headers,
      }).then((data) => data.json());
    },
    async getPriceList(): Promise<IProductInfo[]> {
      const headers = {
        // 'Authorization': `Bearer ${st.moleculerToken}`,
        'Content-Type': 'application/json',
      };
      const data = await fetch(`${MOLECULER_API}/products/`, {
        method: 'GET',
        headers,
      });
      return await data.json();
    },
    getEdrpouInfo(edrpou: string) {
      const headers = {
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/edrpou/?edrpou=${edrpou}`, {
        method: 'GET',
        headers,
      }).then((data) => data.json());
    },
    getLastLicense(edrpou: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/licenses/${edrpou}/last`, {
        method: 'GET',
        headers,
      }).then((data) => data.json());
    },
    getLicenses(edrpou: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      return fetch(`${MOLECULER_API}/private/licenses/${edrpou}`, {
        method: 'GET',
        headers,
      });
    },
    getMessageInfo(messageId: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/emails/status`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          messageId,
        }),
      }).then((data) => data.json());
    },
    getVipDiscount(email: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/vipcabinet/users?email=${email}`, {
        method: 'GET',
        headers,
      })
        .then((data) => data.json())
        .then((discounts) => {
          if (discounts && discounts.length) {
            return discounts[0];
          }
          return {
            hash: null,
            email,
            discountFop: 0,
            discountOrg: 0,
          };
        });
    },
    updateDiscountFop(email: string, discountFop: number) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/vipcabinet/users`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({
          email,
          discountFop,
        }),
      });
    },
    updateDiscountOrg(email: string, discountOrg: number) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/vipcabinet/users`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({
          email,
          discountOrg,
        }),
      });
    },
    sendLink(email: string) {
      const token = API2.auth.get_token();
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return fetch(`${MOLECULER_API}/private/vipcabinet/sendlink`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          email,
        }),
      });
    },
  },
};
