import { ChevronDoubleRightIcon } from '@heroicons/react/outline';
import { PhoneIncomingIcon, PhoneOutgoingIcon } from '@heroicons/react/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  CallEvent as CallEventType,
  Disposition as DispositionType,
} from '../../types/CallEvent';
import {
  classNames,
  formatDate,
  getCallColor,
  getDispositionText,
  phoneWithoutPrefix,
} from '../../utils';
import CopyPhoneButton from '../CallEvent/CopyPhoneButton';
import ExtNumber from '../CallEvent/ExtNumber';
import RecallButton from '../CallEvent/RecallButton';
import Partner from './Partner';

type CallEventProps = {
  data: CallEventType;
};

const CallEvent = (props: CallEventProps): JSX.Element => {
  const { data: event } = props;
  let created = formatDate(event.created, 'DD.MM.YY HH:mm');
  const eventCreated = new Date(event.created);
  const today = new Date();
  if (
    today.getFullYear() === eventCreated.getFullYear() &&
    today.getMonth() === eventCreated.getMonth() &&
    today.getDate() === eventCreated.getDate()
  ) {
    created = formatDate(event.created, 'HH:mm');
  }
  let partners: JSX.Element[] | JSX.Element = <></>;
  if (event.partners) {
    partners = event.partners
      .sort((a, b) => {
        if (a.lastLicense && b.lastLicense) {
          return a.lastLicense.end > b.lastLicense.end ? -1 : 1;
        }
        if (a.lastLicense) {
          return -1;
        }
        if (b.lastLicense) {
          return 1;
        }
        return 1;
      })
      .map((partner) => {
        return (
          <div key={partner.edrpou} className='pb-1'>
            <Partner partner={partner} showEdrpou={true} />
          </div>
        );
      });
  }
  const callColor = getCallColor({
    disposition: event.disposition,
    extNumber: event.extNumber,
    incoming: event.callType === '0',
  });
  return (
    <div
      className={classNames(
        'mt-1 flex flex-wrap rounded-md text-sm shadow',
        callColor
      )}
    >
      <div className='w-1/5 p-1 text-center lg:w-1/6 xl:w-1/12 '>
        <div className='text-sm'>{created}</div>
        <div className='text-xs text-gray-700'>
          {event.billsec < 60
            ? `${event.billsec} сек.`
            : `${Math.round(event.billsec / 60)} хв.`}
        </div>
        <ExtNumber
          number={
            event.transFromNumber ? event.transFromNumber : event.extNumber
          }
          transNumber={event.transExtNumber}
        />
        <div className='flex justify-center'>
          {event.callType === '0' ? (
            <PhoneIncomingIcon className='h-5 w-5 text-green-600' />
          ) : (
            <PhoneOutgoingIcon className='h-5 w-5 text-red-500' />
          )}
        </div>
      </div>
      <div className='w-4/5 bg-white lg:w-5/6 xl:w-11/12'>
        <div className='w-full'>
          <CallInfo event={event} />
        </div>
        <div className='w-full'>
          {event.partners.length > 0 ? (
            <div>{partners}</div>
          ) : (
            <div className='pl-2 text-gray-500'>Кто это? 🤭</div>
          )}
        </div>
      </div>
    </div>
  );
};

const CallInfo = ({ event }: { event: CallEventType }): JSX.Element => {
  const incoming = event.callType === '0';
  const color = getCallColor({
    disposition: event.disposition,
    extNumber: event.extNumber,
    incoming,
  });
  return (
    <CallInfoContainer color={color}>
      {incoming ? (
        <>
          <Link to={`/incoming/${phoneWithoutPrefix(event.from)}`}>
            {phoneWithoutPrefix(event.from)}
          </Link>
          <CopyPhoneButton phone={incoming ? event.from : event.to} />
          <ChevronDoubleRightIcon className='mx-1 h-3 w-3' />
          {event.to}
        </>
      ) : (
        <>
          {phoneWithoutPrefix(event.didNumber)}
          <ChevronDoubleRightIcon className='mx-1 h-3 w-3' />
          <Link to={`/incoming/${phoneWithoutPrefix(event.to)}`}>
            {phoneWithoutPrefix(event.to)}
          </Link>
        </>
      )}
      <span
        className={classNames(
          'mx-2',
          event.waitsec > 20 ? 'text-red-600' : 'text-gray-500'
        )}
        title='Час очікування клієнта'
      >
        {event.waitsec} сек.
      </span>
      <RecallButton phone={incoming ? event.from : event.to} />
      <Disposition disposition={event.disposition} />
    </CallInfoContainer>
  );
};

type CallInfoContainerProps = {
  children: React.ReactNode;
  color: string;
};
const CallInfoContainer = ({
  children,
  color,
}: CallInfoContainerProps): JSX.Element => (
  <div
    className={classNames(
      'text-normal -border-b -border-gray-400 mb-1 flex flex-wrap items-center px-1 pr-3',
      color
    )}
  >
    {children}
  </div>
);

const Disposition = ({
  disposition,
}: {
  disposition: DispositionType;
}): JSX.Element => (
  <span className='pl-1 text-xs text-gray-500'>
    {getDispositionText(disposition)}
  </span>
);

export default CallEvent;
