import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { API2 } from '../api/api';
import DfsExchange from '../common/DfsExchange';
import Messages from '../common/messages';
import PartnerEditForm from '../forms/partnerEditForm';
import { useDiscount } from '../hooks/useDiscount';
import { usePartnerPosts } from '../hooks/useOldPartnerPosts';
import { usePartner } from '../hooks/usePartner';
import { usePartnerInvoices } from '../hooks/usePartnerInvoices';
import { usePhones } from '../hooks/usePhones';
import Spinner from '../RestView/Spinner';
import { formatDate } from '../utils';
import ClipboardButton from './Buttons/ClipboardButton';
import Card from './Card';
import DataLine from './DataLine';
import EdrpouInfo from './EdrpouInfo';
import Gerb from './Gerb';
import ActionsBar from './partnerDetails/ActionsBar';
import Discount from './partnerDetails/Discount';
import LinkedPartners from './partnerDetails/LinkedPartners';
import ManagerName from './partnerDetails/ManagerName';
import PayReminder from './partnerDetails/PayReminder';
import PhonesList from './partnerDetails/PhonesList';
import PartnerInvoices from './PartnerInvoices';
import PartnerLicenses from './PartnerLicenses';
import PartnerNotes from './PartnerNotes';
import PartnerPosts from './PartnerPosts';
import SonataInfo from './SonataInfo';

interface MatchParams {
  partnerId: string;
}
type Props = RouteComponentProps<MatchParams>;

const PartnerDetails = ({ match }: Props): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [showReferer, setShowReferer] = useState(false);
  const { partnerId } = match.params;

  const {
    phones,
    isLoading: isLoadingPhones,
    mutate: mutatePhones,
  } = usePhones(partnerId);
  const {
    posts,
    isLoading: isLoadingPosts,
    mutate: mutatePosts,
  } = usePartnerPosts(parseInt(partnerId));
  // } = usePartnerDocuments(parseInt(partnerId));
  // const {
  //   posts,
  //   isLoading: isLoadingPosts,
  //   mutate: mutatePosts,
  // } = usePartnerPosts(partnerId);
  const {
    invoices,
    isLoading: isInvoicesLoading,
    mutate: mutateInvoices,
  } = usePartnerInvoices(partnerId);
  const {
    partner,
    isLoading: isLoadingPartner,
    mutate: mutatePartner,
  } = usePartner(partnerId);

  const {
    discounts,
    isLoading: isLoadingDiscounts,
    mutate: mutateDiscounts,
  } = useDiscount(partner?.email);

  const handleUpdate = () => {
    // loadDataFromServer();
    mutatePartner();
    mutateInvoices();
    mutatePosts();
    mutatePhones();
  };

  const handleUpdateDiscount = ({
    discountFop,
    discountOrg,
  }: {
    discountFop?: number;
    discountOrg?: number;
  }) => {
    if (!partner) return;
    if (discountFop != undefined) {
      API2.moleculer
        .updateDiscountFop(partner.email, discountFop)
        .then(() => mutateDiscounts && mutateDiscounts());
    }
    if (discountOrg != undefined) {
      API2.moleculer
        .updateDiscountOrg(partner.email, discountOrg)
        .then(() => mutateDiscounts && mutateDiscounts());
    }
  };

  const handleSendDiscountLink = () => {
    if (!partner) return;
    API2.moleculer
      .sendLink(partner.email)
      .then(() =>
        Messages.info('Надіслано посилання на кабінет', `на ${partner.email}`)
      );
  };

  const handleSubmit = (values: any) => {
    return new Promise((resolve, reject) => {
      API2.partners
        .changePartner(partnerId, values)
        .then((data) => {
          setIsEditing(false);
          mutatePartner();
          resolve(data);
        })
        .catch((data) => {
          console.error('***ERROR***', data);
          reject(new Error(data.responseJSON));
        });
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  if (isLoadingPartner) {
    return (
      <div className='flex justify-center py-10'>
        <Spinner />
      </div>
    );
  }

  if (isEditing) {
    return (
      <PartnerEditForm
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialValues={partner} //TODO Проверить
      />
    );
  }
  return (
    <div className='flex flex-wrap space-y-2 text-sm md:space-x-2 md:space-y-0 md:p-2'>
      <Helmet title={partner.name} />
      <div className='w-full md:w-1/2 md:space-y-2'>
        <Card>
          <div className='float-right text-xs text-gray-600'>
            {partner.manager && (
              <span className='flex items-center'>
                <ManagerName
                  name={partner.manager.full_name}
                  title={`Дата прив'язки до менеджера: ${formatDate(
                    partner.assigned_date,
                    'DD.MM.YY HH:mm'
                  )}`}
                />
              </span>
            )}
          </div>
          <h1 className='text-lg font-bold'>
            {partner.is_gov && (
              <div className='mr-1 inline-flex h-5 w-5'>
                <Gerb />
              </div>
            )}
            {partner.name}
          </h1>
          <div className='flex items-center text-xl text-indigo-700'>
            {partner.edrpou}
            <ClipboardButton className='ml-1' clipboardText={partner.edrpou} />
            <span className='ml-1 text-gray-500'>
              Створений: {formatDate(partner.created, 'DD.MM.YY')}
            </span>
          </div>
          {partner.referer && (
            <div className='break-words text-blue-700'>
              <a
                className='cursor-pointer'
                onClick={() => setShowReferer(true)}
              >
                Джерело
              </a>
              {showReferer && <div> {partner.referer}</div>}
            </div>
          )}
          <ActionsBar
            partnerId={partnerId}
            partner={partner}
            isFop={partner.is_fop}
            onUpdateAction={handleUpdate.bind(this)}
            onEditMode={setIsEditing}
          />
        </Card>
        <Card>
          <div className='flex flex-wrap divide-gray-200 sm:space-x-2 sm:divide-x'>
            <div className='w-full sm:w-1/2'>
              <>
                {partner.email ? (
                  <>
                    {partner.primary_organisation ? (
                      <div className='py-1'>
                        Організація для рахунків: <br />
                        <span className='font-bold text-pink-900'>
                          {partner.primary_organisation.name}
                        </span>
                      </div>
                    ) : null}
                    <div className='flex items-center py-1 text-lg text-blue-600'>
                      {partner.email}
                      <ClipboardButton clipboardText={partner.email} />
                    </div>
                  </>
                ) : null}
                {isLoadingDiscounts ? (
                  <div className='flex justify-center py-2'>
                    <Spinner />
                  </div>
                ) : (
                  <Discount
                    hash={discounts?.hash || ''}
                    discountOrg={discounts?.discountOrg || 0}
                    discountFop={discounts?.discountFop || 0}
                    onChangeDiscountOrg={(discountOrg) =>
                      handleUpdateDiscount({ discountOrg })
                    }
                    onChangeDiscountFop={(discountFop) =>
                      handleUpdateDiscount({ discountFop })
                    }
                    onSendDiscountLink={handleSendDiscountLink}
                  />
                )}
                {partner.email_edo ? (
                  <DataLine name='Email для ЕДО' data={partner.email_edo} />
                ) : null}
                <DataLine name='Адреса (юридична)' data={partner.address} />
                <DataLine name='Адреса (поштова)' data={partner.post_address} />
                <PayReminder enable={partner.send_pay_reminders} />

                {partner.comment ? (
                  <DataLine name='Коментар' data={partner.comment} />
                ) : null}
                <PhonesList partnerId={partnerId} />
                <h2 className='mt-2 font-bold uppercase text-gray-800'>
                  Ліцензії:
                </h2>
                <PartnerLicenses edrpou={partner.edrpou} />
              </>
            </div>
            <div className='flex-1 space-y-2 sm:pl-3'>
              <SonataInfo edrpou={partner.edrpou} />
              <EdrpouInfo edrpou={partner.edrpou} />
              <DfsExchange edrpou={partner.edrpou} />
            </div>
          </div>
        </Card>
        <div className='flex flex-wrap md:space-x-2'>
          <div className='w-full md:w-1/2'>
            <legend className='pt-3'>Рахунки:</legend>
            {isInvoicesLoading ? (
              <div className='flex justify-center py-5'>
                <Spinner />
              </div>
            ) : (
              <PartnerInvoices invoices={invoices} />
            )}
          </div>
          <div className='flex-1'>
            <legend className='mr-2 pt-3'>Надсилання документів:</legend>
            {isLoadingPosts ? (
              <Spinner />
            ) : posts ? (
              <PartnerPosts posts={posts} onUpdate={() => mutatePosts()} />
            ) : null}
            <legend className='pt-3'>Пов&apos;язані:</legend>
            {isLoadingPhones ? (
              <div className='flex justify-center py-5'>
                <Spinner />
              </div>
            ) : (
              <LinkedPartners
                edrpou={partner.edrpou}
                phones={phones}
                // emails={[partner.email]}
              />
            )}
          </div>
        </div>
      </div>
      <div className='w-auto flex-1'>
        <PartnerNotes partnerId={parseInt(partnerId, 10)} />
      </div>
    </div>
  );
};

export default PartnerDetails;
