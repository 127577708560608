import useSWR from 'swr';
import { IOldPost } from '../api/api';
import { OldRestResult } from '../providers/types';
import { oldFetcher } from './utils';

const OLD_API = process.env.REACT_APP_OLD_API;

export const usePosts = () => {
  const { data, error, mutate } = useSWR<OldRestResult<IOldPost>>(
    `${OLD_API}/api/posts/`,
    oldFetcher
  );
  return {
    posts: data?.results,
    lastPage: data?.last_page,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
