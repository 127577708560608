import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { API2 } from '../api/api';
import { usePartnersByEdrpou } from '../hooks/usePartnersByEdrpou';
import { usePartnersByPhone } from '../hooks/usePartnersByPhone';
import Spinner from '../RestView/Spinner';
import { isFop, isOrganisation } from '../utils';
import Card from './Card';
import EdrpouInfo from './EdrpouInfo';
import CreateForm from './IncomingCall/CreateForm';
import PartnersList from './IncomingCall/PartnersList';
import ResultList from './IncomingCall/ResultList';
import SearchForm from './IncomingCall/SearchForm';
import SonataInfo from './SonataInfo';

interface MatchParams {
  phoneNumber: string;
}
type Props = RouteComponentProps<MatchParams>;

const IncomingCall = ({ match }: Props): JSX.Element => {
  const [note, setNote] = useState('');
  const [edrpou, setEdrpou] = useState('');
  const history = useHistory();
  const { phoneNumber } = match.params;

  const {
    partners: results,
    isLoading: isLoadingResults,
    mutate: mutateResults,
  } = usePartnersByEdrpou(edrpou);

  const {
    partners,
    isLoading: isLoadingPartners,
    mutate: mutatePartners,
  } = usePartnersByPhone(phoneNumber);

  const handleAppendPhone = (partnerId: number) => {
    API2.moleculer
      .appendPhone(partnerId, phoneNumber)
      .then(() => mutatePartners());
  };

  const handleCreatePartner = () => {
    const postData = {
      phone: phoneNumber,
      edrpou,
      name: '',
      note,
    };
    API2.moleculer.createPartner(postData).then((data) => {
      history.push(`/partners/${data.id}`);
    });
  };
  return (
    <div className='flex flex-wrap md:space-x-2 md:p-2'>
      <div className='w-full md:w-1/2'>
        <Card>
          <legend>
            Дзвінок з номера{' '}
            <span className='font-bold text-gray-700'>{phoneNumber}</span>
          </legend>
          <SearchForm
            onSearch={(filter) => {
              setEdrpou(filter);
              if (filter.length > 6) {
                mutateResults();
              }
            }}
            onChangeNote={setNote}
          />
          <CreateForm
            onCreate={handleCreatePartner}
            // edrpou={edrpou}
            // phone={phoneNumber}
          />
        </Card>
      </div>
      <div className='w-full space-y-2 md:flex-1'>
        {edrpou.length > 6 && (
          <Card>
            {isLoadingResults || !results ? (
              <Spinner />
            ) : (
              <ResultList results={results} onAppend={handleAppendPhone} />
            )}
          </Card>
        )}
        <Card>
          <legend>Закріплені за цим номером:</legend>
          {isLoadingPartners && !partners ? (
            <Spinner />
          ) : (
            <PartnersList partners={partners} />
          )}
        </Card>
        {(isFop(edrpou) || isOrganisation(edrpou)) && (
          <Card>
            <SonataInfo edrpou={edrpou} />
          </Card>
        )}
        {(isFop(edrpou) || isOrganisation(edrpou)) && (
          <Card>
            <EdrpouInfo edrpou={edrpou} />
          </Card>
        )}
      </div>
    </div>
  );
};

export default IncomingCall;
