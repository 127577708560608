import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

type Props = {
  partners?: {
    partnerId: number;
    edrpou: string;
    name: string;
  }[];
};

const PartnersList = ({ partners }: Props): JSX.Element => {
  if (!partners || partners.length === 0) {
    return <p className='text-gray-500'>нет закрепленных</p>;
  }
  const partnersList = partners.map((partner) => {
    return (
      <div key={partner.partnerId}>
        <div>
          <span className='font-bold text-gray-700'>{partner.edrpou}</span>{' '}
          <Link to={`/partners/${partner.partnerId}`}>{partner.name}</Link>
        </div>
      </div>
    );
  });
  return <div>{partnersList}</div>;
};
PartnersList.propTypes = {
  partners: PropTypes.array.isRequired,
};

export default PartnersList;
