import { ErrorMessage, Field as _Field } from 'formik';
import React from 'react';
import Label from './Label';

type FieldProps = {
  name: string;
  label?: string;
  type: string;
  children?: React.ReactNode;
  onBlur?: () => void;
};

const Field = (props: FieldProps) => {
  const { label, name, children, type } = props;
  const inputProps = {
    ...props,
    type,
    children: null,
    className: 'w-full',
  };
  return (
    <div className='mt-3'>
      <Label name={name} label={label} />
      <div className=''>
        <_Field {...inputProps}></_Field>
        <div className='text-red-600'>
          <ErrorMessage name={name} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Field;
