import React from 'react';
import get from 'lodash/get';
import VchasnoLogo from '../../VchasnoLogo';
import UkrposhtaLogo from '../../UkrposhtaLogo';
import NovaPoshtaLogo from '../../NovaPoshtaLogo';
import { FieldProps } from '../../../RestView/types';

const ProviderField: React.FC<FieldProps> = ({ source, resource }) => {
  const value = get(resource, source);
  switch (value) {
    case 'vchasno':
      return (
        <div className='w-6 h-6'>
          <VchasnoLogo />
        </div>
      );
    case 'ukrposhta':
      return (
        <div className='w-6 h-6'>
          <UkrposhtaLogo />
        </div>
      );
    case 'novaposhta':
      return (
        <div className='w-6 h-6'>
          <NovaPoshtaLogo />
        </div>
      );
  }
  return null;
};

export default ProviderField;
