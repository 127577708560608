import React from 'react';
import { PostDocument } from '../types/PostDocument';
import NovaPoshtaLogo from './NovaPoshtaLogo';
import UkrposhtaLogo from './UkrposhtaLogo';
import VchasnoLogo from './VchasnoLogo';

const ProviderLogo = ({
  provider,
}: {
  provider: PostDocument['provider'];
}): JSX.Element => {
  switch (provider) {
    case 'vchasno':
      return <VchasnoLogo className='w-5 h-5' />;

    case 'ukrposhta':
      return <UkrposhtaLogo className='w-5 h-5' />;

    case 'novaposhta':
      return <NovaPoshtaLogo className='w-5 h-5' />;
  }
};

export default ProviderLogo;
