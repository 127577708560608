export type Provider = {
  id: string;
  name: string;
};

export const postProviders: Provider[] = [
  {
    id: 'ukrposhta',
    name: 'Укрпочта',
  },
  {
    id: 'vchasno',
    name: 'Вчасно',
  },
  {
    id: 'novaposhta',
    name: 'Новая почта',
  },
];
