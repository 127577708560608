import React from 'react';

interface DataColumnProps {
  title?: string;
}

const DataColumn: React.FC<DataColumnProps> = ({ children, ...props }) => (
  <div>
    {Array.isArray(children)
      ? children.map((el, idx) =>
        React.cloneElement(el as React.ReactElement<any>, { ...props, key: idx })
      )
      : React.cloneElement(children as React.ReactElement<any>, { ...props })}
  </div>
);

export default DataColumn;
