import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { API2 } from '../api/api';

const CustomNavLink = ({
  children,
  to,
  activeClassName,
  ...props
}: {
  children: React.ReactNode;
  to: string;
  activeClassName: string;
}) => (
  <li>
    <NavLink
      to={to}
      activeClassName={activeClassName}
      className='mr-1 block border-b border-gray-900 px-2 py-2 uppercase hover:border-yellow-600 hover:text-yellow-600 md:inline-block md:border-b-2'
      {...props}
    >
      {children}
    </NavLink>
  </li>
);

const Header = ({ onLogout }: { onLogout: () => void }) => {
  const history = useHistory();
  const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    API2.auth.logout().then(() => {
      onLogout();
      history.push('/login/');
    });
  };
  return (
    <header id='header'>
      <div className='float-right'>
        <div className='btn-header transparent float-right'>
          <span>
            <a href='' onClick={handleLogout} title='Вихід'>
              <i className='fa fa-sign-out'> </i>
            </a>
          </span>
        </div>
      </div>
    </header>
  );
};

const Nav = ({
  mailsCount,
  paymentsCount,
}: {
  mailsCount: number;
  paymentsCount: number;
}) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div>
      <aside id='left-panel'>
        <nav className='fixed right-0 left-0 top-0 z-50 flex h-auto items-center bg-gray-900 px-1 py-1 shadow-lg print:hidden md:h-10'>
          <div className='container mx-auto'>
            <div className='flex flex-wrap items-center justify-end md:justify-start'>
              <div className='flex items-center md:hidden'>
                {paymentsCount ? (
                  <div
                    className='mr-3 flex h-6 w-6 items-center justify-center rounded-full bg-red-600 text-sm text-white'
                    id='user-task-queue'
                  >
                    <div>{paymentsCount}</div>
                  </div>
                ) : null}
                <button
                  aria-label='згорнути меню'
                  className='flex items-center rounded border border-blue-800 px-3 py-2 text-blue-700 hover:border-blue-600 hover:text-blue-500 focus:outline-none'
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <svg
                    className='h-3 w-3 fill-current'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    {/* <title>Меню</title> */}
                    <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
                  </svg>
                </button>
              </div>
              <div
                className={
                  'mt-4 block w-full md:mt-0 md:flex md:w-auto md:flex-grow md:items-center' +
                  (collapsed ? ' hidden' : '')
                }
              >
                <div className='-md:pt-2 pt-0 text-sm md:flex-grow '>
                  <ul className='text-white md:float-left md:flex'>
                    <CustomNavLink activeClassName='font-bold' to='/'>
                      <span className='menu-item-parent'>Панель</span>
                    </CustomNavLink>
                    <CustomNavLink activeClassName='font-bold' to='/invoices'>
                      <span className='menu-item-parent'>Рахунки</span>
                    </CustomNavLink>
                    <CustomNavLink activeClassName='font-bold' to='/partners'>
                      <span className='menu-item-parent'>Клієнти</span>
                    </CustomNavLink>
                    <CustomNavLink activeClassName='font-bold' to='/mail'>
                      <span className='menu-item-parent'>Документи</span>
                      <span
                        className='m-1 rounded-full bg-blue-600 px-2 text-white'
                        id='user-task-queue'
                      >
                        {mailsCount}
                      </span>
                    </CustomNavLink>
                    <CustomNavLink activeClassName='font-bold' to='/payments'>
                      <span className='menu-item-parent'>Банк</span>
                      {paymentsCount ? (
                        <span
                          className='m-1 rounded-full bg-blue-600 px-2 text-white'
                          id='user-task-queue'
                        >
                          {paymentsCount}
                        </span>
                      ) : null}
                    </CustomNavLink>
                    <CustomNavLink activeClassName='font-bold' to='/queue'>
                      <span className='menu-item-parent'>Черга</span>
                    </CustomNavLink>
                    <CustomNavLink
                      activeClassName='font-bold'
                      to='/invoices/new'
                    >
                      <span className='menu-item-parent'>+ Рахунок</span>
                    </CustomNavLink>
                    {/* <CustomNavLink activeClassName='font-bold' to='/invoices/token'>
                      <span className='menu-item-parent'>Токен</span>
                    </CustomNavLink> */}
                    {/* <NavLink activeClassName='active' to='/settings'>
                    <span className='menu-item-parent'>Настройки</span>
                  </NavLink> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </aside>
    </div>
  );
};

export default Nav;
