export const SELECT_LIST = 'SELECT_LIST';
export const GET_LISTS_START = 'GET_LISTS_START';
export const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
export const LOAD_LIST_MEMBERS_SUCCESS = 'LOAD_LIST_MEMBERS_SUCCESS';
export const LOAD_LIST_MEMBERS = 'LOAD_LIST_MEMBERS';
export const REMOVE_PARTNER_FROM_LIST_START = 'REMOVE_PARTNER_FROM_LIST_START';
export const REMOVE_PARTNER_FROM_LIST_SUCCESS =
  'REMOVE_PARTNER_FROM_LIST_SUCCESS';
export const CLEAR_LIST_MEMBERS = 'CLEAR_LIST_MEMBERS';

export const CALL_INCOMING = 'CALL_INCOMING';
export const CALL_ANSWER = 'CALL_ANSWER';
export const CALL_HANGUP = 'CALL_HANGUP';

export const NOTIFY_CALL = 'NOTIFY_CALL';

export const GET_PARTNER_START = 'GET_PARTNER_START';
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
export const GET_PARTNER_INVOICES = 'GET_PARTNER_INVOICES';
export const GET_PARTNER_INVOICES_SUCCESS = 'GET_PARTNER_INVOICES_SUCCESS';
export const GET_PARTNER_LICENSES_START = 'GET_PARTNER_LICENSES_START';
export const GET_PARTNER_LICENSES_SUCCESS = 'GET_PARTNER_LICENSES_SUCCESS';
export const GET_PARTNER_POSTS_START = 'GET_PARTNER_POSTS_START';
export const GET_PARTNER_POSTS_SUCCESS = 'GET_PARTNER_POSTS_SUCCESS';

// export const PHONE_IDLE = "online";
export const PHONE_RINGING = 'ringing';
export const PHONE_INUSE = 'inuse';
export const PHONE_BUSY = 'busy';
export const PHONE_UNAVAILABLE = 'offline';
export const GET_PHONES_STATE_START = 'GET_PHONES_STATE_START';
export const GET_PHONES_STATE_SUCCESS = 'GET_PHONES_STATE_SUCCESS';
export const SET_PHONES_STATE = 'SET_PHONES_STATE';
export const PHONES_PANEL_STATE_CHANGED = 'PHONES_PANEL_STATE_CHANGED';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const CHANGE_PRESENCE_STATE = 'CHANGE_PRESENCE_STATE';

export const STATS_UPDATE = 'STATS_UPDATE';
export const STATS_UPDATE_SUCCESS = 'STATS_UPDATE_SUCCESS';
export const REQUEST_SMS_BALANCE = 'REQUEST_SMS_BALANCE';
export const RECEIVE_SMS_BALANCE = 'RECEIVE_SMS_BALANCE';

export const GET_SONATA_INFO_START = 'GET_SONATA_INFO_START';
export const GET_SONATA_INFO_SUCCESS = 'GET_SONATA_INFO_SUCCESS';
export const GET_SONATA_INFO_FAILED = 'GET_SONATA_INFO_FAILED';

export const FETCH_INVOICES_START = 'FETCH_INVOICES_START';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILED = 'FETCH_INVOICES_FAILED';
export const GET_INVOICE_START = 'GET_INVOICE_START';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const SET_DOCUMENTS_ON_PAYER_SUCCESS = 'SET_DOCUMENTS_ON_PAYER_SUCCESS';
export const CHANGE_INVOICE_TO_GENERAL_EMAiL =
  'CHANGE_INVOICE_TO_GENERAL_EMAiL';
export const INVOICES_CHANGE_PAGE = 'INVOICES_CHANGE_PAGE';

export const PARTNERS_CHANGE_PAGE = 'PARTNERS_CHANGE_PAGE';
export const FETCH_PARTNERS_START = 'FETCH_PARTNERS_START';
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS';

export const FETCH_TASKS_START = 'FETCH_TASKS_START';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const SET_TASK_SUCCESS = 'SET_TASK_SUCCESS';

export const FETCH_LICENSE_START = 'FETCH_LICENSE_START';
export const FETCH_LICENSE_SUCCESS = 'FETCH_LICENSE_SUCCESS';
export const FETCH_LICENSE_FAILED = 'FETCH_LICENSE_FAILED';

export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_RESULTS_START = 'GET_RESULTS_START';
export const GET_RESULTS_SUCCESS = 'GET_RESULTS_SUCCESS';
export const GET_PARTNERS_START = 'GET_PARTNERS_START';

export const FETCH_EDRPOU_SUCCESS = 'FETCH_EDRPOU_SUCCESS';
export const FETCH_EDRPOU_START = 'FETCH_EDRPOU_START';
export const FETCH_EDRPOU_FAILED = 'FETCH_EDRPOU_FAILED';
