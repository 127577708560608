import React from 'react';
import { ReactComponent as Icon } from '../assets/svg/tryzub.svg';
import { classNames } from '../utils';

const Gerb = ({ className = '' }: { className?: string }): JSX.Element => (
  <div className={classNames('flex justify-center text-center', className)}>
    <Icon className='rounded-full border border-white bg-blue-400 p-0.5' />
  </div>
);

export default Gerb;
