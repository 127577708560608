import React from 'react';
import { formatDate } from '../../utils';

const InvoiceTitle = ({
  number,
  date,
  total,
  hash,
}: {
  number: string;
  date: string;
  total: number;
  hash?: string;
}): JSX.Element => (
  <>
    <h1 className='text-lg'>
      Рахунок № <strong>{number}</strong> от{' '}
      {formatDate(date, 'DD.MM.YY HH:mm')}
      &nbsp;на&nbsp;
      <span className='font-bold'>{total}</span> грн.
    </h1>
    {hash && <div className='text-xs text-gray-600'>{hash}</div>}
  </>
);

export default InvoiceTitle;
