import React from 'react';
import { classNames } from '../utils';

const VchasnoLogo = (props: { className?: string }): JSX.Element => (
  <div
    className={classNames(
      // 'rounded-full bg-yellow-400',
      props.className ? props.className : ''
    )}
  >
    <svg width='100%' height='100%' viewBox='0 0 512 512'>
      <circle cx={256} cy={256} r={255} fill='gold' />
      <path
        transform='scale(0.6, -0.6) translate(170, -682)'
        d='M257 6c-140 0-255 112-257 253 0 137 110 250 247 253l0-73c-88 0-174-81-174-180 1-100 83-180 182-180 100 0 181 80 182 180 1 30-7 60-22 86-48-51-122-133-122-134l-25-27-107 95 48 55 54-49c37 40 104 113 132 142l28 28 26-29c41-47 64-106 64-169-3-139-116-250-256-251z'
      />
    </svg>
  </div>
);

export default VchasnoLogo;
