import useSWR from 'swr';

const MOLECULER = process.env.REACT_APP_MOLECULER;

type Props = {
  short_name: string;
  name: string;
  kved: string;
  address: string;
  boss: string;
  pdvStatus: boolean;
  vatCode?: string;
  stan: string;
  founders: string[];
  beneficiary: string;
  sti?: string;
};

export const useEdrpouInfo = (edrpou: string) => {
  const { data, error } = useSWR<Props, any>(
    `${MOLECULER}/edrpou/?edrpou=${edrpou}`,
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  );
  return {
    info: data,
    isLoading: !error && !data,
    isError: error,
  };
};
