import { ExclamationIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLastEvents } from '../hooks/useLastEvents';
import { useLastNotes } from '../hooks/useLastNotes';
import Spinner from '../RestView/Spinner';
import AllNotesPanel from './AllNotes';
import Card from './Card';
import EventList from './EventsList';
import SocketIO from './general/SocketIO';
import InfoPanel from './InfoPanel';

const HomeView = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<0 | 1>(0);

  const {
    events,
    isLoading: isLoadingEvents,
    isError: isErrorEvents,
    mutate: mutateEvents,
  } = useLastEvents(currentTab);

  const {
    notes,
    isLoading: isLoadingNotes,
    isError: isErrorNotes,
    mutate: mutateNotes,
  } = useLastNotes();

  const handleCallUpdate = (data: { event: string }) => {
    const { event } = data;
    if (event == 'callcenter.push') {
      mutateEvents();
    }
  };

  return (
    <div className='flex flex-wrap-reverse md:space-x-2 md:p-2'>
      <Helmet title='Головна' />
      <SocketIO channel='callcenter' onUpdate={handleCallUpdate} />
      <SocketIO channel='notes' onUpdate={() => mutateNotes()} />
      <div className='w-full space-y-2 md:w-2/5'>
        <Card>
          <InfoPanel />
        </Card>
        <div>
          <h3 className='pl-2 uppercase text-gray-700'>Останні нотатки:</h3>
          {isErrorEvents && (
            <div className='flex p-2 text-red-600'>
              <ExclamationIcon className='h-5 w-5' /> помилка при завантаженні
              нотаток
            </div>
          )}
          {isLoadingNotes ? <Spinner /> : <AllNotesPanel notes={notes?.rows} />}
        </div>
      </div>
      <div className='w-full md:flex-1'>
        {isErrorEvents && (
          <div className='flex p-2 text-red-600'>
            <ExclamationIcon className='h-5 w-5' /> помилка при завантаженні
            дзвонків
          </div>
        )}
        {!isLoadingEvents && events && (
          <EventList
            tab={currentTab}
            events={events}
            onChangeTab={(tab) => {
              // setEvents([]);
              setCurrentTab(tab);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default HomeView;
