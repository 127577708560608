import Noty from 'noty';
import 'noty/lib/noty.css';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// toast.configure()

Noty.overrideDefaults({
  theme: 'metroui',
  // visibilityControl: true,
});

class Messages {
  static info(title: string, content: string) {
    const text = `<div><i class="fa fa-2x fa-info-circle pull-right"></i> ${title}<p>${content}</p></div>`;
    const n = new Noty({
      text: text,
      type: 'info',
      timeout: 5000,
    });
    n.show();
  }

  static error(content: string | undefined) {
    if (!content) return;
    const text = `<div><i class="fa fa-2x fa-exclamation-triangle pull-right"></i><p>${content}</p></div>`;
    new Noty({
      text: text,
      type: 'error',
    }).show();
  }

  static showUserMessage(
    data: { body: string },
    timeout = 20000,
    type: Noty.Type = 'info'
  ) {
    const text = `<div><i class="fa fa-2x fa-comments-o bounce animated pull-right"></i>${data.body}</div>`;
    const n = new Noty({
      text: text,
      type: type,
      timeout: timeout,
    });
    n.show();
    return n;
  }

  static showAllUsersMessage(data: { body: string }) {
    const text = `<div><i class="fa fa-2x fa-comments-o bounce animated pull-right"></i>${data.body}</div>`;
    const n = new Noty({
      text: text,
      type: 'info',
    });
    n.show();
  }

  static showAlertMessage(data: { body: string }) {
    new Noty({
      text: data.body,
    }).show();
  }
}

export default Messages;
