import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { validateIpn } from 'edrpou-ua';
import { classNames } from '../../utils';

type SearchFormProps = {
  onChangeNote: (value: string) => void;
  onSearch: (value: string) => void;
};

const SearchForm = ({
  onChangeNote,
  onSearch,
}: SearchFormProps): JSX.Element => {
  const [validated, setValidated] = useState(false);
  const [valid, setValid] = useState(true);

  const handleSearch = debounce((event) => {
    onSearch(event.target.value);
  }, 500);

  const _onSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    if (value.length === 10) {
      const valid = validateIpn(value);
      setValidated(true);
      setValid(valid);
    } else {
      setValidated(false);
      setValid(false);
    }
    e.persist();
    handleSearch(e);
  };

  return (
    <form className='space-y-2'>
      <div
        className={classNames(
          'flex flex-wrap',
          validated ? (valid ? 'text-green-600' : 'text-red-500') : ''
        )}
      >
        <input
          type='text'
          placeholder='Введите ЕГРПОУ или ИНН'
          aria-describedby='helpBlock'
          onChange={_onSearch}
        />
        {validated ? (
          <div className='text-gray-500 text-xs'>
            {valid ? 'ИНН верный' : 'Ошибка в коде ИНН'}
          </div>
        ) : null}
      </div>
      <input
        type='text'
        placeholder='Примечание'
        onChange={(e) => onChangeNote(e.target.value)}
      />
    </form>
  );
};
SearchForm.propTypes = {
  onChangeNote: PropTypes.func,
  onSearch: PropTypes.func,
};

export default SearchForm;
