import { AnnotationIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { API2 } from '../api/api';
import Messages from '../common/messages';
import { usePayments } from '../hooks/usePayments';
import { TUtmTags } from '../providers/types';
import { SpinnerWithContainer } from '../RestView/Spinner';
import { IManager, IUTMData, TOldPayment } from '../types/Payments';
import { classNames, formatDate, shortName } from '../utils';
import SocketIO from './general/SocketIO';
import LicenseInfo from './LicenseInfo';
import OrganisationAvatar from './OrganisationAvatar';
import Pager from './Pager';
import ManagerName from './partnerDetails/ManagerName';
import SearchForm from './SearchForm';

const UTMTag = ({ name, value }: { name: TUtmTags; value: string | null }) => {
  if (!value) return null;
  if (name === 'referrer')
    return <div className='break-words'>ref: {value.substring(0, 100)}</div>;
  return (
    <div>
      <span className='text-gray-500'>{name}</span>: {value}
    </div>
  );
};

const UTMDetails = ({ invoice }: { invoice: IUTMData }) => (
  <>
    {(invoice.utm_source ||
      invoice.utm_term ||
      invoice.client_id ||
      invoice.referrer) && (
      <div className='rounded bg-green-300 px-1 py-1 md:px-2'>
        <UTMTag name='utm_source' value={invoice.utm_source} />
        <UTMTag name='utm_term' value={invoice.utm_term} />
        <UTMTag name='client_id' value={invoice.client_id} />
        <UTMTag name='referrer' value={invoice.referrer} />
      </div>
    )}
  </>
);

const PartnerLink = ({
  partnerId,
  partnerName,
}: {
  partnerId: number;
  partnerName: string;
}) => (
  <Link className='text-blue-800' to={{ pathname: `/partners/${partnerId}` }}>
    {shortName(partnerName)}
  </Link>
);

const InvoiceInfo = ({
  onUpdate,
  onClearInvoice,
  invoice,
  partner,
  payment_id: paymentId,
  partner_edrpou,
  state,
}: {
  onUpdate: () => void;
  onClearInvoice: (paymentId: string) => void;
  invoice: TOldPayment['invoice'];
  partner: TOldPayment['partner'];
  payment_id: string;
  partner_edrpou: string;
  state: string;
}) => {
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [_, setPartnerUrl] = useState('');

  const copy = (url: string) => {
    const textField = document.createElement('textarea');
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    Messages.info('Посилання в буфері обміну', url);
  };

  const handlePartnerLink = () => {
    API2.moleculer.getPartnerInfo(partner.edrpou).then((data) => {
      setPartnerUrl(`https://sonata.biz.ua${data.data.url}`);
      copy(`https://sonata.biz.ua${data.data.url}`);
    });
  };

  const handleSetInvoice = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    API2.payments.setInvoice(paymentId, invoiceNumber).then(() => {
      onUpdate();
    });
  };

  const handleClearInvoice = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onClearInvoice(paymentId);
  };

  const handleSetInvoiceFromPayment = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setInvoiceNumber(partner_edrpou);
  };

  if (!invoice) {
    return (
      <div className='flex justify-center py-2'>
        <form>
          <div className='mb-1 inline-block border border-gray-500 shadow'>
            <input
              className='-border border-gray-800 bg-gray-200 p-3'
              onChange={(e) => setInvoiceNumber(e.target.value)}
              value={invoiceNumber}
              type='text'
              placeholder='номер рахунку..'
            />
            <button
              type='submit'
              className='border-l border-r border-gray-500 bg-orange-300 px-8 py-3 font-bold text-orange-900'
              onClick={handleSetInvoice}
            >
              Ок
            </button>
            <button
              type='submit'
              className='bg-orange-300 px-5 py-3 font-bold text-orange-900'
              onClick={handleSetInvoiceFromPayment}
            >
              ЄДРПОУ платника
            </button>
          </div>
        </form>
      </div>
    );
  }
  return (
    <div className='my-1 rounded bg-orange-300 px-2 py-1 shadow-sm md:rounded-none md:bg-transparent md:shadow-none'>
      <div className='flex items-center '>
        <div>
          <button
            className='my-2 h-10 w-10 rounded-md bg-red-400 text-center shadow'
            onClick={handleClearInvoice}
          >
            <i className='fa fa-eraser'> </i>
          </button>
        </div>
        <div className='pl-1'>
          <div className='flex flex-wrap'>
            <Link to={`/invoices/${invoice.id}/`}>
              Рахунок:&nbsp;
              <span
                className={
                  'font-bold ' +
                  (invoice.paid ? 'text-teal-800' : 'text-orange-700')
                }
              >
                {invoice.id}
              </span>
            </Link>
            <span className='ml-1'>
              Сума: {invoice.total}/
              <b title='Сплачено па рахунку'>{invoice.total_payments}</b> &nbsp;
              {invoice.total_payments - invoice.total > 10 && (
                <span className='label label-danger'>!!!</span>
              )}
            </span>
            {invoice.user && <ManagerName name={invoice.user.full_name} />}
          </div>
          <div className='flex w-full flex-wrap'>
            <span className='pr-1 font-bold text-teal-900'>
              {partner.edrpou}
            </span>
            <PartnerLink partnerId={partner.id} partnerName={partner.buyer} />
            <span className='ml-1'>
              <LicenseInfo edrpou={partner.edrpou} />
            </span>
          </div>
          <Manager manager={partner.manager} />
        </div>
      </div>
      {state === 'need_transfer' && (
        <div className='py-1 text-center md:text-left'>
          <StateActions handlePartnerLink={handlePartnerLink} />
        </div>
      )}
    </div>
  );
};

const Payment = ({
  onUpdate,
  reactKey,
  data,
}: {
  onUpdate: () => void;
  reactKey: string;
  data: TOldPayment;
}) => {
  const handleClearInvoice = (paymentId: string) => {
    API2.payments.setInvoice(paymentId, null).then(() => {
      onUpdate();
    });
  };

  const handleUnrecognizedPayment = (paymentId: string) => {
    API2.payments.setAsUnrecognized(paymentId).then(() => {
      onUpdate();
    });
  };

  const handleSuccessClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    API2.payments.setAsCompleted(reactKey).then(() => {
      onUpdate();
    });
  };

  const handlePaid = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    API2.invoices.setInvoicePaid(data.invoice.id, true).then(onUpdate);
  };

  const handleActivationMail = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    API2.moleculer.sendActivationMail(data.invoice.id).then(onUpdate);
  };

  const handleActivateLicense = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    API2.invoices
      .activateLicense(data.invoice.id, data.id)
      .then((data) => {
        if (data && data.description) {
          Messages.showUserMessage(
            {
              body: data.description,
            },
            1000,
            'success'
          );
        }
      })
      .then(onUpdate);
  };

  const handleFixOrganisation = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const invoiceId = data.invoice.id;
    const organisationId = data.organisation.id; // исправить
    API2.invoices.setOrganisation(invoiceId, organisationId).then(() => {
      onUpdate();
    });
  };

  const handleFixEmail = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const { id: partnerId } = data.partner;
    const { email } = data.invoice;
    API2.partners.changePartnerEmail(partnerId, email).then(() => {
      onUpdate();
    });
  };

  const setDocumentsOnPayer = (invoiceId: string, checked: boolean) => {
    API2.invoices.setDocumentsOnPayer(invoiceId, checked).then(() => {
      onUpdate();
    });
  };

  const { details, state, completed, total, invoice, partner } = data;
  let organisation = null;
  if (
    data.organisation &&
    invoice &&
    invoice.organisation &&
    data.organisation.id !== invoice.organisation.id
  ) {
    organisation = (
      <div className='text-sm text-red-600'>
        ?{data.organisation.name}
        &nbsp;
        <a href='' onClick={handleFixOrganisation}>
          Виправити
        </a>
      </div>
    );
  } else if (data.organisation) {
    organisation = <OrganisationAvatar organisation={data.organisation} />;
  }
  return (
    <div className='md:px-2 md:pt-1'>
      <div
        className={classNames(
          'mb-1 shadow md:rounded-md',
          completed ? 'bg-green-300' : 'bg-white'
        )}
      >
        <div className='flex flex-wrap p-2'>
          <div className='w-full md:w-1/3'>
            <div className='float-right md:float-left'>{organisation}</div>
            <div className='w-full text-lg'>
              {formatDate(data.date, 'DD.MM.YY HH:mm')}{' '}
              <span
                className={
                  'ml-1 font-bold ' +
                  (invoice && total !== invoice.total
                    ? 'text-orange-700'
                    : 'text-teal-800')
                }
              >
                {total} грн.
              </span>
              <span className='ml-1 rounded bg-blue-600 px-1 py-1 text-sm text-white shadow'>
                {state}
              </span>
            </div>
            <div className='py-1'>
              <span className='font-bold text-teal-800'>{data.edrpou}</span>{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href={'/admin/documents/paymentorder/' + data.id + '/'}
              >
                {data.name}
              </a>
            </div>
            <div className='text-sm text-gray-600'>{details}</div>
          </div>
          <div className='w-full md:w-1/3 md:pl-2'>
            <InvoiceInfo
              onUpdate={onUpdate}
              onClearInvoice={handleClearInvoice}
              // onSetAsUnrecognized={handleUnrecognizedPayment}
              payment_id={data.id.toString()}
              invoice={invoice}
              partner={partner}
              partner_edrpou={data.edrpou}
              state={data.state}
            />
            {invoice &&
              invoice.paid &&
              data.partner.edrpou !== data.edrpou &&
              data.edrpou != '14360570' && (
                <div>
                  <div className='checkbox'>
                    <label>
                      <input
                        type='checkbox'
                        checked={invoice.documents_on_payer}
                        onChange={() =>
                          setDocumentsOnPayer(
                            invoice.id.toString(),
                            !invoice.documents_on_payer
                          )
                        }
                        className='checkbox style-0'
                      />
                      <span> Документи на платника</span>
                    </label>
                  </div>
                  {invoice.partner_for_documents && (
                    <div>
                      Для документів:{' '}
                      <PartnerLink
                        partnerId={invoice.partner_for_documents.id}
                        partnerName={invoice.partner_for_documents.name}
                      />
                    </div>
                  )}
                </div>
              )}
            {invoice && !completed && (
              <div className='flex justify-center md:justify-start'>
                <InvoiceActions
                  invoice={invoice}
                  state={state}
                  handleActivateLicense={handleActivateLicense}
                  handleActivationMail={handleActivationMail}
                  handlePaid={handlePaid}
                />
              </div>
            )}
            {partner && partner.comment && (
              <div className='flex items-center text-sm text-blue-500'>
                <AnnotationIcon className='mr-1 h-5 w-5' />
                {partner.comment}
              </div>
            )}
            <EmailFix
              partner={partner}
              invoice={invoice}
              handleFixEmail={handleFixEmail}
            />
            {invoice && invoice.remind && <div>Це нагадування</div>}
          </div>
          <div className='w-full pt-2 md:w-1/3 md:pt-0'>
            {invoice && <UTMDetails invoice={invoice} />}
          </div>
        </div>
        {!completed && (
          <div className='flex w-full justify-center py-2 md:mt-2 md:rounded-b md:bg-gray-300'>
            <button
              disabled={invoice && !invoice.paid}
              className='rounded bg-blue-500 px-4 py-2 text-lg text-white shadow'
              onClick={handleSuccessClick}
            >
              Виконано
            </button>
            <button
              className='ml-1 rounded bg-red-700 px-4 py-2 text-lg text-white shadow'
              onClick={() => handleUnrecognizedPayment(reactKey)}
            >
              В загублені
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Manager = ({ manager }: { manager: IManager }) => {
  if (manager && manager.is_active && !manager.is_staff) {
    return (
      <span className='whitespace-nowrap rounded bg-pink-700 px-1 text-sm text-white'>
        {manager.username}
      </span>
    );
  }
  return null;
};

const PaymentsList = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const search = query.get('search') || undefined;

  const [currentPage, setCurrentPage] = useState(1);

  const { payments, isLoading, mutate, lastPage } = usePayments({
    page: currentPage,
    filter: search,
  });

  const handleSearch = (search: string) => {
    if (search) {
      return history.push(`${location.pathname}?search=${search}`);
    }
    history.push(`${location.pathname}`);
  };

  return (
    <>
      <SocketIO channel='bank' onUpdate={() => mutate()} />
      <SocketIO channel='licenses' onUpdate={() => mutate()} />
      <Helmet title='Платежі' />
      <div className='justify-left flex flex-wrap items-center bg-gray-700 px-2 py-2'>
        <div className='w-full md:w-1/3'>
          <SearchForm onSearch={handleSearch} initialValue={search} />
        </div>
        <div className='flex w-full items-center py-1 md:w-auto md:px-4'>
          <Pager
            onPageChange={({ selected }) => setCurrentPage(selected + 1)}
            pageCount={lastPage}
            initialPage={currentPage}
          />
        </div>
      </div>
      {isLoading || !payments ? (
        <SpinnerWithContainer />
      ) : (
        <div>
          {payments.results.map((payment) => (
            <Payment
              key={payment.id}
              reactKey={payment.id.toString()}
              onUpdate={() => mutate()}
              data={payment}
            />
          ))}
        </div>
      )}
    </>
  );
};

const EmailFix = ({
  partner,
  invoice,
  handleFixEmail,
}: {
  partner: {
    email: string;
  };
  invoice: {
    email: string;
  };
  handleFixEmail: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
  if (
    partner === null ||
    invoice === null ||
    invoice.email === '' ||
    invoice.email === partner.email
  ) {
    return null;
  }
  return (
    <div className='text-red-600'>
      {partner.email} -&gt; {invoice.email}{' '}
      <button className='btn-primary' onClick={handleFixEmail}>
        Виправити
      </button>
    </div>
  );
};

const InvoiceActions = ({
  invoice,
  state,
  handlePaid,
  handleActivateLicense,
  handleActivationMail,
}: {
  invoice: {
    paid: boolean;
  };
  state: string;
  handlePaid: (e: React.MouseEvent<HTMLElement>) => void;
  handleActivateLicense: (e: React.MouseEvent<HTMLElement>) => void;
  handleActivationMail: (e: React.MouseEvent<HTMLElement>) => void;
}) => {
  return (
    <div className='mt-2'>
      <button
        className={
          'mr-2 rounded-md bg-gray-400 py-2 px-4 shadow' +
          (invoice.paid ? ' cursor-not-allowed opacity-50' : '')
        }
        disabled={invoice.paid}
        onClick={handlePaid}
      >
        Сплачений
      </button>
      <button
        className='mr-2 rounded-md bg-gray-400 px-4 py-2 shadow'
        disabled={state === 'need_transfer'}
        onClick={handleActivateLicense}
      >
        Активувати
      </button>
      <button
        className='mr-2 rounded-md bg-gray-400 px-4 py-2 shadow'
        // disabled={state === 'need_transfer'}
        onClick={handleActivationMail}
      >
        Повід. про активацію
      </button>
    </div>
  );
};

const StateActions = ({
  handlePartnerLink,
}: {
  handlePartnerLink: () => void;
}) => (
  <button className='rounded bg-blue-300 px-4 py-2' onClick={handlePartnerLink}>
    Отримати посилання на кабінет
  </button>
);

export default PaymentsList;
