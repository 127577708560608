import { saveAs } from 'file-saver';
import { API2 } from './api/api';
import { OldPost } from './components/InvoiceDetails/PostsList';
import { Disposition } from './types/CallEvent';
import { PostDocumentState } from './types/PostDocument';

export const getStateName = (post: OldPost): PostDocumentState => {
  if (post.return_reason) return 'returned';
  if (post.sent) return 'sent';
  if (post.printed) return 'printed';
  return 'wait';
};

export const downloadFile = async (
  invoiceId: string | number,
  format = 'pdf',
  documentType = 'invoice',
  sign = true,
  options?: {
    edrpouOwner: string;
    edrpouRecipient: string;
    date: string;
    emailRecipient: string;
  }
): Promise<void> => {
  const token = API2.auth.get_token();
  const response = await fetch(
    `https://old.sonatazvit.com.ua/api/v2/invoices/${invoiceId}/export/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        format,
        sign,
        document: documentType,
      }),
    }
  );
  const blob = await response.blob();
  if (documentType === 'akt' && options) {
    const { edrpouOwner, edrpouRecipient, date, emailRecipient } = options;
    saveAs(
      blob,
      `${edrpouOwner}_${edrpouRecipient}_${date}_${documentType}_${invoiceId}_${emailRecipient}.${format}`
    );
  } else {
    saveAs(blob, `${documentType}-${invoiceId}.${format}`);
  }
  console.log('Start download');
};

export const printFile = async (
  invoiceId: string | number,
  format = 'pdf',
  documentType: 'invoice' | 'envelop' | 'invoice_akt' | 'contract' = 'invoice',
  sign = true,
  options?: {
    edrpouOwner: string;
    edrpouRecipient: string;
    date: string;
    emailRecipient: string;
  }
): Promise<void> => {
  const token = API2.auth.get_token();
  const response = await fetch(
    `https://old.sonatazvit.com.ua/api/v2/invoices/${invoiceId}/export/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        format,
        sign,
        document: documentType,
      }),
    }
  );
  const blob = await response.blob();
  // post file to print server
  const formData = new FormData();
  formData.append('file', blob);

  let url = '';
  switch (documentType) {
    case 'invoice':
      url = 'http://localhost:3001/invoice/';
      break;
    case 'envelop':
      url = 'http://localhost:3001/envelope/';
      break;
    case 'invoice_akt':
      url = 'http://localhost:3001/invoice/';
      break;
    case 'contract':
      url = 'http://localhost:3001/contract/';
      break;
    default:
      break;
  }

  const printResult = await fetch(url, {
    method: 'POST',
    body: formData,
  });
  console.log(printResult);
};

export function isNumeric(n: string | number): boolean {
  return n != null && n !== '' && !isNaN(Number(n.toString()));
}

const leadZero = (number: number) => {
  return number < 10 ? '0' + number : number;
};

export function formatDate(
  date: string | Date,
  format = 'DD.MM.YYYY HH:mm'
): string {
  const _date = new Date(date);
  return format
    .replace('YYYY', _date.getFullYear().toString())
    .replace('MM', leadZero(_date.getMonth() + 1).toString())
    .replace('DD', leadZero(_date.getDate()).toString())
    .replace('YY', (_date.getFullYear() - 2000).toString())
    .replace('HH', leadZero(_date.getHours()).toString())
    .replace('mm', leadZero(_date.getMinutes()).toString());
}

export const isOrganisation = (edrpou: string): boolean => {
  return edrpou.search(/^\d{8,9}$/) > -1;
};

export const isFop = (edrpou: string): boolean => {
  return edrpou.search(/^\d{10}$/) > -1 || edrpou.search(/^\D{2}\d{6}$/) > -1;
};

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

export const getCallColor = ({
  disposition,
  extNumber,
  incoming,
}: {
  disposition: Disposition;
  extNumber: string;
  incoming: boolean;
}): string => {
  let callStatus;
  if (disposition === 'ANSWER') {
    callStatus = 'bg-green-300';
  } else if (disposition === 'NOANSWER') {
    callStatus = 'bg-red-300';
  } else if (disposition === 'CANCEL') {
    callStatus = 'bg-yellow-300';
  } else if (incoming && extNumber) {
    callStatus = 'bg-orange-300';
  } else if (disposition && disposition.search('VM') > -1) {
    callStatus = 'bg-gray-200';
  } else {
    callStatus = 'bg-orange-300';
  }
  return callStatus;
};

export const phoneWithoutPrefix = (fullPhone: string): string => {
  if (!fullPhone) return '?';
  return fullPhone.replace('+38', '');
};

export const getDispositionText = (disposition: Disposition): string => {
  let dispositionTranslate: string = disposition;
  if (disposition) {
    if (disposition.search('VM') > -1) {
      dispositionTranslate = 'Неробочий час';
    } else if (disposition === 'ANSWER') {
      dispositionTranslate = '';
      // dispositionTranslate = 'Ответили';
    } else if (disposition === 'CANCEL') {
      dispositionTranslate = 'Скинули';
    } else if (disposition === 'NOANSWER') {
      dispositionTranslate = 'Пропущено';
    } else if (disposition === 'CHANUNAVAI') {
      dispositionTranslate = 'Немає доступних ліній';
    } else if (disposition === 'BUSY') {
      dispositionTranslate = 'Зайнято';
    }
  }
  return dispositionTranslate;
};

export const shortName = (partnerName: string): string =>
  partnerName
    .replace('ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ', 'ТОВ')
    .replace('СЕЛЯНСЬКО-ФЕРМЕРСЬКЕ ГОСПОДАРСТВО', 'СФГ')
    .replace('ПРИВАТНЕ ПІДПРИЄМСТВО', 'ПП')
    .replace('Товариство з обмеженою відповідальністю', 'ТОВ')
    .replace('ФЕРМЕРСЬКЕ ГОСПОДАРСТВО', 'ФГ')
    .replace("ОБ'ЄДНАННЯ СПІВВЛАСНИКІВ БАГАТОКВАРТИРНОГО БУДИНКУ", 'ОСББ')
    .replace("Об'єднання співвласників багатоквартирного будинку", 'ОСББ')
    .replace('ПУБЛІЧНЕ АКЦІОНЕРНЕ ТОВАРИСТВО', 'ПАТ')
    .replace('КОЛЕКТИВНЕ ПІДПРИЄМСТВО', 'КП')
    .replace('СЕЛЯНСЬКЕ (ФЕРМЕРСЬКЕ) ГОСПОДАРСТВО', 'СФГ')
    .replace('КОМУНАЛЬНЕ ПІДПРИЄМСТВО', 'КП')
    .replace('СІЛЬСЬКОЇ РАДИ', 'С/Р')
    .replace('СЕЛИЩНОЇ РАДИ', 'С/Р')
    .replace('ГОЛОВНЕ УПРАВЛІННЯ', 'ГУ');
