import React from 'react';
import STATES from './states';
import { DocumentRecord } from '../Documents';
import { ListControllerProps } from '../../RestView/types';
import DropDown from '../FormElements/DropDown';
import DropDownItemA from '../FormElements/DropDownItemA';
import { TrashIcon } from '@heroicons/react/solid';
import { PostDocumentState } from '../../types/PostDocument';
import RectButton from '../Buttons/RectButton';
import { notifyDocsSent } from '../../api/moleculer';

type DropDownMenuProps = {
  resourceId: string;
  state: PostDocumentState;
  updateResource: (resourceId: string, newData: any) => void;
  removeResource: (resourceId: string) => void;
};

const DropDownMenu = ({
  resourceId,
  state,
  updateResource,
  removeResource,
}: DropDownMenuProps): JSX.Element => (
  <div className='px-2'>
    <DropDown dots title='' left>
      {state === STATES.SENT && (
        <DropDownItemA
          onClick={() => {
            updateResource(resourceId, { state: STATES.WAIT });
          }}
        >
          Отмена
        </DropDownItemA>
      )}
      <DropDownItemA
        onClick={() => {
          if (confirm('Вы удаляете заявку. Еще есть шанс отменить')) {
            removeResource(resourceId);
          }
        }}
      >
        <TrashIcon className='inline-flex w-5 h-5 mr-1 text-red-700' />
        Удалить
      </DropDownItemA>
    </DropDown>
  </div>
);

type Props = {
  resource?: DocumentRecord;
  data?: ListControllerProps;
};

const RowActions = (props: Props): JSX.Element => {
  const { resource, data } = props;
  if (!resource || !data) return <></>;
  const { provider, state } = resource;
  const { updateResource, removeResource } = data;

  return (
    <div className='flex justify-between'>
      <div className='flex space-x-2'>
        {state !== STATES.SENT &&
          state !== STATES.PRINTED &&
          provider === 'ukrposhta' && (
            <RectButton
              onClick={() =>
                updateResource(resource._id, { state: STATES.PRINTED })
              }
              className='bg-teal-600 hover:bg-teal-700'
            >
              Распечатан
            </RectButton>
          )}
        {state !== STATES.SENT &&
          state !== STATES.RETURNED &&
          ((state === 'printed' && provider === 'ukrposhta') ||
            (provider !== 'ukrposhta' && state === 'wait')) && (
            <RectButton
              onClick={() =>
                updateResource(resource._id, { state: STATES.SENT })
              }
              className='bg-blue-600 hover:bg-blue-700'
            >
              Отправлен
            </RectButton>
          )}
        {state === STATES.SENT && provider === 'ukrposhta' && (
          <RectButton
            className='bg-orange-500 hover:bg-orange-600 disabled:bg-orange-500'
            onClick={() => notifyDocsSent(resource.invoiceNumber)}
          >
            Уведомить
          </RectButton>
        )}
      </div>
      <DropDownMenu
        state={state}
        resourceId={resource._id}
        removeResource={removeResource}
        updateResource={updateResource}
      />
    </div>
  );
};

export default RowActions;
