import { HeartIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import React from 'react';
import DropDown from '../FormElements/DropDown';
import DropDownItemA from '../FormElements/DropDownItemA';

type Props = {
  // handleSendToSlack: (e: React.MouseEvent) => void;
  handleNoteDelete: () => void;
  handleNoteEdit: () => void;
  handlePin: () => void;
};

const NoteMenu = ({
  // handleSendToSlack,
  handleNoteDelete,
  handleNoteEdit,
  handlePin,
}: Props): JSX.Element => (
  <div className='flex float-right pt-2 pr-1'>
    <DropDown title='Открыть меню' dots left>
      <DropDownItemA onClick={handlePin}>
        <HeartIcon className='mr-1 h-5 w-5 inline-flex' />
        <span>Закрепить</span>
      </DropDownItemA>
      <DropDownItemA onClick={handleNoteEdit}>
        <PencilIcon className='mr-1 h-5 w-5 inline-flex' />
        <span>Изменить</span>
      </DropDownItemA>
      <DropDownItemA onClick={handleNoteDelete}>
        <TrashIcon className='mr-1 h-5 w-5 inline-flex text-red-700' />
        <span>Удалить</span>
      </DropDownItemA>
    </DropDown>
  </div>
);

export default NoteMenu;
