import useSWR from 'swr';
import { OldPost } from '../components/InvoiceDetails/PostsList';
import { oldFetcher } from './utils';

const OLD_API = process.env.REACT_APP_OLD_API;

export const usePartnerPosts = (partnerId: number) => {
  const { data, error, mutate } = useSWR<OldPost[], any>(
    `${OLD_API}/api/partners/${partnerId}/posts/`,
    oldFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    posts: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
