import useSWR from 'swr';
import { OldRestResult } from '../providers/types';
import { TOldPayment } from '../types/Payments';
import { oldFetcher } from './utils';

const OLD_API = process.env.REACT_APP_OLD_API;

type TParams = {
  page?: number;
  filter?: string | null;
};

export const usePayments = ({ page = 1, filter = '' }: TParams) => {
  const { data, error, mutate } = useSWR<OldRestResult<TOldPayment>>(
    `${OLD_API}/api/payments/?page=${page}&filter=${filter || ''}`,
    oldFetcher
    // { revalidateOnFocus: false }
  );
  return {
    payments: data,
    lastPage: data?.last_page,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
