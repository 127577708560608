import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import { SWRConfig } from 'swr';
import './assets/css/font-awesome.min.css';
import Container from './components/Container';
import Login from './components/Login';

const App: React.FC = () => {
  return (
    <SWRConfig
      value={{ fetcher: (url) => fetch(url).then((res) => res.json()) }}
    >
      <HashRouter>
        <Route exact path='/login' component={Login} />
        <Route path='/' component={Container} />
      </HashRouter>
    </SWRConfig>
  );
};

export default App;
