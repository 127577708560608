import { PencilIcon, UserIcon } from '@heroicons/react/outline';
import React from 'react';
import Spinner from '../../RestView/Spinner';
import { API2 } from '../../api/api';
import { formatLegalAddress, getYouControlInfo } from '../../api/moleculer';
import Message from '../../common/messages';
import { usePriceList } from '../../hooks/usePriceList';
import { InvoiceData } from '../../types/InvoiceData';
import { Partner } from '../../types/Partner';
import RectButton from '../Buttons/RectButton';
import DropDown from '../FormElements/DropDown';
import DropDownItemA from '../FormElements/DropDownItemA';

type Props = {
  partner: Partner;
  partnerId: string;
  isFop: boolean;
  onUpdateAction: () => void;
  onEditMode: (enable: boolean) => void;
};

const ActionsBar = ({
  partner,
  partnerId,
  isFop,
  onUpdateAction,
  onEditMode,
}: Props): JSX.Element => {
  const { prices, isLoading } = usePriceList();
  if (isLoading || !prices) return <Spinner />;
  const handleSendTeamViewerLink = () => {
    Message.info('Надсилання', 'Надсилання посилання на teamviewer');
    API2.partners.sendTeamViewerLink(partnerId).then(() => {
      onUpdateAction();
    });
  };

  const updatePartnerData = async (partnerId: string, partner: Partner) => {
    // Перевести ПІБ у скорочене та тільки перша буква велика
    const toShortPibCapitalize = (pib: string) => {
      const parts = pib.toLocaleLowerCase().trim().split(/\s+/);
      if (parts.length !== 3) {
        throw new Error('Некоректний формат ПІБ');
      }

      const [lastName, firstName, middleName] = parts;
      const initialLastName =
        lastName.charAt(0).toUpperCase() + lastName.slice(1);
      const initialFirstName = firstName.charAt(0).toUpperCase() + '.';
      const initialMiddleName = middleName.charAt(0).toUpperCase() + '.';
      return `${initialLastName} ${initialFirstName}${initialMiddleName}`;
    };

    const toFullPibCapitalize = (pib: string) => {
      const parts = pib.toLocaleLowerCase().trim().split(/\s+/);
      if (parts.length !== 3) {
        throw new Error('Некоректний формат ПІБ');
      }

      const [lastName, firstName, middleName] = parts;
      const initialLastName =
        lastName.charAt(0).toUpperCase() + lastName.slice(1);
      const initialFirstName =
        firstName.charAt(0).toUpperCase() + firstName.slice(1);
      const initialMiddleName =
        middleName.charAt(0).toUpperCase() + middleName.slice(1);
      return `${initialLastName} ${initialFirstName} ${initialMiddleName}`;
    };

    Message.info('Оновлення', 'Оновлення даних партнера');
    const { edrpou } = partner;
    const response = await getYouControlInfo(edrpou);
    const { data: info } = response;
    if (edrpou.length === 8) {
    }
    console.log(info);
    if (!info) {
      console.warn(response);
      return;
    }
    const values: any = {};
    if (edrpou.length === 8) {
      if (!partner.address && info.address) {
        const formated = await formatLegalAddress(info.address);
        console.log(formated);
        if (formated?.output) {
          values.address = formated.output;
        }
      }
      if (info.signers) {
        const signer = getSigner(info.signers);
        if (signer) {
          if (!partner.contract_fio) {
            values.contract_fio = toFullPibCapitalize(signer);
          }
          if (!partner.contract_fio_footer) {
            values.contract_fio_footer = toShortPibCapitalize(signer);
          }
        }
      }
    }
    if (Object.keys(values).length > 0) {
      console.log(values);
      await API2.partners.changePartner(partnerId, values);
      onUpdateAction();
    }
  };

  const handleCreateInvoice = (term = 12, sent = true) => {
    Message.info('Створення', 'Створення рахунку');
    const filtered = prices.filter(
      (item) => item.period === term && item.individual === partner.is_fop
    );
    const invoiceData: InvoiceData = {
      edrpou: partner.edrpou,
      name: partner.name,
      phones: '',
      price: filtered[0].price,
      licType: filtered[0].code,
      isGov: partner.is_gov,
      notSendEmail: !sent,
      email: undefined,
    };
    invoiceData.email = partner.email;
    API2.invoices.createCustomInvoice(invoiceData).then(() => {
      onUpdateAction();
    });
  };
  return (
    <div className='flex'>
      <div className='flex items-center space-x-1 py-1' role='group'>
        <RectButton
          className='bg-red-500 text-white hover:bg-red-600 hover:text-white'
          as='a'
          url={'/admin/invoice/partner/' + partnerId + '/'}
        >
          <UserIcon className='h-5 w-5' />
        </RectButton>
        <RectButton
          className='bg-yellow-600 text-white hover:bg-yellow-700'
          onClick={() => onEditMode(true)}
        >
          <PencilIcon className='h-5 w-5' />
        </RectButton>
        <DropDown title='Надіслати рахунок'>
          <DropDownItemA onClick={() => handleCreateInvoice(12, true)}>
            На 12 місяців
          </DropDownItemA>
          <DropDownItemA onClick={() => handleCreateInvoice(12, false)}>
            На 12 місяців
            <br />
            <span className='text-xs text-gray-600'>
              (без надсилання на email)
            </span>
          </DropDownItemA>
          {!isFop && (
            <>
              <DropDownItemA onClick={() => handleCreateInvoice(6, true)}>
                На 6 місяців
              </DropDownItemA>
              <DropDownItemA onClick={() => handleCreateInvoice(3, true)}>
                На 3 місяця
              </DropDownItemA>
            </>
          )}
        </DropDown>
        <DropDown title='Дії'>
          <DropDownItemA onClick={handleSendTeamViewerLink}>
            Надіслати посилання на Teamviewer
          </DropDownItemA>
        </DropDown>
        <RectButton
          className='bg-yellow-600 text-white hover:bg-yellow-700'
          onClick={() => updatePartnerData(partnerId, partner)}
        >
          {/* <PencilIcon className='h-5 w-5' /> */}
          Оновити дані з реєстру
        </RectButton>
      </div>
    </div>
  );
};

const getSigner = (signers: { name: string }[] | undefined) => {
  if (!signers || signers.length === 0) {
    return '';
  }
  if (signers.length === 1) {
    return signers[0].name;
  }
  const lowSigners = signers.map((item) => item.name.toLocaleLowerCase());
  const uniqueSigners = [...new Set(lowSigners)];
  if (uniqueSigners.length === 1) {
    return signers[0].name;
  }
  return '';
};

export default ActionsBar;
