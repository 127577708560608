import useSWR from 'swr';
import { oldFetcher } from './utils';

const OLD_API = process.env.REACT_APP_OLD_API;

export const useInvoicePayments = (invoiceId: string) => {
  const { data, error, mutate } = useSWR(
    `${OLD_API}/api/v2/invoices/${invoiceId}/payments/`,
    oldFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    payments: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
