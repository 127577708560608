import React, { useContext, useEffect, useState } from 'react';
import { API2 } from '../api/api';
import { UserProvider } from '../providers/UserProvider';
import StatsView from './StatsView';
import WorkTable from './worktable';

const MyStats = () => {
  const [debt, setDebt] = useState(0);
  const user = useContext(UserProvider.context);

  const loadData = () => {
    API2.sonata.getStats().then((data) => {
      setDebt(data.debt_current_month);
    });
  };

  useEffect(() => {
    user.api_gate_token && loadData();
  }, [user]);

  return (
    <div>
      <div className='well m-1 p-2'>
        <h2>Статистика</h2>
        Борг Сонаті: {debt}
      </div>
      <div className='well m-1 p-2'>
        <WorkTable />
      </div>
      <div className='well m-1 p-2'>
        <StatsView />
      </div>
    </div>
  );
};

export default MyStats;
