import useSWR from 'swr';
import { Partner } from '../types/Partner';
import { UseSwrResponse } from './swrTypes';
import { oldFetcher } from './utils';

const OLD_API = process.env.REACT_APP_OLD_API;

export const usePartner = (
  partnerId: string
): UseSwrResponse & { partner: Partner } => {
  const { data, error, mutate } = useSWR(
    `${OLD_API}/api/partners/${partnerId}/`,
    oldFetcher
  );
  return {
    partner: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
