import React, { useState } from 'react';

type Props = {
  start: string;
  end: string;
  onChangePeriod: (start: string, end: string) => void;
};
const PeriodChoice = ({ start, end, onChangePeriod }: Props): JSX.Element => {
  const [_start, setStart] = useState(start);
  const [_end, setEnd] = useState(end);

  const handlerChangeStart = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStart(e.target.value);
    onChangePeriod(e.target.value, end);
  };

  const handlerChangeEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnd(e.target.value);
    onChangePeriod(_start, e.target.value);
  };

  return (
    <div className='flex'>
      <div>
        <label>Начало:</label>
        <input type='date' value={_start} onChange={handlerChangeStart} />
      </div>
      <div className='pl-2'>
        <label>Окончание:</label>
        <input type='date' value={_end} onChange={handlerChangeEnd} />
      </div>
    </div>
  );
};

export default PeriodChoice;
