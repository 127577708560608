import React, { useContext, useEffect } from 'react';
import { IProductInfo } from '../../api/api';
import { PriceProvider } from '../../providers/PriceProvider';
import { isFop, isOrganisation } from '../../utils';

const LicTypeChooser = ({
  edrpou,
  licType,
  onChange,
}: {
  edrpou: string;
  licType: number;
  onChange: (license: IProductInfo) => void;
}) => {
  const priceList = useContext(PriceProvider.context);

  useEffect(() => {
    const license = getLicensesList(edrpou)[0] || { code: 0, price: 0 };
    onChange(license);
  }, [edrpou]);

  const getLicensesList = (edrpou: string) => {
    if (isFop(edrpou)) {
      return priceList.filter((lic) => lic.individual);
    } else if (isOrganisation(edrpou)) {
      return priceList.filter((lic) => !lic.individual);
    }
    return [];
  };

  const licenses = getLicensesList(edrpou).map((lic) => {
    return (
      <label key={lic.code} className='flex items-center'>
        <input
          className=''
          name='period'
          type='radio'
          value={lic.code}
          checked={licType === lic.code}
          onChange={(e) => {
            onChange(
              priceList.filter(
                (lic) => lic.code === parseInt(e.target.value, 10)
              )[0]
            );
          }}
        />
        <span className='ml-1'>{lic.period_uk}</span>
      </label>
    );
  });
  return (
    <div id='period' className='my-4 md:flex md:items-center'>
      <label className='pr-2 text-left text-gray-600'>Ліцензія:</label>
      {licenses.length ? (
        <div className='flex items-center space-x-2 rounded p-3'>
          {licenses}
        </div>
      ) : (
        <div className='text-gray-500'>не доступен</div>
      )}
    </div>
  );
};

export default LicTypeChooser;
