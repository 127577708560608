import React from 'react';
import OrganisationWithAction from './OrganisationWithAction';

const ResultList = ({
  results,
  onAppend,
}: {
  results: {
    partnerId: number;
    edrpou: string;
    name: string;
  }[];
  onAppend: (partnerId: number) => void;
}): JSX.Element => {
  if (!results || results.length === 0) {
    return <p className='text-gray-500'>нет результатов</p>;
  }
  const resultsList = results.map((data) => {
    return (
      <li key={data.partnerId} className='py-2'>
        <OrganisationWithAction data={data} onAppend={onAppend} />
      </li>
    );
  });
  return <ul className='divide-y divide-gray-300'>{resultsList}</ul>;
};

export default ResultList;
