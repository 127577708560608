import moment from 'moment';
import 'moment/locale/ru';
import React, { useEffect, useState } from 'react';
import { API2 } from '../../api/api';
import { classNames } from '../../utils';

const LinkedPartners = ({
  edrpou,
  phones = [],
}: {
  edrpou: string;
  phones?: {
    number: string;
  }[];
}) => {
  const [linked, setLinked] = useState<any[]>([]);
  useEffect(() => {
    console.log('Fetch partners by phone');
    const fetchData = async () => {
      const partners = [];
      const promises = [];
      for (const phoneData of phones) {
        const resp = API2.moleculer.getPartnersByPhone(phoneData.number);
        promises.push(resp);
      }
      await Promise.all(promises);
      for (const promise of promises) {
        const _partners = await promise;
        for (const partner of _partners) {
          console.log(partner);
          if (
            partners.find((value) => value._id === partner._id) === undefined &&
            partner.edrpou !== edrpou
          ) {
            partners.push(partner);
          }
        }
      }
      // const sorted = partners.sort(
      //   (a, b) => moment(b.lastLicense.date) - moment(a.lastLicense.date)
      // );
      // setLinked(sorted);
      setLinked(partners);
    };
    fetchData();
  }, [phones]);
  return (
    <div>
      {linked.map((p, k) => (
        <LinkedPartner key={k} partner={p} />
      ))}
    </div>
  );
};

const LinkedPartner = ({
  partner,
}: {
  partner: {
    edrpou: string;
    partnerId: number;
    name: string;
  };
}) => {
  // const [alien, setAlien] = useState();
  const [lastLicense, setLastLicense] = useState<{
    end: string | undefined;
  }>();
  useEffect(() => {
    console.log('Fetch last license');
    let isConnected = true;
    const fetchData = () => {
      API2.moleculer.getLastLicense(partner.edrpou).then((data) => {
        if (isConnected) {
          setLastLicense(data);
        }
      });
      // API2.moleculer
      //   .getLicenses(partner.edrpou)
      //   .then((data) => data.json())
      //   .then((data) => {
      //     if (isConnected) {
      //       setAlien(data.alien);
      //     }
      //   });
    };
    fetchData();
    return () => {
      isConnected = false;
    };
  }, [partner.edrpou]);
  return (
    <div className='mb-1 rounded bg-white px-2 py-1 ring-1 ring-black ring-opacity-5'>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={`#/partners/${partner.partnerId}`}
      >
        {partner.name}
      </a>
      <br />
      {lastLicense && Object.keys(lastLicense).length ? (
        <span
          className={classNames(
            'text-sm',
            moment() >= moment(lastLicense.end).endOf('day')
              ? ' text-gray-400'
              : moment(lastLicense.end).diff(moment(), 'days') <= 30
              ? 'text-orange-700'
              : 'text-green-800'
          )}
        >
          {moment(lastLicense.end).endOf('day').fromNow()} (
          {moment(lastLicense.end).format('DD.MM.YY')})
        </span>
      ) : (
        <div className='text-sm text-gray-600'>Дилер</div>
      )}
    </div>
  );
};

export default LinkedPartners;
