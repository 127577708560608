import React, { useEffect, useRef, useState } from 'react';

type Props = {
  content: string;
  handleSubmit: (content: string | undefined) => void;
  handleCancel: () => void;
};

const NoteEditForm = ({
  content,
  handleSubmit,
  handleCancel,
}: Props): JSX.Element => {
  const [text, setText] = useState(content || '');
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref.current?.focus();
  });
  return (
    <form
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        console.log(ref.current?.value);
        handleSubmit(ref.current?.value);
      }}
    >
      <div className='flex items-center relative'>
        <input
          type='text'
          value={text}
          onChange={(e) => setText(e.target.value)}
          ref={ref}
          placeholder='Введите примечание...'
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              handleCancel && handleCancel();
            }
          }}
        />
        {/* <button
        className='bg-gray-800 text-white h-10 w-10 flex items-center justify-center rounded-r-lg absolute right-0 px-2'
        type='submit'
      >
        <CheckIcon className='h-5 w-5' />
      </button> */}
      </div>
    </form>
  );
};

export default NoteEditForm;
