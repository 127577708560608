// TODO НЕ использовать эти кнопки, замена на RectButton
import React from 'react';

interface ButtonProps {
  onClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, ...props }) => (
  <button
    {...props}
    onClick={onClick}
    className='px-3 py-2 text-white bg-blue-600 rounded shadow hover:bg-blue-800'
  >
    {children}
  </button>
);

export default Button;
