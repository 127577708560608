import { Switch } from '@headlessui/react';
import React from 'react';
import { classNames } from '../../utils';

type Props = {
  checked: boolean;
  onChange: (v: boolean) => void;
  title?: string;
  children: React.ReactNode;
  className?: string;
};

const Switcher = ({
  checked,
  title,
  onChange,
  children,
  className = 'justify-between',
}: Props): JSX.Element => {
  return (
    <Switch.Group
      as='div'
      className={`flex items-center space-x-2 ${className}`}
    >
      <Switch.Label
        as='span'
        className={classNames('flex flex-col', className ? '' : 'flex-grow')}
        passive
      >
        <span
          className={classNames(
            'block text-sm font-medium text-gray-800 transition-opacity',
            checked ? 'opacity-100' : 'opacity-50'
          )}
        >
          {children}
        </span>
      </Switch.Label>
      <Switch
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        )}
      >
        {title && <span className='sr-only'>{title}</span>}
        <span
          aria-hidden='true'
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  );
};

export default Switcher;
