import React from 'react';
import { DataProvider } from '../providers/types';
import DataProviderContext from './DataProviderContext';

interface Props {
  dataProvider: DataProvider<unknown>;
  children: React.ReactNode;
}

const Admin: React.FC<Props> = ({ dataProvider, children }) => (
  <div id='admin'>
    <DataProviderContext.Provider value={dataProvider}>
      {children}
    </DataProviderContext.Provider>
  </div>
);

export default Admin;
