import { PhoneIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { PhoneIncomingIcon, PhoneOutgoingIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { API2 } from '../../api/api';
import { usePhones } from '../../hooks/usePhones';
import Spinner from '../../RestView/Spinner';
import { PhoneNumber } from '../../types/PhoneNumber';
import { formatDate } from '../../utils';
import ClipboardButton from '../Buttons/ClipboardButton';
import RectButton from '../Buttons/RectButton';

type PhoneProps = {
  phone: PhoneNumber;
  onRemove: (number: string) => void;
  onCall: (number: string) => void;
};
const Phone = ({ phone, onRemove, onCall }: PhoneProps) => (
  <li className='flex items-center justify-between rounded bg-gray-100 py-1 px-2'>
    <div className='flex flex-wrap'>
      <div className='flex items-center'>
        <a
          className='text-gray-700'
          href={'sip:' + phone.number.replace(/^\+38/g, '')}
        >
          {phone.number.replace(/^\+38/g, '')}
        </a>
        <ClipboardButton clipboardText={phone.number.replace(/^\+38/g, '')} />
      </div>
      <span className='w-full space-x-1 text-xs text-indigo-600'>
        <span
          className='text-green-600'
          title='Останній дзвінок з цього номера'
        >
          <PhoneIncomingIcon className='inline-flex h-3 w-3' />
          {phone.lastCallIn
            ? formatDate(phone.lastCallIn, 'DD.MM.YY HH:mm')
            : '-'}
        </span>
        <span className='text-indigo-500' title='Останній дзвінок на цей номер'>
          <PhoneOutgoingIcon className='inline-flex h-3 w-3' />
          {phone.lastCallOut
            ? formatDate(phone.lastCallOut, 'DD.MM.YY HH:mm')
            : '-'}
        </span>
      </span>
    </div>
    <div className='flex space-x-1'>
      <RectButton
        className='h-8 w-8 border-red-500 bg-white text-red-500 hover:bg-red-400 hover:text-white'
        onClick={() => {
          if (
            window.confirm('Ви впевнені, що хочете видалити номер телефону?')
          ) {
            onRemove(phone.number);
          }
        }}
        title='Видалити телефон'
      >
        <TrashIcon className='h-5 w-5' />
      </RectButton>
      <RectButton
        className='h-8 w-8 bg-green-600 text-white hover:bg-green-700'
        onClick={() => onCall(phone.number)}
        title='Подзвонити на цей номер'
      >
        <PhoneIcon className='h-5 w-5' />
      </RectButton>
    </div>
  </li>
);

const PhoneAddForm = ({
  onAddPhone,
}: {
  onAddPhone: (number: string) => void;
}) => {
  const [phone, setPhone] = useState('');
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setPhone('');
    onAddPhone(phone);
  };
  return (
    <form onSubmit={handleSubmit} className='relative flex items-center'>
      <input
        id='add_phone'
        className='h-9 py-2 pl-2 pr-9'
        type='tel'
        value={phone}
        placeholder='Додати телефон'
        onChange={(e) => setPhone(e.target.value)}
      />
      <button
        className='absolute inset-y-0 right-0 flex h-9 w-9 items-center justify-center rounded-r-lg bg-green-600 text-white hover:bg-green-700 focus:outline-none focus:ring-0'
        type='submit'
      >
        <PlusIcon className='h-6 w-6' />
      </button>
    </form>
  );
};

const PhonesList = ({ partnerId }: { partnerId: string }): JSX.Element => {
  const { phones, isLoading, mutate } = usePhones(partnerId);
  if (isLoading) return <Spinner />;

  const handleAddPhone = (number: string) => {
    if (number === undefined || number.trim() === '') return;
    API2.moleculer.appendPhone(partnerId, number).then(mutate);
  };

  const handleRemovePhone = (phoneNumber: string) => {
    API2.moleculer.removePhone(partnerId, phoneNumber).then(mutate);
  };

  const handleCall = (phone: string) => {
    console.log('call to', phone);
    API2.calls.callToPhone(phone, partnerId).then((data) => console.log(data));
  };
  const phonesList = phones?.map((phone) => (
    <Phone
      key={phone.number}
      phone={phone}
      onRemove={handleRemovePhone}
      onCall={handleCall}
    />
  ));
  return (
    <ul className='py-2'>
      <h3 className='text-gray-500'>Телефони:</h3>
      <div className='space-y-1 divide-y divide-gray-200'>{phonesList}</div>
      <div className='pt-1'>
        <PhoneAddForm onAddPhone={handleAddPhone} />
      </div>
    </ul>
  );
};

export default PhonesList;
