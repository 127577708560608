import { PostDocument } from '../types/PostDocument';
import { SendToNovaposhta } from '../types/SendToNovaposhta';
import { SendToUkrposhta } from '../types/SendToUkrposhta';
import { SendToVchasno } from '../types/SendToVchasno';

const DEBUG = process.env.REACT_APP_STAGE === 'dev';

const MOLECULER_API = 'https://test.sonatazvit.com.ua/api';
// if (DEBUG) {
//   MOLECULER_API = 'http://localhost:3000/api';
// }

type PostCodeInfo = {
  success?: false;
  cityId: string;
  cityUa: string;
  districtId: string;
  districtUa: string;
  regionId: string;
  regionUa: string;
};

const getToken = () => {
  return localStorage.getItem('token');
};

const getHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
  'Content-Type': 'application/json',
});

export const getPostCodeInfo = async (
  postCode: string
): Promise<PostCodeInfo> => {
  const res = await fetch(`${MOLECULER_API}/documents/postoffice/${postCode}`);
  return res.json();
};

export const getDfsExchangeInfo = async (edrpou: string): Promise<any> => {
  const url = `${MOLECULER_API}/private/gates/dps/exchange/${edrpou}`;
  const res = await fetch(url, {
    headers: getHeaders(),
  });
  return res.json();
};

export const getYouControlInfo = async (edrpou: string): Promise<any> => {
  const url = `${MOLECULER_API}/private/youcontrol/${edrpou}`;
  const res = await fetch(url, {
    headers: getHeaders(),
  });
  return res.json();
};

export const formatEnvelopeAddress = async (
  address: string
): Promise<{ input: string; output: string }> => {
  const url = `${MOLECULER_API}/private/secretary/format-envelope-address`;
  const res = await fetch(url, {
    method: 'post',
    headers: getHeaders(),
    body: JSON.stringify({
      address,
    }),
  });
  return res.json();
};

export const formatLegalAddress = async (
  address: string
): Promise<{ input: string; output: string }> => {
  const url = `${MOLECULER_API}/private/secretary/format-legal-address`;
  const res = await fetch(url, {
    method: 'post',
    headers: getHeaders(),
    body: JSON.stringify({
      address,
    }),
  });
  return res.json();
};

export const sendDocumentToQueue = async (
  document: SendToVchasno | SendToUkrposhta | SendToNovaposhta
): Promise<
  { success: true; data: any } | { success: false; reason: string }
> => {
  const url = `${MOLECULER_API}/private/documents/new`;
  const res = await fetch(url, {
    method: 'post',
    headers: getHeaders(),
    body: JSON.stringify(document),
  });
  if (res.status !== 200) {
    return { success: false, reason: res.statusText };
  }
  return { success: true, data: await res.json() };
};

interface ISuccess {
  success: true;
  data: { id: string; url: string };
}
interface IFalse {
  success: false;
  reason: string;
}

type TResult = ISuccess | IFalse;

export const sendDocumentToGoogleDrive = async (
  docNumber: string,
  documentType: 'akt' | 'invoice' | 'contract'
): Promise<TResult> => {
  const url = `${MOLECULER_API}/private/exports/${docNumber}/export_to_gdocs?docType=${documentType}&options[signAndStamp]=true&&options[description]=${docNumber}`;
  const res = await fetch(url, {
    method: 'post',
    headers: getHeaders(),
    body: JSON.stringify(document),
  });
  if (res.status !== 200) {
    return { success: false, reason: res.statusText };
  }
  return { success: true, data: await res.json() };
};

export const deleteDocumentFromQueue = async (
  documentId: string
): Promise<any> => {
  const url = `${MOLECULER_API}/private/documents/${documentId}`;
  await fetch(url, {
    method: 'delete',
    headers: getHeaders(),
  });
  return { success: true };
};

export const notifyDocsSent = async (invoiceId: number): Promise<any> => {
  const url = `${MOLECULER_API}/private/invoices/${invoiceId}/notify-docs-sent`;
  console.log(url);
  await fetch(url, {
    method: 'post',
    headers: getHeaders(),
  });
  return { success: true };
};

// export const setDocumentState = async (
//   documentId: string,
//   newState: PostDocument['state']
// ): Promise<void> => {
//   const url = `${MOLECULER_API}/private/documents/${documentId}`;
//   const res = await fetch(url, {
//     method: 'put',
//     headers: getHeaders(),
//     body: JSON.stringify({
//       state: newState,
//     }),
//   });
//   console.log(res.status);
//   console.log(res.statusText);
// };

export const returnDocument = async (
  documentId: string,
  returnReason: string
): Promise<void> => {
  const url = `${MOLECULER_API}/private/documents/${documentId}`;
  const newState: PostDocument['state'] = 'returned';
  const res = await fetch(url, {
    method: 'put',
    headers: getHeaders(),
    body: JSON.stringify({
      state: newState,
      returnReason,
    }),
  });
  console.log(res.status);
  console.log(res.statusText);
};
