import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import React from 'react';
import ReactPaginate from 'react-paginate';

type Props = {
  pageCount: number | undefined;
  initialPage: number;
  onPageChange: (selectedItem: { selected: number }) => void;
};

const Pager = ({
  pageCount = 1,
  initialPage = 1,
  onPageChange,
}: Props): JSX.Element => (
  <ReactPaginate
    previousLabel={<ChevronLeftIcon className='h-6 w-6' />}
    nextLabel={<ChevronRightIcon className='h-6 w-6' />}
    breakLabel={'...'}
    forcePage={initialPage - 1}
    pageCount={pageCount}
    marginPagesDisplayed={3}
    pageRangeDisplayed={3}
    onPageChange={onPageChange}
    containerClassName='inline-flex shadow-sm -space-x-px text-lg'
    pageLinkClassName='text-gray-800 focus:outline-none py-2 px-3 text-center'
    previousLinkClassName='text-gray-800 focus:outline-none py-2 text-center'
    nextLinkClassName='text-gray-800 focus:outline-none py-2 text-center'
    activeClassName='bg-orange-200'
    disabledClassName='disabled cursor-not-allowed bg-gray-300 text-gray-400 hover:bg-gray-300'
    pageClassName='relative inline-flex items-center border border-gray-400 bg-white text-sm font-medium text-gray-700 hover:bg-gray-200'
    breakClassName='relative px-2 inline-flex items-center border border-gray-400 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50'
    previousClassName='relative inline-flex items-center rounded-l-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
    nextClassName='relative inline-flex items-center rounded-r-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
  />
);

export default Pager;
