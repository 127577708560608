import React from 'react';
import Pager from '../components/Pager';
import { ListContext } from './ListContextProvider';
import SelectField from './SelectField';
import Spinner from './Spinner';

const TableHeader = ({
  selectable,
  items,
}: {
  selectable: boolean;
  items: { props: { title: string } }[];
}): JSX.Element => (
  <thead className='bg-gray-50'>
    <tr>
      {selectable && (
        <th
          scope='col'
          className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'
        ></th>
      )}
      {items.map((child, indx) => (
        <th
          key={indx}
          scope='col'
          className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'
        >
          {child.props.title}
        </th>
      ))}
    </tr>
  </thead>
);

const TableBody = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <tbody className='divide-y divide-gray-200 bg-white'>{children}</tbody>
);

const TableFooter = ({
  colSpan,
  children,
}: {
  colSpan: number;
  children: React.ReactNode;
}): JSX.Element => (
  <tfoot className='bg-white text-center'>
    <tr className='border-t border-gray-300 '>
      <td colSpan={colSpan} className='py-2'>
        {children}
      </td>
    </tr>
  </tfoot>
);

type Props = {
  children: React.ReactElement[];
  rowStyle: (item: any) => string;
  selectable?: boolean;
};

const TableContainer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <div className='p-2- flex flex-col'>
    <div className='--my-2 sm:--mx-6 lg:--mx-8 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle sm:px-1 lg:px-2'>
        <div className='overflow-hidden border-b border-gray-200 shadow sm:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-200'>
            {children}
          </table>
        </div>
      </div>
    </div>
  </div>
);

const CenterSpinner = (): JSX.Element => (
  <div className='flex justify-center pt-10'>
    <Spinner />
  </div>
);

const NoData = () => (
  <div className='pt-10 text-center text-lg text-gray-500'>
    Нет данных для отображения
  </div>
);

const Datagrid = ({ children, rowStyle, selectable }: Props): JSX.Element => (
  <ListContext.Consumer>
    {(value) =>
      value.dataLoading ? (
        <CenterSpinner />
      ) : !value.items || value.items.length === 0 ? (
        <NoData />
      ) : (
        <TableContainer>
          <TableHeader selectable={selectable || false} items={children} />
          <TableBody>
            {value.items.map((item) => (
              <tr className={rowStyle(item)} key={item._id}>
                {selectable && (
                  <td className='py-2 pl-4'>
                    <SelectField resource={item} data={value} source='' />
                  </td>
                )}
                {children.map((el, idx) => (
                  <td key={idx} className='p-2'>
                    {React.cloneElement(el, {
                      resource: item,
                      data: value,
                    })}
                  </td>
                ))}
              </tr>
            ))}
          </TableBody>
          <TableFooter colSpan={children.length + (selectable ? 2 : 1)}>
            <Pager
              onPageChange={({ selected }) => value.setPage(selected + 1)}
              pageCount={value.totalPages}
              initialPage={value.currentPage}
            />
          </TableFooter>
        </TableContainer>
      )
    }
  </ListContext.Consumer>
);

export default Datagrid;
