import React, { useState, useEffect } from 'react';
import PeriodChoice from './PeriodChoice';
import Chart from 'chart.js';
import { formatDate } from '../utils';
import { API2 } from '../api/api';

const API_URLS = [
  {
    name: 'Продажи',
    url: '/api/stats/profit/',
  },
  {
    name: 'Звонки',
    url: '/api/stats/calls/',
  },
  {
    name: 'Лицензии',
    url: '/api/stats/licenses/',
  },
];

let myChart: Chart;

const ChoiceChart = ({
  onChangeChart,
}: {
  onChangeChart: (chart: string) => void;
}) => (
  <div className='flex'>
    <div>
      Тип графика:
      <select
        defaultValue=''
        required
        onChange={(e) => onChangeChart(e.target.value)}
      >
        {API_URLS.map(function (data) {
          return (
            <option key={data.url} value={data.url}>
              {data.name}
            </option>
          );
        })}
      </select>
    </div>
  </div>
);

const StatsView = (): JSX.Element => {
  const DATE_FORMAT = 'YYYY-MM-DD';
  const dt = new Date(new Date().getFullYear(), 0, 1);
  const [start, setStart] = useState(formatDate(dt, DATE_FORMAT));
  const [end, setEnd] = useState(formatDate(new Date(), DATE_FORMAT));
  const [url, setUrl] = useState(API_URLS[0].url);

  const handlerChangePeriod = (start: string, end: string) => {
    setStart(start);
    setEnd(end);
  };
  const loadDataFromServer = () => {
    const params = {
      start,
      end,
    };
    API2.stats.getStats(url, params).then((response: any): void => {
      const ctx = document.getElementById('myChart') as HTMLCanvasElement;
      if (myChart) {
        myChart.data.datasets = response.data.datasets;
        myChart.data.labels = response.data.labels;
        if (myChart.options && myChart.options.title) {
          myChart.options.title.text =
            response.title + ' ' + start + ' по ' + end;
        }
        myChart.update();
      } else {
        myChart = new Chart(ctx, {
          type: response.chart_type,
          data: response.data,
          options: {
            title: {
              display: true,
              text: response.title + ' ' + start + ' по ' + end,
            },
            responsive: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          },
        });
      }
    });
  };

  useEffect(() => {
    loadDataFromServer();
  }, [start, end, url]);

  return (
    <>
      <ChoiceChart onChangeChart={(url) => setUrl(url)} />
      <PeriodChoice
        start={start}
        end={end}
        onChangePeriod={handlerChangePeriod}
      />
      <canvas id='myChart' width='800' height='600'></canvas>
    </>
  );
};

export default StatsView;
