import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import TextField from '../../../RestView/TextField';
import { FieldProps } from '../../../RestView/types';

const InvoiceField: React.FC<FieldProps> = ({
  source,
  resource,
  label,
  ...props
}) => {
  const value = get(resource, source);
  if (!value) return null;
  return (
    <div {...props}>
      {label && <span className='text-xs text-gray-600'>{label}: </span>}
      <Link to={`/invoices/${value}`}>№ {value}</Link>{' '}
      <TextField
        label='Сумма'
        className='text-xs text-gray-600'
        resource={resource}
        source='invoice.total'
      >
        грн.
      </TextField>
    </div>
  );
};

export default InvoiceField;
