import useSWR from 'swr';
import { OldRestResult } from '../providers/types';
import { TOldInvoice } from '../types/Invoices';
import { oldFetcher } from './utils';

const OLD_API = process.env.REACT_APP_OLD_API;

type TParams = {
  page?: number;
  filter?: string | null;
  edrpou?: string;
  shouldFetch?: boolean;
};

export const useInvoices = ({
  page = 1,
  filter = '',
  edrpou = '',
  shouldFetch = true,
}: TParams) => {
  let url = `${OLD_API}/api/invoices/?page=${page}&filter=${filter || ''}`;
  if (edrpou) {
    url = `${OLD_API}/api/v2/invoices/?page=${page}&partner__edrpou=${
      edrpou || ''
    }&page_size=30`;
  }
  const { data, error, mutate } = useSWR<OldRestResult<TOldInvoice>>(
    shouldFetch ? url : null,
    oldFetcher
    // { revalidateOnFocus: false }
  );
  return {
    invoices: data?.results,
    lastPage: data?.last_page,
    isLoading: !error && !data && shouldFetch,
    isError: error,
    mutate,
  };
};
