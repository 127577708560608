import React from 'react';
import { downloadFile } from '../../utils';
import IconButton from '../Buttons/IconButton';
import ContractIcon from '../Icons/ContractIcon';
import DocsIcon from '../Icons/DocsIcon';
import EnvelopeIcon from '../Icons/EnvelopeIcon';
import PacketIcon from '../Icons/PacketIcon';
import STATES from './states';

const DocumentActions = ({ resource }: { resource?: any }) => {
  const { provider, state, invoiceNumber } = resource;
  if (provider !== 'vchasno' && state === STATES.WAIT) {
    return (
      <div className='inline-flex space-x-1'>
        <IconButton
          title='Рахунок та Акт'
          onClick={() =>
            downloadFile(invoiceNumber, 'pdf', 'invoice_akt', false)
          }
        >
          <DocsIcon />
        </IconButton>
        <IconButton
          title='Конверт'
          onClick={() => downloadFile(invoiceNumber, 'pdf', 'envelop')}
        >
          <EnvelopeIcon />
        </IconButton>
        <IconButton
          title='Пакет'
          onClick={() => downloadFile(invoiceNumber, 'pdf', 'packet', false)}
        >
          <PacketIcon />
        </IconButton>
        <IconButton
          title='Договір'
          onClick={() => downloadFile(invoiceNumber, 'pdf', 'contract', false)}
        >
          <ContractIcon />
        </IconButton>
      </div>
    );
  }
  return null;
};

export default DocumentActions;
