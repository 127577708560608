import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { API2, IQueueTask } from '../api/api';
import { formatDate } from '../utils';

const InvoiceInfo = ({ invoice }: { invoice: IQueueTask['invoice'] }) => (
  <span>
    <span className='label label-warning' title='Cума за рахунком'>
      Рахунок: {invoice.total}
    </span>
    &nbsp;
    <span className={'label label-' + (invoice.paid ? 'success' : '')}>
      <Link to={`/invoices/${invoice.id}/`}>Рахунок {invoice.id}</Link>
    </span>
  </span>
);

const Task = ({
  onSuccess,
  data,
}: {
  data: IQueueTask;
  onSuccess: () => void;
}) => (
  <div className='well well-sm mb-3'>
    <div className='flex flex-wrap'>
      <div className='w-1/6'>
        {formatDate(data.date, 'DD.MM.YY HH:mm')}
        <br />
        {data.title}
      </div>
      <div className='w-1/2'>
        {data.name}
        <div>
          <small>{data.body}</small>
        </div>
        {data.invoice && (
          <div>
            <span>Партнер за рахунком: {data.invoice.buyer}</span>
          </div>
        )}
        Призначеня: {data.details}
      </div>
      <div className='w-1/6'>
        <span className='label label-success' title='Сплачено'>
          Сплачено: {data.total}
        </span>
        &nbsp;
        {data.invoice && <InvoiceInfo invoice={data.invoice} />}
        &nbsp;
      </div>
      <div className='w-1/6'>
        <a
          href='#'
          className='btn btn-default'
          title='Встановити як виконана'
          onClick={(e) => {
            e.preventDefault();
            onSuccess();
          }}
        >
          Виконана
        </a>
      </div>
    </div>
  </div>
);

const TasksList = () => {
  const [tasks, setTasks] = useState<IQueueTask[]>([]);
  const loadData = async () => {
    const tasks = await API2.queue.getAll();
    setTasks(tasks);
  };
  useEffect(() => {
    loadData();
  }, []);

  if (!tasks || tasks.length === 0) return <></>;

  return (
    <>
      <Helmet title='Черга' />
      {tasks.map((task) => (
        <Task
          key={task.id}
          onSuccess={async () => {
            await API2.queue.setCompleted(task.id);
            loadData();
          }}
          data={task}
        />
      ))}
    </>
  );
};

export default TasksList;
