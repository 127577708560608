import React from 'react';

const InvoiceOrganisation = ({
  organisation,
}: {
  organisation: { name: string };
}): JSX.Element => (
  <small>{organisation ? `Рахунок від: ${organisation.name}` : null}</small>
);

export default InvoiceOrganisation;
