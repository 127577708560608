import React from 'react';
import { classNames, formatDate, getStateName } from '../../utils';
import DropDown from '../FormElements/DropDown';
import DropDownItemA from '../FormElements/DropDownItemA';
import { PostDocument, PostDocumentState } from '../../types/PostDocument';
import { UserCircleIcon } from '@heroicons/react/solid';
import ProviderLogo from '../ProviderLogo';
import { PostState } from '../PartnerPosts';

export type OldPost = {
  address: string;
  comment: string;
  created: string;
  email_edo: string;
  id: number;
  invoice: {
    id: number;
    date: string;
  };
  partner: number;
  post_type: null;
  printed: boolean;
  provider: 'ukrposhta' | 'novaposhta' | 'vchasno';
  return_reason: string;
  sent: boolean;
  sent_date: string;
  user: { id: number; username: string; full_name: string; is_active: boolean };
};

const PostItem = ({
  post,
  onDelete,
}: {
  post: OldPost;
  onDelete: (postId: string) => void;
}): JSX.Element => (
  <div className='relative py-2'>
    {/* {getStateName(post) === 'wait' && (
      <div className='absolute right-0'>
        <DropDown dots left title='Действия'>
          <DropDownItemA onClick={() => onDelete(post._id)}>
            Удалить
          </DropDownItemA>
        </DropDown>
      </div>
    )} */}
    <div className='flex items-center space-x-1'>
      <ProviderLogo provider={post.provider} />
      <span className='text-gray-800'>
        {formatDate(post.created, 'DD.MM.YY HH:mm')}
      </span>
      <PostState state={getStateName(post)} stateName={getStateName(post)} />
      {post.sent && (
        <div className='text-green-700'>
          Отправлен: {formatDate(post.sent_date, 'DD.MM.YY')}
        </div>
      )}
    </div>
    <div className='space-y-1 py-1 text-gray-800'>
      <div className='flex items-center space-x-1'>
        <UserCircleIcon className='h-4 w-4 text-gray-600' />
        {post.user ? (
          <span className='text-gray-700'>{post.user.full_name}</span>
        ) : (
          <span className='text-gray-500'>Через сайт</span>
        )}
      </div>

      {post.provider === 'vchasno' && <div>{post.email_edo}</div>}
      {post.provider === 'novaposhta' && <div>{post.address}</div>}
      {post.provider === 'ukrposhta' && (
        <div>
          <div>{post.address}</div>
          {/* <div>{post.recipient}</div> */}
        </div>
      )}
    </div>
    <div className='text-purple-600'>{post.comment}</div>
  </div>
);

const PostsList = ({
  posts,
  onDelete,
}: {
  posts: OldPost[];
  onDelete: (postId: string) => void;
}): JSX.Element => {
  const postsList = posts.map((post) => {
    return <PostItem key={post.id} post={post} onDelete={onDelete} />;
  });
  return <div className='divide-y divide-gray-300'>{postsList}</div>;
};

export default PostsList;
