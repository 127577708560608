import React from 'react';
import { classNames } from '../../utils';

type Props = {
  className?: string;
  children: React.ReactNode;
  title?: string;
};

const Badge = ({
  children,
  className = 'bg-gray-100 text-gray-800',
  title,
}: Props): JSX.Element => (
  <span
    title={title}
    className={classNames(
      'inline-flex items-center rounded-full px-2.5 py-1 text-xs font-medium',
      className
    )}
  >
    {children}
  </span>
);

export default Badge;
