import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { usePartners } from '../hooks/usePartners';
import Spinner from '../RestView/Spinner';
import { classNames } from '../utils';
import Card from './Card';
import Badge from './FormElements/Badge';
import Gerb from './Gerb';
import Pager from './Pager';
import SearchForm from './SearchForm';

const PartnerItem = ({ data }: { data: any }) => {
  const ageDays = Math.floor(
    (new Date().getTime() - new Date(data.created).getTime()) /
      1000 /
      60 /
      60 /
      24
  );
  const isNew = ageDays < 7;
  return (
    <Card className='relative text-sm'>
      <div className='font-semibold text-gray-800'>{data.edrpou}</div>
      {data.created && (
        <span className='absolute top-0 right-0 pt-1 pr-2 text-xs text-gray-600'>
          {moment(data.created).format('DD.MM.YY')}
        </span>
      )}
      <div className='flex'>
        <div>{data.isGov && <Gerb className='h-5 w-5' />}</div>
        <Link to={{ pathname: `/partners/${data.partnerId}` }}>
          {data.name ? data.name : 'Без назви'}
        </Link>
      </div>
      <div className='text-purple-700'>{data.email}</div>
      <div className='space-x-1'>
        {isNew && (
          <Badge
            className='bg-orange-500 text-white'
            title={ageDays + ' днів після реєстрації'}
          >
            {ageDays + ' днів'}
          </Badge>
        )}

        {data.lastLicense && (
          <Badge
            className={classNames(
              'font-normal',
              data.lastLicense.state === 'Актуальна' ||
                moment(data.lastLicense.start) >= moment()
                ? 'bg-green-600 text-white'
                : 'bg-gray-300 text-gray-600'
            )}
          >
            {moment(data.lastLicense.end).format('DD.MM.YY')}{' '}
            {data.lastLicense.type}
          </Badge>
        )}
      </div>
      {/* {(data.client_id || data.referer) && (
        <div className='text-xs text-gray-500 break-words truncate'>
          {data.client_id && <div>{data.client_id}</div>}
          {data.referer && <div>{data.referer}</div>}
        </div>
      )} */}
    </Card>
  );
};

const PartnersList = (): JSX.Element => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const history = useHistory();
  const query = useQuery();
  const search = query.get('search');
  const page = parseInt(query.get('page') || '1');

  const { partners, isLoading } = usePartners(
    page,
    search && search.length > 0 ? search : null
  );

  const setCurrentPage = (pageIndex: number) => {
    query.set('page', pageIndex.toString());
    history.push({
      search: query.toString(),
    });
  };

  const handleSearch = (search: string) => {
    query.set('search', search);
    history.push({
      search: query.toString(),
    });
  };

  if (isLoading) {
    return <Spinner />;
  }
  if (!partners) return <></>;

  return (
    <>
      <Helmet title='Клієнти' />
      <div className='flex flex-wrap items-center justify-center space-y-1 bg-gray-700 py-2 md:justify-start md:space-y-0'>
        <div className='w-full md:w-1/2'>
          <SearchForm
            onSearch={handleSearch}
            initialValue={search ? search : ''}
            placeholder='ЄДРПОУ, назва, email або телефон'
          />
        </div>
        <Pager
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
          pageCount={partners.totalPages || 1}
          initialPage={page}
        />
      </div>

      <div className='grid gap-1 sm:grid-cols-2 md:grid-cols-3 md:gap-2 md:p-2 lg:grid-cols-4'>
        {partners &&
          partners.rows.map((partner) => {
            return <PartnerItem key={partner._id} data={partner} />;
          })}
      </div>
      <div className='mb-2 flex justify-center py-1 pt-3 md:px-4 md:pt-0'>
        <Pager
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
          pageCount={partners.totalPages || 1}
          initialPage={page}
        />
      </div>
    </>
  );
};

export default PartnersList;
