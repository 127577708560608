import useSWR from 'swr';
import { RestResult } from '../providers/types';
import { NoteType } from '../types/Note';
import { fetcher } from './utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

export const useLastNotes = () => {
  const { data, error, mutate } = useSWR<RestResult<NoteType>, any>(
    `${MOLECULER}/private/notes/?pageSize=30&sort=-created`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    notes: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
