import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Admin from '../RestView/Admin';
import DataColumn from '../RestView/DataColumn';
import Datagrid from '../RestView/Datagrid';
import DateField from '../RestView/DateField';
import FilterList from '../RestView/Filters/FilterList';
import FilterListItem from '../RestView/Filters/FilterListItem';
import FilterSearch from '../RestView/Filters/FilterSearch';
import List from '../RestView/List';
import { ListContext } from '../RestView/ListContextProvider';
import Resource from '../RestView/Resource';
import TextField from '../RestView/TextField';
import { moleculerProvider } from '../providers/moleculerProvider';
import { BaseRecord } from '../providers/types';
import { PostDocumentState, PostProvider } from '../types/PostDocument';
import Button from './Buttons/Button';
import DocumentActions from './Documents/DocumentActions';
import InvoiceField from './Documents/Fields/InvoiceField';
import PartnerLinkField from './Documents/Fields/PartnerLinkField';
import ProviderField from './Documents/Fields/ProviderField';
import RowActions from './Documents/RowActions';
import rowStyle from './Documents/rowStyle';
import STATES from './Documents/states';

let MOLECULER_API = 'https://test.sonatazvit.com.ua/api';
// eslint-disable-next-line no-undef
if (process.env.REACT_APP_STAGE === 'dev') {
  MOLECULER_API = 'http://localhost:3000/api';
}

type PartnerRecord = {
  partnerId: number;
  address: string;
  partnerName: string;
};

type InvoiceRecord = {
  total: number;
};

export interface DocumentRecord extends BaseRecord {
  email: string;
  provider: PostProvider;
  created: string;
  address?: string;
  invoiceNumber: number;
  state: PostDocumentState;
  priority: string;
  stateName: string;
  comment?: string;
  invoice: InvoiceRecord;
  partner: PartnerRecord;
}

const Aside = () => (
  <div className='space-y-2 py-2'>
    <FilterSearch
      source='invoiceNumber'
      placeholder='№ рахунку'
      processValue={(value) => parseInt(value, 10)}
    />
    <FilterList label='Статус'>
      <FilterListItem label='Очікування' value={{ state: STATES.WAIT }} />
      <FilterListItem label='Надісланий' value={{ state: STATES.SENT }} />
      <FilterListItem label='Надрукований' value={{ state: STATES.PRINTED }} />
      <FilterListItem label='Повернення' value={{ state: STATES.RETURNED }} />
    </FilterList>
    <FilterList label='Провайдер'>
      <FilterListItem label='Вчасно' value={{ provider: 'vchasno' }} />
      <FilterListItem label='Укрпошта' value={{ provider: 'ukrposhta' }} />
      <FilterListItem label='Нова пошта' value={{ provider: 'novaposhta' }} />
    </FilterList>
  </div>
);

const BulkActions = () => {
  const { selected, setSelected } = useContext(ListContext);
  return (
    <>
      <Button
        onClick={() => {
          console.log(selected);
          setSelected([]);
        }}
      >
        Помітити як надіслані
      </Button>
      <Button
        onClick={() => {
          console.log(selected);
          setSelected([]);
        }}
      >
        Надіслати повідомлення
      </Button>
    </>
  );
};

type DocumentsListProps = any;

const DocumentsList = (props: DocumentsListProps) => (
  <List bulkActions={<BulkActions />} aside={<Aside />} {...props}>
    <Datagrid rowStyle={rowStyle} selectable>
      <ProviderField source='provider' />
      <DataColumn title='Заявка'>
        <DateField
          className='text-xs text-gray-600'
          source='created'
          title='Створена'
        />
        <DocumentActions />
      </DataColumn>
      {/* <TextField source='priority' title='Приоритет' /> */}
      <DataColumn title='Рахунок'>
        <PartnerLinkField
          className='text-teal-600'
          source='partner'
          title='Клієнт'
        />
        <InvoiceField
          className='flex items-center space-x-1'
          source='invoiceNumber'
          title='Рахунок'
          label='Рахунок'
        />
        <TextField
          label='Автор'
          source='user.fullName'
          className='text-xs text-pink-700'
        />
      </DataColumn>
      <DataColumn title='Адрес'>
        <TextField source='email' />
        <TextField source='fullAddress' />
        <TextField label='У клієнта' source='partner.address' />
        <TextField className='text-xs text-yellow-800' source='comment' />
        <TextField
          label='Повернення'
          className='text-xs text-red-500'
          source='returnReason'
        />
      </DataColumn>
      {/* <TextField source='stateName' title='Статус' /> */}
      <DataColumn title='Дії'>
        <RowActions />
      </DataColumn>
    </Datagrid>
  </List>
);

const Documents: React.FC = () => {
  return (
    <>
      <Helmet title='Документи на відправку' />
      <Admin
        dataProvider={moleculerProvider<DocumentRecord>(
          `${MOLECULER_API}/private/`
        )}
      >
        <Resource name='documents' list={DocumentsList} orderBy='-created' />
      </Admin>
    </>
  );
};

export default Documents;
