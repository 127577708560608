import useSWR from 'swr';

const MOLECULER = process.env.REACT_APP_MOLECULER;

export const useRegions = () => {
  const { data, error } = useSWR<{ id: string; name: string }[], unknown>(
    `${MOLECULER}/documents/regions`,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return {
    regions: data,
    isLoading: !error && !data,
    isError: error,
  };
};
