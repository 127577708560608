import React from 'react';
import PropTypes from 'prop-types';

const FilterListItem = ({ label, value, setFilter, enabled }) => {
  return (
    <a
      onClick={() => {
        if (enabled) {
          const newValue = { ...value };
          Object.keys(newValue).map((key) => (newValue[key] = undefined));
          setFilter(newValue);
        } else {
          setFilter(value);
        }
      }}
      className={`py-2- leading-10 pl-4 pr-2 block cursor-pointer hover:bg-indigo-400 ${
        enabled ? 'text-indigo-900 bg-indigo-400 font-medium' : 'text-gray-800'
      }`}
    >
      <div className='flex items-center justify-between'>
        {label}
        {enabled && (
          <svg
            className='h-6 w-6 inline-block opacity-80'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
        )}
      </div>
    </a>
  );
};
FilterListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.object,
  setFilter: PropTypes.func,
  enabled: PropTypes.bool,
};

export default FilterListItem;
