import { RefreshIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { getDfsExchangeInfo } from '../api/moleculer';
import Spinner from '../RestView/Spinner';

type ExDocument = {
  docCd: string;
  docType: 'Original' | 'Response';
};

type DocsListProps = {
  docs: ExDocument[];
};

const DocsList = ({ docs }: DocsListProps): JSX.Element => {
  const docs_list = docs.map(function (doc) {
    return (
      <li key={doc.docCd}>
        {doc.docCd} ({doc.docType})
      </li>
    );
  });
  return (
    <div>
      Кількість документів: {docs.length}
      <ul>{docs_list}</ul>
    </div>
  );
};

type Props = {
  edrpou: string;
};

const DfsExchange = ({ edrpou }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [docs, setDocs] = useState<ExDocument[]>();

  const check = () => {
    setIsLoading(true);
    setDocs([]);
    getDfsExchangeInfo(edrpou).then((data) => {
      if (data.success) {
        setIsLoading(false);
        setDocs(data.documents);
      }
    });
  };
  return (
    <>
      <div className='header1'>Сервер обміну ДФС:</div>
      <button className='btn-primary' onClick={check}>
        <RefreshIcon className='h-5 w-5' />
      </button>
      {isLoading ? <Spinner /> : <div>{docs && <DocsList docs={docs} />}</div>}
    </>
  );
};

export default DfsExchange;
