import React from 'react';
import { Helmet } from 'react-helmet';
import { moleculerProvider } from '../providers/moleculerProvider';
import Admin from '../RestView/Admin';
import BooleanField from '../RestView/BooleanField';
import DataColumn from '../RestView/DataColumn';
import Datagrid from '../RestView/Datagrid';
import DateField from '../RestView/DateField';
import FilterList from '../RestView/Filters/FilterList';
import FilterListItem from '../RestView/Filters/FilterListItem';
import FilterSearch from '../RestView/Filters/FilterSearch';
import List from '../RestView/List';
import Resource from '../RestView/Resource';
import TextField from '../RestView/TextField';
import StatusField from './Reminders/Fields/StatusField';
import TagsField from './Reminders/Fields/TagsField';

const MOLECULER_API = 'https://test.sonatazvit.com.ua/api';
// eslint-disable-next-line no-undef
// if (process.env.REACT_APP_STAGE === 'dev') {
//   MOLECULER_API = 'http://localhost:3000/api';
// }

// const RemindersFilter = () => {
//   const history = useHistory();
//   const location = useLocation();
//   const handleSearch = (filter) => {
//     const query = new URLSearchParams(location.search);
//     if (filter) {
//       if (filter.search('@') > -1) {
//         query.set('q', JSON.stringify({email: {'$regex': filter}}));
//       } else {
//         query.set('q', JSON.stringify({edrpou: {'$regex': filter}}));
//       }
//     } else {
//       query.delete('q');
//     }
//     history.push(`${location.pathname}?${query.toString()}`);
//   };
//   return (
//     <div className="w-1/2 px-2">
//       <SearchForm onSearch={handleSearch} />
//     </div>
//   );
// };

const Aside = () => (
  <div className='space-y-2 py-2'>
    <FilterSearch source='edrpou' placeholder='ЄДРПОУ' />
    <FilterList label='Надісланий'>
      <FilterListItem label='Так' value={{ sentOn: { $exists: true } }} />
      <FilterListItem label='Ні' value={{ sentOn: { $exists: false } }} />
    </FilterList>
  </div>
);

const RemindersList = (props: any) => (
  <List
    // bulkActions={<BulkActions />}
    aside={<Aside />}
    {...props}
  >
    <Datagrid rowStyle={() => ''}>
      <DataColumn title='ЄДРПОУ'>
        <TextField source='edrpou' className='font-bold text-purple-800' />
        <TextField source='_id' className='text-xs font-normal text-gray-500' />
        <TagsField source='tags' className='text-xs text-gray-500' />
      </DataColumn>
      <DateField source='createdOn' title='Створена' />
      <DateField source='sendOn' title='План' />
      <DataColumn title='Факт'>
        <DateField source='sentOn' />
        <TextField source='email' className='text-xs text-purple-800' />
        <TextField source='messageId' className='text-xs text-gray-500' />
      </DataColumn>
      <StatusField source='sendResult' title='Статус' />
      <TextField source='sendResult.transport' title='Сервіс' />
      <BooleanField source='locked' title='Lock' />
    </Datagrid>
  </List>
);

const ReminderList = () => (
  <>
    <Helmet title='Список запланованих email' />
    <Admin dataProvider={moleculerProvider(`${MOLECULER_API}/private/`)}>
      <Resource
        name='reminder'
        list={RemindersList}
        orderBy='-createdOn'
        // perPage={50}
      />
    </Admin>
  </>
);

export default ReminderList;
