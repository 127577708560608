import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

type Props = {
  source: string;
  title: string;
  resource?: string;
  className?: string;
};

const PartnerLinkField = ({
  source,
  resource,
  ...props
}: Props): JSX.Element => {
  const value = get(resource, source);
  if (!value) return <></>;
  return (
    <>
      <Link {...props} to={`/partners/${value.partnerId}`}>
        {value.partnerName}
      </Link>
    </>
  );
};

export default PartnerLinkField;
