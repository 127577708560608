import STATES from './states';

const rowStyle = (record: { state: keyof typeof STATES }) => {
  switch (record.state) {
    case STATES.SENT:
      return 'bg-green-200';
    case STATES.PRINTED:
      return 'bg-blue-200';
    case STATES.RETURNED:
      return 'bg-red-200';
  }
  return '';
};

export default rowStyle;
