import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ListContext } from '../ListContextProvider';

const FilterList = ({ children, label }) => {
  const listContext = useContext(ListContext);
  const {filterValues, setFilterValues} = listContext;
  const setFilter = (value) => {
    setFilterValues({...filterValues, ...value});
  };
  return (
    <div>
      <div className='pl-3 pr-2 py-1 uppercase text-gray-600'>{label}</div>
      <div>
        {children.map((el, idx)=>{
          const { value } = el.props;
          const key = Object.keys(value)[0];
          const enabled = filterValues[key] && filterValues[key] === value[key];
          return React.cloneElement(el, {setFilter, enabled, key: idx});
        })}
      </div>
    </div>
  );
};
FilterList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  label: PropTypes.string.isRequired,
};

export default FilterList;