import useSWR from 'swr';
import { RestResult } from '../providers/types';
import { NoteType } from '../types/Note';
import { fetcher } from './utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

export const usePartnerNotes = (partnerId: number) => {
  const { data, error, mutate } = useSWR<RestResult<NoteType>>(
    `${MOLECULER}/private/notes/?pageSize=100&sort=-pin,-created&query={"partnerId":${partnerId}}`,
    fetcher
  );
  return {
    notes: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
