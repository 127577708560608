import { SearchIcon, XIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';

type Props = {
  onSearch: (text: string) => void;
  placeholder?: string;
  initialValue?: string;
};

const SearchForm = ({
  onSearch,
  placeholder = 'Шукати...',
  initialValue = '',
}: Props): JSX.Element => {
  const [value, setValue] = useState(initialValue);
  return (
    <form method='get' className='inline-block w-full px-2'>
      <div className='relative flex'>
        <input
          className='text w-full rounded border border-gray-300 bg-gray-200 py-2 pl-1 pr-16 outline-none focus:ring'
          type='text'
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className='absolute inset-y-0 right-0 flex'>
          {value && (
            <button
              className='w-8- flex items-center p-1 text-gray-500 hover:text-gray-800 focus:outline-none focus:ring-0'
              type='button'
              onClick={() => {
                setValue('');
                onSearch('');
              }}
            >
              <XIcon className='h-6 w-6' />
            </button>
          )}
          <button
            className='flex w-8 items-center rounded-r bg-blue-500 p-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-0'
            type='submit'
            onClick={(e) => {
              e.preventDefault();
              const newValue = value.trim();
              setValue(newValue);
              onSearch(newValue);
            }}
          >
            <SearchIcon className='h-6 w-6' />
          </button>
        </div>
      </div>
    </form>
  );
};
export default SearchForm;
