import { PhoneIcon } from '@heroicons/react/solid';
import React from 'react';
import { API2 } from '../../api/api';

const RecallButton = ({ phone }: { phone: string }): JSX.Element => (
  <button
    className='ml-1 border border-gray-400 rounded w-6 h-6 m-1 bg-gray-300 flex items-center justify-center text-gray-700 hover:bg-gray-400'
    title={`Перезвонить на ${phone}`}
    onClick={() => {
      API2.calls.callToPhone(phone, null).then((data) => console.log(data));
    }}
  >
    <PhoneIcon className='h-4 w-4' />
  </button>
);

export default RecallButton;
