import React from 'react';

const IconButton = ({
  children,
  title,
  onClick,
  ...props
}: {
  children: React.ReactNode;
  title?: string;
  onClick?: () => void;
}) => (
  <button
    {...props}
    title={title}
    onClick={onClick}
    className='h-7 w-7 rounded bg-gray-300 p-1 text-gray-700 shadow-sm hover:bg-teal-200 hover:text-teal-800'
  >
    {children}
  </button>
);

export default IconButton;
