import * as React from 'react';
import NovaPoshtaLogo from './NovaPoshtaLogo';
import UkrposhtaLogo from './UkrposhtaLogo';

const Address = ({
  title,
  ukrposhta,
  novaposhta,
}: {
  title: string;
  ukrposhta: React.ReactNode;
  novaposhta?: React.ReactNode;
}) => (
  <div className='py-1'>
    <h4 className='font-bold text-teal-800'>{title}:</h4>
    <div className='ml-2 flex flex-wrap pt-1'>
      <UkrposhtaLogo className='mr-2 h-5 w-5' /> {ukrposhta}
    </div>
    {novaposhta && (
      <div className='ml-2 flex flex-wrap pt-2'>
        <NovaPoshtaLogo className='mr-2 h-5 w-5' />
        {novaposhta}
      </div>
    )}
  </div>
);

const InfoPanel = () => {
  return (
    <div className='text-sm'>
      <span>
        <b>Наші телефони</b>: 0671281917, 0992519059, 0634724299, 0670119041,
        0673138733, 0996200678
      </span>
      <br />
      <span>
        <b>Viber</b>: 0671281917
      </span>
      <br />
      <span>
        <b>Фізична адреса</b>: м.Біла Церква, вул.Олеся Гончара, 1А/42А, оф.713
      </span>
      <div>
        <b>Нова Пошта</b>
        <br />
        м. Буча, відділення №5 або поштомат №9372, <br />
        отримувач Лавриненко Ольга, фіз особа (не ТОВ Інфомережі),
        <br />
        телефон 0971491634
      </div>
      <Address
        title='Поштова адреса ФОП Єрмаков (2929003816)'
        ukrposhta={<>а/с 53, м.Біла Церква, 09107</>}
        // novaposhta={
        //   <>
        //     №17, м. Біла Церква, вул. Шолом Алейхема, 37
        //     <br />
        //     Отримувач: Єрмаков Сергій Миколайович (096 923-66-35)
        //   </>
        // }
      />
      <Address
        title='Поштова адреса ТОВ "Інформаційні мережі" (34326540)'
        ukrposhta={<>а/с 20, м.Біла Церква, 09107</>}
        // novaposhta={
        //   <>
        //     №17, м. Біла Церква, вул. Шолом Алейхема, 37
        //     <br />
        //     Отримувач: ТОВ “Інформаційні мережі”
        //     <br />
        //     Представник: Лавриненко Ольга Леонідівна (097-149-16-34)
        //   </>
        // }
      />
      <Address
        title='Поштова адреса ТОВ "Інформаційні мережі сервіс" (42467363)'
        ukrposhta={
          <>
            а/с 20, м.Біла Церква, 09107 для ТОВ &quot;Інформаційні мережі&quot;
          </>
        }
        // novaposhta={
        //   <>
        //     №17, м. Біла Церква, вул. Шолом Алейхема, 37
        //     <br />
        //     Отримувач: Єрмаков Сергій Миколайович (096 923-66-35)
        //   </>
        // }
      />
      <Address
        title='Поштова адреса ФОП Лавриненко (3120712483)'
        ukrposhta={
          <>
            а/с 20, м.Біла Церква, 09107 для ТОВ &quot;Інформаційні мережі&quot;
          </>
        }
        // novaposhta={
        //   <>
        //     №17, м. Біла Церква, вул. Шолом Алейхема, 37
        //     <br />
        //     Отримувач: Єрмаков Сергій Миколайович (096 923-66-35)
        //   </>
        // }
      />
      <a
        href='https://sonatazvit.com.ua/request/'
        target='_blank'
        rel='noreferrer'
      >
        Демка
      </a>
    </div>
  );
};

export default InfoPanel;
