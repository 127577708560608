import React from 'react';
import { useSonataInfo } from '../hooks/useSonataInfo';
import Spinner from '../RestView/Spinner';
import DataLine from './DataLine';

type Props = {
  edrpou: string;
};

const SonataInfo = ({ edrpou }: Props): JSX.Element => {
  if (!edrpou) return <></>;
  const { info: sonataData, isLoading } = useSonataInfo(edrpou);
  if (isLoading || !sonataData) return <Spinner />;

  let license, licenseInfo, licenseActive;
  if (sonataData.success && sonataData.data && sonataData.data.licenses) {
    if (!sonataData.data.isMy && sonataData.data.licenses.length > 0) {
      license = sonataData.data.licenses[0];
      licenseInfo = `${license.type} ${license.start}-${license.end}`;
      licenseActive = license.isActive;
    } else {
      const licenses = sonataData.data.licenses.filter((item) => item.isActive);
      if (licenses.length > 0) {
        license = licenses[0];
        licenseInfo = `${license.type} ${license.start}-${license.end}`;
        licenseActive = license.isActive;
      }
    }
  }
  return (
    <div>
      <h2 className='header1'>З кабінету розробника:</h2>
      {sonataData.success ? (
        <div className='space-y-1'>
          <div>{sonataData.data.name}</div>
          <div>
            {licenseActive ? (
              <span className='text-green-700'>{licenseInfo}</span>
            ) : license ? (
              <span className='text-gray-500'>{licenseInfo}</span>
            ) : null}
          </div>
          <DataLine name='Дилер'>
            {sonataData.data.owner}
            <br />
            {sonataData.data.ownerContacts}
          </DataLine>
          <a
            className=''
            target='_blank'
            rel='noopener noreferrer'
            href={
              'https://sonata.biz.ua/organization/view_all?code_display=' +
              sonataData.data.edrpou
            }
          >
            Перейти до кабінету розробника
          </a>
        </div>
      ) : (
        <div className='text-red-600'>{sonataData.reason}</div>
      )}
    </div>
  );
};

export default SonataInfo;
