import { ChevronDoubleRightIcon } from '@heroicons/react/solid';
import React from 'react';

const ExtNumber = ({
  number,
  transNumber,
}: {
  number: string;
  transNumber: string;
}): JSX.Element => (
  <div className='flex items-center justify-center'>
    <b>{number}</b>
    {transNumber ? (
      <span className='flex items-center'>
        <ChevronDoubleRightIcon className='w-3 h-3 text-gray-900' />
        <b>{transNumber}</b>
      </span>
    ) : null}
  </div>
);

export default ExtNumber;
