import React from 'react';
import { FieldProps, ListControllerProps } from './types';

interface SelectFieldProps extends FieldProps {
  _id?: string;
  data: ListControllerProps;
}

const SelectField: React.FC<SelectFieldProps> = ({ resource, data }) => {
  const { selected, setSelected } = data;
  if (!resource) return null;
  const { _id } = resource;
  return (
    <div>
      <input
        type='checkbox'
        checked={selected.indexOf(_id) !== -1}
        onChange={(data) => {
          if (!_id) return;
          const checked = data.target.checked;
          if (checked) {
            setSelected([...selected, _id]);
          } else {
            const index = selected.indexOf(_id);
            if (index !== -1) {
              const newSelected = [...selected];
              newSelected.splice(index, 1);
              setSelected(newSelected);
            }
          }
        }}
      ></input>
    </div>
  );
};

export default SelectField;
