import React from 'react';
import { CallEvent as CallEventType } from '../types/CallEvent';
import CallEvent from './home/CallEvent';

type TabsProps = {
  tab: 0 | 1;
  onChange: (t: 0 | 1) => void;
};

const Tabs = ({ tab, onChange }: TabsProps) => (
  <>
    <ul className='inline-block rounded-t-lg bg-white md:mx-1'>
      <li
        role='presentation'
        className={tab === 0 ? 'inline text-orange-700' : 'inline'}
      >
        <button
          onClick={() => onChange(0)}
          className='inline-block border-r border-gray-400 px-4 py-2 hover:text-gray-800'
        >
          Вхідні
        </button>
      </li>
      <li
        role='presentation'
        className={tab === 1 ? 'inline text-orange-700' : 'inline'}
      >
        <button
          onClick={() => onChange(1)}
          className='inline-block px-4 py-3 hover:text-gray-800'
        >
          Вихідні
        </button>
      </li>
    </ul>
  </>
);

type EventList = {
  tab: 0 | 1;
  events: CallEventType[];
  onChangeTab: (tab: 0 | 1) => void;
};

const EventList = ({ tab, events, onChangeTab }: EventList): JSX.Element => {
  return (
    <>
      <Tabs tab={tab} onChange={onChangeTab} />
      <div className='space-y-2'>
        {events.map((event, ind) => (
          <CallEvent key={ind} data={event} />
        ))}
      </div>
    </>
  );
};

export default EventList;
