import React from 'react';
import get from 'lodash/get';
import { FieldProps } from './types';

const TextField: React.FC<FieldProps> = ({
  children,
  resource,
  source,
  label,
  ...props
}) => {
  const value = get(resource, source);
  console.log(resource, source, value);
  if (!value) return null;
  return (
    <div {...props}>
      {label && <span className='text-xs text-gray-600'>{label}: </span>}
      {value} {children}
    </div>
  );
};

export default TextField;
