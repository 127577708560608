import React, { useEffect, useState } from 'react';
import { API2, IWorktableData, TDayData } from '../api/api';
import RectButton from './Buttons/RectButton';

const WorkDay = ({
  id,
  value,
  onClick,
}: {
  id: number;
  value: number;
  onClick: (dayNumber: number) => void;
}) => (
  <td
    // id={id.toString()}
    // key={id}
    className={
      'workcell border border-gray-400 p-3 ' +
      (value === 2 ? 'half' : '') +
      (value === 1 ? 'full bg-green-500' : '') +
      (value === 3 ? 'holiday bg-red-400' : '')
    }
    onClick={() => onClick(id)}
  ></td>
);

const getTotalDays = (days: TDayData[]) =>
  days.reduce((sum, current) => {
    let value = current.value;
    if (value === 2) value = 0.5;
    if (value === 3) value = 0;
    return sum + value;
  }, 0);

const WorkRow = ({
  onClick,
  person,
  days,
}: {
  onClick: (personId: number, dayNumber: number) => void;
  person: { id: number; name: string };
  days: TDayData[];
}) => (
  <tr className='border border-gray-400'>
    <td className='bg-gray-200 px-2 py-1'>{person.name}</td>
    {days.map((day) => (
      <WorkDay
        key={day.id}
        id={day.id}
        value={day.value}
        onClick={(dayNumber) => {
          onClick(person.id, dayNumber);
        }}
      />
    ))}
    <td className='text-center'>{getTotalDays(days)}</td>
  </tr>
);

const WorkHeader = ({ data }: { data: IWorktableData }) => (
  <tr className='border border-gray-400 bg-gray-300 text-center'>
    <td></td>
    {data.days.map((day) => (
      <td
        key={day.id}
        className={
          'border border-green-300 py-2 px-1' +
          ([5, 6].indexOf(day.weekday) === -1
            ? ' workheader bg-green-400'
            : ' workheader holiday bg-red-300')
        }
      >
        {day.id}
      </td>
    ))}
    <td className='total px-4'>Итог</td>
  </tr>
);

const WorkTable = () => {
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState<IWorktableData>({
    days: [],
    data: [],
    month,
    year,
  });

  const handleDayClick = (person_id: number, cell_id: number) => {
    data.data.forEach((v, i) => {
      if (v.person.id === person_id) {
        data.data[i].days.forEach((v2) => {
          if (v2.id === cell_id) {
            v2.value += 1;
            if (v2.value > 2) {
              v2.value = 0;
            }
          }
        });
      }
    });
    setData({ ...data });
  };

  const loadDataFromServer = () => {
    API2.salary.getWorktable(month, year).then((data) => setData(data));
  };

  useEffect(() => {
    loadDataFromServer();
  }, [month, year]);

  const handlePrevMonth = () => {
    let _month, _year;
    if (month === 1) {
      _month = 12;
      _year = year - 1;
    } else {
      _month = month - 1;
      _year = year;
    }
    setMonth(_month);
    setYear(_year);
  };

  const handleNextMonth = () => {
    let _month, _year;
    if (month === 12) {
      _month = 1;
      _year = year + 1;
    } else {
      _month = month + 1;
      _year = year;
    }
    setMonth(_month);
    setYear(_year);
  };

  const rows = data.data.map((row) => (
    <WorkRow
      key={row.person.id}
      person={row.person}
      days={row.days}
      onClick={handleDayClick}
    />
  ));
  return (
    <div className='worktable'>
      <h2>
        Табель за {month}.{year}
      </h2>
      <div className='flex space-x-1'>
        <RectButton onClick={handlePrevMonth}>Назад</RectButton>
        <RectButton onClick={handleNextMonth}>Вперед</RectButton>
      </div>
      <table className='workdata my-2 p-2'>
        <thead>
          <WorkHeader data={data} />
        </thead>
        <tbody>{rows}</tbody>
      </table>
      <form>
        <button
          type='submit'
          className='btn-primary'
          onClick={(e) => {
            e.preventDefault();
            API2.salary.setWorktable({
              data: data.data,
              days: data.days,
              month: data.month,
              year: data.year,
            });
          }}
        >
          Зберегти
        </button>
      </form>
    </div>
  );
};

export default WorkTable;
