import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import useSWR from 'swr';
import DataProviderContext from './DataProviderContext';
import { ListControllerProps } from './types';

export interface ListProps {
  filters?: React.ReactNode;
  aside?: React.ReactNode;
  bulkActions?: React.ReactNode;
  orderBy?: string;
  perPage?: number;
  name?: string;
}

const useListController = <T,>(props: ListProps): ListControllerProps => {
  const { orderBy, perPage = 25, name } = props;
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);
  const q = query.get('filter');
  const sort = query.get('sort') || '';
  const filterValues = JSON.parse(q || '{}');
  const [selected, setSelected] = useState<T[]>([]);
  const dataProvider = useContext(DataProviderContext);

  const setFilterValues = (values: any[]) => {
    query.set('filter', JSON.stringify({ ...filterValues, ...values }));
    history.push({
      search: query.toString(),
    });
  };

  const setPage = (number: number) => {
    query.set('page', number.toString());
    history.push({
      search: query.toString(),
    });
  };

  const fetcher = async () => {
    if (typeof dataProvider != 'undefined' && name) {
      const response = await dataProvider.getList(name, {
        pagination: { page, perPage },
        sort: { field: sort || orderBy },
        filter: q && JSON.parse(q),
      });
      return response;
    }
  };

  const basePath = `${dataProvider ? dataProvider.getBaseUrl() : ''}${name}${
    location.search
  }`;

  const { data, error, mutate } = useSWR(basePath, fetcher, {
    refreshInterval: 30000,
  });
  const totalPages = data && data.totalPages ? data.totalPages : 1;

  return {
    basePath,
    dataLoading: !data && !error,
    items: !data ? [] : data.rows,
    currentPage: page,
    totalPages,
    setPage,
    setItems: () => {
      mutate();
    },
    filterValues,
    setFilterValues,
    selected,
    setSelected,
    updateResource: (resourceId, newData) => {
      mutate(async (dt: any) => {
        return {
          ...dt,
          rows: dt.rows.map((item: any) => {
            if (item._id === resourceId) {
              return { ...item, ...newData };
            }
            return item;
          }),
        };
      }, false);
      if (dataProvider && name && dataProvider.update) {
        return dataProvider.update(name, resourceId, newData);
      }
      return Promise.resolve();
    },
    removeResource: (resourceId) => {
      console.log('Try remove item...');
      mutate(async (dt: any) => {
        return {
          ...dt,
          rows: dt.rows.filter((item: any) => item._id !== resourceId),
        };
      }, false);
      if (dataProvider && name && dataProvider.remove) {
        return dataProvider.remove(name, resourceId);
      }
      return Promise.resolve();
    },
  };
};

export default useListController;
