import React from 'react';
import { NoteType } from '../../types/Note';
import Note from '../Note';

type Props = {
  data: NoteType[] | undefined;
  onNotePin?: (key: string, pin: boolean) => void;
  onNoteDelete?: (key: string) => void;
  onNoteSubmit?: (key: string, content: string) => void;
  showPartnerName?: boolean;
  disableMenu?: boolean;
};

const NotesList = ({
  data,
  onNotePin,
  onNoteDelete,
  onNoteSubmit,
  showPartnerName = false,
  disableMenu = false,
}: Props): JSX.Element => {
  if (!data) return <></>;
  const notesNodes = data.map((note) => {
    return (
      <Note
        key={note._id}
        onNotePin={onNotePin}
        onNoteDelete={onNoteDelete}
        onNoteSubmit={onNoteSubmit}
        note={note}
        pin={note.pin}
        showPartnerName={showPartnerName}
        disableMenu={disableMenu}
      />
    );
  });
  return <div className='space-y-1 pt-1'>{notesNodes}</div>;
};

export default NotesList;
