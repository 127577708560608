import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { API2, IProductInfo } from '../api/api';
import * as newInvoiceForm from '../forms/newInvoiceForm';
import { useInvoices } from '../hooks/useInvoices';
import { PriceProvider } from '../providers/PriceProvider';
import { isOrganisation } from '../utils';
import Container from './NewInvoice/Container';
import CustomSelectField from './NewInvoice/CustomSelectField';
import EdrpouField from './NewInvoice/EdrpouField';
import Field from './NewInvoice/Field';
import FieldCheckBox from './NewInvoice/FieldCheckBox';
import FormContainer from './NewInvoice/FormContainer';
import LicTypeChooser from './NewInvoice/LicTypeChooser';
import OldInvoices from './NewInvoice/OldInvoices';
import PartnerPhonesList from './NewInvoice/PartnerPhonesList';
import TotalPanel from './NewInvoice/TotalPanel';
import SonataInfo from './SonataInfo';

const InvoiceForm = () => {
  const [phones, setPhones] = useState([]);
  const [license, setLicense] = useState<IProductInfo | null>(null);
  const [currentEdrpou, setCurrentEdrpou] = useState<string>('');
  const {
    invoices: _invoices,
    isLoading,
    mutate,
  } = useInvoices({
    edrpou: currentEdrpou,
    shouldFetch: currentEdrpou.length >= 8 && currentEdrpou.length <= 10,
  });

  const loadPhones = (partnerId: number) => {
    API2.moleculer.getPhones(partnerId).then((phones) => setPhones(phones));
  };

  return (
    <PriceProvider>
      <Formik
        initialValues={newInvoiceForm.initialValues}
        validationSchema={newInvoiceForm.InvoiceSchema}
        onSubmit={(values, actions) =>
          newInvoiceForm.onSubmit(values, actions, {
            total: license?.price,
            licTypeCode: license?.code,
            onSendComplete: () => {
              // loadInvoices(values.edrpou);
              mutate();
            },
          })
        }
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => (
          <Container>
            <div className='w-full text-sm md:flex-1 md:px-2 md:py-1'>
              <FormContainer>
                <Form onSubmit={handleSubmit}>
                  <legend className='w-full'>Виписка рахунку</legend>
                  <div className='flex items-center space-x-2'>
                    <div>
                      <EdrpouField
                        onChange={(partner) => {
                          // setInvoices([]);
                          setPhones([]);
                          setCurrentEdrpou('');
                          if (partner) {
                            setCurrentEdrpou(partner.edrpou);
                            loadPhones(partner.id);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <CustomSelectField
                        name='preferredOrganisation'
                        label='Організація'
                        values={newInvoiceForm.ORGANISATIONS}
                      />
                    </div>
                  </div>
                  <Field name='name' label='Назва або ПІБ' type='text' />
                  {isOrganisation(values.edrpou) && (
                    <FieldCheckBox
                      name='isGov'
                      checked={values.isGov}
                      onChange={(value) => setFieldValue('isGov', value)}
                    >
                      Бюджетник
                    </FieldCheckBox>
                  )}
                  <LicTypeChooser
                    licType={license?.code || 0}
                    edrpou={values.edrpou}
                    onChange={(license) => {
                      setLicense(license);
                    }}
                  />
                  <Field
                    name='email'
                    label='Email'
                    type='email'
                    onBlur={() => {
                      const initialEmail = values.email;
                      let email = values.email;
                      const result = email.match(/^.+<(.*)>$/);
                      console.log(result);
                      if (result) {
                        email = result[1];
                      }
                      email = email.trim();
                      if (initialEmail !== email) {
                        setFieldValue('email', email);
                      }
                    }}
                  />
                  <Field name='phones' label='Телефони' type='text' />
                  <PartnerPhonesList phones={phones} />
                  <Field name='discount' label='Знижка (в грн)' type='number' />
                  <TotalPanel
                    total={license?.price || 0}
                    discount={values.discount}
                  />
                  <div className='text-center'>
                    <button
                      disabled={isSubmitting}
                      className='btn-primary'
                      type='submit'
                    >
                      Надіслати рахунок
                    </button>
                  </div>
                </Form>
              </FormContainer>
            </div>
            <div className='flex-1'>
              {currentEdrpou && (
                <div className='mt-1 rounded bg-white p-5 shadow'>
                  <SonataInfo edrpou={currentEdrpou} />
                </div>
              )}
              <legend>Надіслані раніше:</legend>
              <OldInvoices invoices={_invoices} fetching={isLoading} />
            </div>
          </Container>
        )}
      </Formik>
    </PriceProvider>
  );
};

const NewInvoice = () => (
  <>
    <Helmet title='Новий рахунок' />
    <InvoiceForm />
  </>
);

export default NewInvoice;
