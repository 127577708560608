import React, { createContext, useEffect, useState } from 'react';
import { API2 } from '../api/api';

const context = createContext({
  api_gate_token: undefined,
});

// api_gate_token: "xxxxxxx"
// moleculer_gate_token: "xxxxxxx"
// sip_number: "906"
// user_id: 1
// username: "root"

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState({
    api_gate_token: undefined,
  });
  useEffect(() => {
    let isSubscribed = true;
    API2.users.getCurrentUser().then((data) => {
      if (isSubscribed) {
        setUser(data);
      }
    });
    return () => {
      isSubscribed = false;
    };
  }, []);
  return <context.Provider value={user}>{children}</context.Provider>;
};

UserProvider.context = context;
