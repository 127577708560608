import React from 'react';
import get from 'lodash/get';
import { FieldProps } from './types';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/outline';

const BooleanField: React.FC<FieldProps> = ({ resource, source, ...props }) => {
  const value = get(resource, source);
  console.log(resource, source, value);
  return (
    <div {...props}>
      {value ? (
        <CheckCircleIcon className='w-5 h-5 text-green-700' />
      ) : (
        <XCircleIcon className='w-5 h-5 text-red-600' />
      )}
    </div>
  );
};

export default BooleanField;
