import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { ErrorMessage, Field as FormikField, useField } from 'formik';
import React from 'react';
import { classNames } from '../../utils';

type FieldProps = {
  type?: string;
  name: string;
  className?: string;
  title?: string;
  placeholder?: string;
  as?: string | React.ComponentType<FieldProps>;
  children?: React.ReactNode;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const Field = ({
  type = 'text',
  name,
  className,
  title,
  placeholder,
  ...props
}: FieldProps): JSX.Element => {
  const [_, meta] = useField(name);
  return (
    <div className={className}>
      <label
        htmlFor={`${name}`}
        className='block text-sm font-medium text-gray-600'
      >
        {title}
      </label>
      <div className='mt-1 relative rounded-lg shadow-sm'>
        <FormikField
          type={type}
          name={name}
          placeholder={placeholder}
          {...props}
          className={classNames(
            'pr-10',
            meta.touched && meta.error
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500'
              : ''
          )}
          aria-invalid={meta.touched && meta.error ? 'true' : 'false'}
          aria-describedby={`${name}-error`}
        />
        {meta.touched && meta.error && (
          <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
            <ExclamationCircleIcon
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        )}
      </div>
      {meta.touched && meta.error && (
        <p className='mt-1 text-xs text-red-600' id={`${name}-error`}>
          <ErrorMessage name={name} />
        </p>
      )}
    </div>
  );
};

export default Field;
