import React from 'react';

const DataLine = ({
  name,
  data,
  url,
  children,
  ...props
}: {
  name: string;
  data?: string;
  url?: string;
  icon?: string;
  children?: React.ReactNode;
}) => {
  let dataClass = 'text-gray-900';
  if (!data && !children) {
    data = 'N/A';
    dataClass = 'text-gray-500';
  }
  return (
    <dl className='pb-1' {...props}>
      {name ? <dt className='text-gray-500'>{name}:</dt> : null}
      <dd className={dataClass}>
        {url ? (
          <>
            {children}{' '}
            <a href={url} title={name}>
              {data}
            </a>
          </>
        ) : (
          <>
            {children} {data}
          </>
        )}
      </dd>
    </dl>
  );
};

export default DataLine;
