import useSWR from 'swr';
import { fetcher } from './utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

type License = {
  type: string;
  start: string;
  end: string;
  comment: string;
  status: 'Актуальна' | 'Прострочена';
  activated: string;
  deactivated: string;
  isActive: boolean;
};

type PropsFailed = {
  success: false;
  reason: string;
};

type PropsSuccess = {
  success: true;
  data: {
    edrpou: string;
    name: string;
    email: string;
    id: string;
    phone: string;
    address: string;
    contact: string;
    comment: string;
    url: string;
    owner: string;
    ownerContacts: string;
    isMy: boolean;
    licenses: License[];
    success: true;
  };
};

export const useSonataInfo = (edrpou: string) => {
  const { data, error } = useSWR<PropsSuccess | PropsFailed, any>(
    `${MOLECULER}/private/sonata/clients/${encodeURI(edrpou)}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    info: data,
    isLoading: !error && !data,
    isError: error,
  };
};
