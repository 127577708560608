import React, { useState } from 'react';
import { PHONE_INUSE, PHONE_RINGING, PHONE_UNAVAILABLE } from '../constants';
import SocketIO from './general/SocketIO';

const PhonesPanel = () => {
  const [phones, setPhones] = useState<{
    [key: string]:
      | typeof PHONE_UNAVAILABLE
      | typeof PHONE_INUSE
      | typeof PHONE_RINGING;
  }>();
  const [timestamp, setTimestamp] = useState(0);
  let phonesList: React.ReactNode = <span className='text-white'>---</span>;
  if (phones) {
    phonesList = Object.keys(phones)
      .filter((key) => phones[key] !== PHONE_UNAVAILABLE)
      .map((key) => {
        let _status = 'bg-green-600';
        const status = phones[key];
        if (status === PHONE_INUSE) _status = 'bg-red-600';
        else if (status === PHONE_RINGING) _status = 'bg-yellow-600';
        else if (status === PHONE_UNAVAILABLE) _status = 'bg-gray-600';
        let userName;
        if (key === '903') userName = 'Ярослав';
        if (key === '904') userName = 'Дима';
        if (key === '907') userName = 'Геннадий';
        return (
          <li key={key} className='inline-block pr-1 md:p-1'>
            <span
              className={
                'rounded py-1 px-1 text-sm text-white md:px-2 ' + _status
              }
            >
              {key}
              <span className='hidden md:inline-block'>
                {userName ? ':' + userName : null}
              </span>
            </span>
          </li>
        );
      });
  }
  return (
    <ul className='list-unstyled inline-block'>
      <SocketIO
        channel='callcenter'
        onUpdate={(data) => {
          if (
            data.event === 'callcenter.updatePhoneState' &&
            data.payload &&
            data.payload.current &&
            timestamp < data.payload.timestamp
          ) {
            setTimestamp(data.payload.timestamp);
            setPhones(data.payload.current);
          }
        }}
      />
      {phonesList}
    </ul>
  );
};

export default PhonesPanel;
