import useSWR from 'swr';
import { UseSwrResponse } from './swrTypes';
import { fetcher } from './utils';

const MOLECULER = process.env.REACT_APP_MOLECULER;

type StatsProps = {
  total: number;
  mailCount: number;
  paymentsCount: number;
};

export const useStats = (): UseSwrResponse & {
  stats: StatsProps | undefined;
} => {
  const { data, error, mutate } = useSWR<StatsProps>(
    `${MOLECULER}/private/secretary/stats`,
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );
  return {
    stats: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
