import { MailIcon, UserIcon } from '@heroicons/react/outline';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API2 } from '../../api/api';
import { sendDocumentToGoogleDrive } from '../../api/moleculer';
import Messages from '../../common/messages';
import { InvoiceFromDjango } from '../../types/InvoiceFromDjango';
import { downloadFile } from '../../utils';
import RectButton from '../Buttons/RectButton';
import DropDown from '../FormElements/DropDown';
import DropDownHeader from '../FormElements/DropDownHeader';
import DropDownItemA from '../FormElements/DropDownItemA';
import Switcher from '../FormElements/Switcher';

type Props = {
  invoiceId: string;
  invoice: InvoiceFromDjango;
  paid: boolean;
  onUpdateAction: () => void;
};

const InvoiceActionsBar = ({
  invoiceId,
  paid,
  invoice,
  onUpdateAction,
}: Props): JSX.Element => {
  const handlePaid = (v: boolean) => {
    // e.preventDefault();
    API2.invoices.setInvoicePaid(invoiceId, v).then(() => onUpdateAction());
  };

  const handleActivationMail = () => {
    API2.moleculer.sendActivationMail(invoiceId);
    Messages.info('Надсилання', 'Надсилання листа про активацію');
  };

  const handleActivateLicense = () => {
    API2.invoices.activateLicense(invoiceId);
    Messages.info('Активація', 'Активація ліцензії');
  };

  const handleRegister = () => {
    API2.invoices.registerPartner(invoiceId);
  };

  const handleSendPost = () => {
    const comment = prompt('Комантар до відправки');
    if (comment) {
      API2.invoices.sendPost(invoiceId, comment);
      Messages.info('Надсилання', 'Надсилання поштою');
    }
  };

  const handleSendToVchasnoInvitation = () => {
    API2.invoices.sendToVchasnoInvitation(invoiceId);
    Messages.info('Надсилання запрошення', 'Надсилання запрошення на Вчасно');
  };

  const handleSendContractRequest = () => {
    API2.invoices.sendContractRequest(invoiceId);
    Messages.info('Надсилання', 'Заявка на договір');
  };

  const handleSendDocsRequest = () => {
    API2.invoices.sendDocsRequest(invoiceId);
    Messages.info('Надсилання', 'Заявка на документи');
  };

  const handleSendDocsPack = () => {
    API2.invoices.sendDocsPack(invoiceId);
    Messages.info('Надсилання', 'Пакет документів');
  };

  const handleSendMyDocs = () => {
    API2.invoices.sendMyDocs(invoiceId);
    Messages.info('Надсилання', 'Надсилання установчіх документів');
  };

  return (
    <>
      <div className='flex items-center space-x-1 pt-2' role='group'>
        <RectButton
          className='bg-red-500 text-white hover:bg-red-600 hover:text-white'
          as='a'
          url={`/admin/invoice/invoice/${invoiceId}/`}
        >
          <UserIcon className='h-5 w-5' />
        </RectButton>
        <RectButton onClick={handleActivationMail}>
          <span className='flex items-center'>
            <MailIcon className='h-5 w-5' />
            <span className='pl-1'>Активація</span>
          </span>
        </RectButton>
        <DropDown title='Дії' left>
          <div>
            <DropDownItemA onClick={handleActivateLicense}>
              Активувати ліцензію
            </DropDownItemA>
            <DropDownItemA onClick={handleRegister}>
              Зареєструвати
            </DropDownItemA>
          </div>
          <div>
            <DropDownItemA onClick={handleSendMyDocs}>
              Надіслати устан.документи
            </DropDownItemA>
            <DropDownItemA onClick={handleSendDocsPack}>
              Надіслати пакет документів на email
            </DropDownItemA>
          </div>
          <div>
            <DropDownItemA onClick={handleSendPost}>
              Надіслати Укрпоштою
            </DropDownItemA>
            <DropDownItemA onClick={handleSendToVchasnoInvitation}>
              Запрошення на Вчасно
            </DropDownItemA>
          </div>
          <div>
            <DropDownHeader>Заявки на email</DropDownHeader>
            <DropDownItemA onClick={handleSendContractRequest}>
              - на договір
            </DropDownItemA>
            <DropDownItemA onClick={handleSendDocsRequest}>
              - на надсилання документів
            </DropDownItemA>
          </div>
        </DropDown>
        <DropDown title='Експорт' left>
          <div>
            <DropDownHeader>PDF</DropDownHeader>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'pdf', 'invoice');
              }}
            >
              Рахунок
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'pdf', 'akt', true, {
                  edrpouOwner: invoice.organisation.edrpou,
                  edrpouRecipient: invoice.partner.edrpou,
                  date: moment(invoice.date).format('YYYYMMDD'),
                  emailRecipient:
                    invoice.partner.email_edo || invoice.partner.email,
                });
              }}
            >
              Акт
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'pdf', 'contract');
              }}
            >
              Договір
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'pdf', 'invoice_akt');
              }}
            >
              Рахунок та акт
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'pdf', 'invoice_akt', false);
              }}
            >
              Рахунок та акт (друк)
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'pdf', 'calculation');
              }}
            >
              Калькуляція
            </DropDownItemA>
          </div>
          <div>
            <DropDownHeader>ODT</DropDownHeader>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'odt', 'akt');
              }}
            >
              Акт
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'odt', 'contract');
              }}
            >
              Договір
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'odt', 'invoice');
              }}
            >
              Рахунок
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                downloadFile(invoiceId, 'odt', 'calculation');
              }}
            >
              Калькуляція
            </DropDownItemA>
          </div>
          <div>
            <DropDownHeader>Google Drive</DropDownHeader>
            <DropDownItemA
              onClick={async () => {
                const toastId = toast.info(
                  'Створюґться посилання на документ...',
                  {
                    theme: 'colored',
                    autoClose: 8000,
                  }
                );
                const result = await sendDocumentToGoogleDrive(
                  invoiceId,
                  'invoice'
                );
                toast.dismiss(toastId);
                if (result.success) {
                  window.open(result.data.url, '_blank');
                } else {
                  alert(result.reason);
                }
              }}
            >
              Рахунок
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                const toastId = toast.info(
                  'Створюґться посилання на документ...',
                  {
                    theme: 'colored',
                    autoClose: 8000,
                  }
                );
                const result = await sendDocumentToGoogleDrive(
                  invoiceId,
                  'akt'
                );
                toast.dismiss(toastId);
                if (result.success) {
                  window.open(result.data.url, '_blank');
                } else {
                  alert(result.reason);
                }
              }}
            >
              Акт
            </DropDownItemA>
            <DropDownItemA
              onClick={async () => {
                const toastId = toast.info(
                  'Створюґться посилання на документ. Зачекайте декілька секунд...',
                  {
                    // autoClose: false,
                    theme: 'colored',
                    autoClose: 8000,
                  }
                );
                const result = await sendDocumentToGoogleDrive(
                  invoiceId,
                  'contract'
                );
                toast.dismiss(toastId);
                if (result.success) {
                  window.open(result.data.url, '_blank');
                } else {
                  alert(result.reason);
                }
              }}
            >
              Договір
            </DropDownItemA>
          </div>
        </DropDown>
      </div>
      <div className='pt-2'>
        <Switcher
          onChange={handlePaid}
          checked={paid}
          className='justify-start'
        >
          Сплачений
        </Switcher>
      </div>
    </>
  );
};
InvoiceActionsBar.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
  paid: PropTypes.bool.isRequired,
  onUpdateAction: PropTypes.func.isRequired,
};

export default InvoiceActionsBar;
