import useSWR from 'swr';
import { RestResult } from '../providers/types';
import { PostDocument } from '../types/PostDocument';
import { oldFetcher } from './utils';

// const MOLECULER = process.env.REACT_APP_MOLECULER;
const OLD_API = process.env.REACT_APP_OLD_API;

export const useInvoicePosts = (invoiceId: number) => {
  const { data, error, mutate } = useSWR<any, any>(
    encodeURI(`${OLD_API}/api/v2/posts/?invoice__id=${invoiceId}`),
    oldFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    posts: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
// export const useInvoicePosts = (invoiceId: number) => {
//   const { data, error, mutate } = useSWR<RestResult<PostDocument>, any>(
//     encodeURI(
//       `${MOLECULER}/private/documents?pageSize=50&sort=-created&query=${JSON.stringify(
//         {
//           invoiceNumber: invoiceId,
//         }
//       )}`
//     ),
//     fetcher,
//     {
//       revalidateOnFocus: false,
//     }
//   );
//   return {
//     posts: data,
//     isLoading: !error && !data,
//     isError: error,
//     mutate,
//   };
// };
