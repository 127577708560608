import React from 'react';
import { Link } from 'react-router-dom';
import { classNames, formatDate } from '../utils';
import ClipboardButton from './Buttons/ClipboardButton';
import { Invoice } from '../types/Invoice';

type InvoiceProps = {
  invoice: Invoice;
};

const InvoiceItem = ({ invoice }: InvoiceProps) => {
  let invoice_user = null;
  if (invoice.user !== null) {
    invoice_user = (
      <>
        {invoice.user.full_name} ({invoice.user.username})
      </>
    );
  }
  return (
    <div className='flex shadow-sm'>
      <div
        className={classNames(
          'md:rounded-l px-2 py-2 w-4/5',
          invoice.paid ? ' bg-green-300' : 'bg-white'
        )}
      >
        <div className='flex flex-wrap items-center'>
          {invoice.organisation ? (
            <div className='mr-2 text-white shadow text-xs rounded-full w-8 h-8 items-center justify-center bg-blue-400 inline-flex'>
              <div>{invoice.organisation.prefix}</div>
            </div>
          ) : null}{' '}
          №{' '}
          <strong>
            <Link to={{ pathname: `/invoices/${invoice.id}` }}>
              {invoice.id}
            </Link>
          </strong>
          &nbsp;от {formatDate(invoice.date, 'DD.MM.YY')}
          <ClipboardButton
            clipboardText={`рахунок № СЗ-${invoice.id} від ${formatDate(
              invoice.date,
              'DD.MM.YY'
            )}`}
          />
          <div className='text-xs w-full text-green-700 mt-1'>
            {invoice_user}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'md:rounded-r py-2 w-1/5 px-2 justify-center text-lg flex items-center',
          invoice.paid ? 'bg-green-400' : 'bg-white'
        )}
      >
        <div>{invoice.total}</div>
      </div>
    </div>
  );
};

type Props = {
  invoices: {
    results: Invoice[];
  };
};

const PartnerInvoices = ({ invoices }: Props): JSX.Element => (
  <div className='space-y-1'>
    {invoices.results.map((invoice) => (
      <InvoiceItem key={invoice.id} invoice={invoice} />
    ))}
  </div>
);

export default PartnerInvoices;
