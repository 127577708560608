import { MailIcon, PencilIcon } from '@heroicons/react/outline';
import React from 'react';
import ClipboardButton from '../Buttons/ClipboardButton';

type Props = {
  discountFop: number;
  hash: string;
  discountOrg: number;
  onChangeDiscountFop: (value: number) => void;
  onChangeDiscountOrg: (value: number) => void;
  onSendDiscountLink: () => void;
};

const Discount = ({
  discountFop,
  discountOrg,
  hash,
  onChangeDiscountFop,
  onChangeDiscountOrg,
  onSendDiscountLink,
}: Props): JSX.Element => {
  return (
    <div>
      <div className='flex justify-between'>
        <div className=''>
          <div className='text-gray-500'>Знижки:</div>
          <div className='flex w-full items-center py-1 pl-1'>
            <span className='text-gray-800'>
              юрид: <span className='font-bold'>{discountOrg}%</span>
            </span>
            <button
              className='ml-2 text-blue-700 hover:text-blue-900'
              onClick={() => {
                const val = prompt('Знижка', discountOrg.toString());
                if (val) {
                  onChangeDiscountOrg(parseInt(val));
                }
              }}
            >
              <PencilIcon className='h-5 w-5' />
            </button>
          </div>
          <div className='flex w-full items-center py-1 pl-1'>
            <span className='text-gray-800'>
              ФОП: <span className='font-bold'>{discountFop}%</span>
            </span>
            <button
              className='ml-2 text-blue-700 hover:text-blue-900'
              onClick={() => {
                const val = prompt('Скидка', discountFop.toString());
                if (val) {
                  onChangeDiscountFop(parseInt(val));
                }
              }}
            >
              <PencilIcon className='h-5 w-5' />
            </button>
          </div>
        </div>
        {discountFop || discountOrg ? (
          <div className='flex items-center justify-end'>
            <ClipboardButton
              clipboardText={`https://promo.sonatazvit.com.ua/vip/${hash}`}
              title='Скопіювати посилання на кабінет'
            />
            <button
              className='ml-2 flex h-10 w-10 items-center justify-center rounded bg-yellow-400 hover:bg-yellow-500'
              onClick={() => {
                const answer = window.confirm(
                  'Надіслати персональне посилання?'
                );
                if (answer) {
                  onSendDiscountLink();
                }
              }}
              title='Надіслати персональне посилання на email'
            >
              <MailIcon className='h-5 w-5' />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Discount;
